@if (patched()) {
    <div class="container-fluid">
        <div class="row patch-box-details">
            <div
                class="col-12 d-flex justify-content-center align-items-center"
            >
                <div
                    class="d-flex justify-content-center align-items-center pt-4 pb-4 mt-2 mb-2"
                >
                    <div>
                        <i
                            class="fa-sharp-duotone fa-light fa-3x fa-object-exclude"
                        ></i>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="font-lg ms-4">
                            Call Patched to {{ contactSelected.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
} @else {
    <div
        class="container-fluid d-flex flex-column enquiry-box-details ps-1 pt-2 pb-3 mb-2"
        [ngClass]="{ highlighted: panelHighlighted === 'enquiry' }"
    >
        <div class="row ps-3">
            <div class="col-1 p-0">
                <div class="rounded-circle call-initials mt-2 ms-2">
                    {{ contactSelected.name | initials }}
                </div>
            </div>
            <div class="col-10 ps-0">
                <div class="font-xxl">
                    {{
                        contactSelected.type === 'agent'
                            ? 'Operator'
                            : contactSelected.identifier
                    }}
                    {{ enquiryState }}
                </div>
                <div class="font-lg">
                    {{ contactSelected.name }}
                </div>
            </div>
            <div class="col-1 pt-2 text-center">
                <div
                    class="d-flex flex-column align-items-center h-100 justify-content-between me-3"
                >
                    <div class="mt-1">
                        <button
                            type="button"
                            class="btn btn-reject rounded-circle p-2"
                            (click)="endEnquiry()"
                            [disabled]="enableButtons"
                        >
                            <i class="fa-xl fa-solid fa-phone-hangup"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-auto">
            <div class="col-12">
                <div class="d-flex flex-row justify-content-between ms-5 me-5">
                    <div
                        tabindex="0"
                        class="btn-action p-3"
                        (click)="callCommand(gotoCommand())"
                        (keypress)="callCommand(gotoCommand())"
                        [ngClass]="{ 'disabled-btn': enableButtons }"
                    >
                        <i class="fa-xl fa-light fa-shuffle ms-2 me-2"></i>
                        <span class="ms-2">{{ switchText() }}</span>
                    </div>
                    <!-- <div
                        tabindex="1"
                        class="btn-action p-3"
                        (click)="callCommand('HoldBoth')"
                        (keypress)="callCommand('HoldBoth')"
                        [ngClass]="{ 'disabled-btn': enableButtons }"
                    >
                        <i class="fa-xl fa-light fa-pause ms-2 me-2"></i>
                        <span class="ms-2">Hold Enquiry</span>
                    </div> -->
                    <div
                        tabindex="2"
                        class="btn-action p-3"
                        (click)="callCommand('Patch')"
                        (keypress)="callCommand('Patch')"
                        [ngClass]="{ 'disabled-btn': enableButtons }"
                    >
                        <i
                            class="fa-xl fa-light fa-object-exclude ms-2 me-2"
                        ></i>
                        <span class="ms-2">Patch Call</span>
                    </div>
                    <!-- <div
                    tabindex="3"
                    class="btn-action p-3"
                    (click)="callCommand('ThreeWay')"
                    (keypress)="callCommand('ThreeWay')"
                    [ngClass]="{ 'disabled-btn': enableButtons }"
                >
                    <i class="fa-xl fa-light fa-merge ms-2 me-2"></i>
                    <span class="ms-2">Merge Call</span>
                </div> -->
                </div>
            </div>
        </div>
    </div>
}
