import {
    Component,
    OnInit,
    Signal,
    computed,
    input,
    signal,
} from '@angular/core';
import { IAgent, IPresence, IQueue, ITeam, IUser } from '@dxp/shared/models';
import {
    AgentService,
    HeartbeatService,
    QueueService,
    StateService,
    TeamService,
    UserService,
} from '@dxp/shared/services';
import { Duration } from 'date-fns';

import { CommonModule } from '@angular/common';
import {
    DoughnutItems,
    TelXLButtonComponent,
    TelXLDoughnutComponent,
} from '@dxp/elements';
import { secondsToDuration } from '@dxp/shared/helpers';
import { FormatDurationPipe } from '@dxp/shared/pipes';

@Component({
    selector: 'app-shell-main-layout-break',
    standalone: true,
    imports: [
        CommonModule,
        FormatDurationPipe,
        TelXLButtonComponent,
        TelXLDoughnutComponent,
    ],
    templateUrl: './main-layout-break.component.html',
    styleUrl: './main-layout-break.component.scss',
})
export class MainLayoutBreakComponent implements OnInit {
    presence = input.required<IPresence>();
    now: Signal<Date>;
    teams = computed<ITeam[]>(() => this.teamService.teams());
    queues = computed<IQueue[]>(() =>
        this.queueService.queues().filter(q => q.name.length > 0),
    );

    constructor(
        private userService: UserService,
        private agentService: AgentService,
        private teamService: TeamService,
        private queueService: QueueService,
        private heartbeatService: HeartbeatService,
        private stateService: StateService,
    ) {
        this.now = this.heartbeatService.lastHeartbeat;
    }

    DoughnutItems = DoughnutItems;
    secondsToDuration = secondsToDuration;

    teamId = signal<string>('');
    queueId = signal<string>('');

    team = computed<ITeam>(
        () => this.teams().find(t => t.id === this.teamId()) as ITeam,
    );

    agent = computed<IAgent>(
        () =>
            this.agentService
                .agents()
                .find(
                    a => a.hasActivity && a.id === this.userService.user().id,
                ) as IAgent,
    );

    queue = computed<IQueue>(
        () => this.queues().find(q => q.id === this.queueId()) as IQueue,
    );

    ngOnInit(): void {
        if (this.teams().length > 0) {
            this.teamId.set(this.teams()[0].id);
        }

        if (this.queues().length > 0) {
            this.queueId.set(this.queues()[0].id);
        }
    }

    onTeamChange(team: ITeam): void {
        this.teamId.set(team.id);
    }

    onQueueChange(queue: IQueue): void {
        this.queueId.set(queue.id);
    }

    get stateSince(): Duration {
        if (this.presence().timedBreakExpiresAt) {
            return secondsToDuration(
                this.presence().timedBreakRemainingDuration ?? 0,
            );
        }

        return secondsToDuration(this.presence().stateDuration);
    }

    endBreak(): void {
        this.stateService.onActivate();
    }

    get user(): IUser {
        return this.userService.user();
    }
}
