export interface ICustomerDirectory {
    count: number;
    customerDirectory: ICustomerDetails[];
}

export interface ICustomerDetails {
    customerId: string;
    businessUnitId: string | null;
    title: string | null;
    name: string | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    email: string | null;
    externalId: string | null;
    phoneNumbers: CustomerPhoneNumber[];
    externalProfiles: CustomerExternalProfile[];
    customFields: CustomerCustomField[];
    preferredAgentId: string | null;
    locale: string | null;
    lastInteractionAt: Date | null;
    createdAt: Date | null;
}

interface CustomerPhoneNumber {
    phoneNumberId: number;
    type: string | null;
    number: string;
    preferred: boolean;
}

interface CustomerExternalProfile {
    externalProfileId: string;
    provider: string;
    id: string;
    username: string | null;
}

interface CustomerCustomField {
    customFieldId: string | null;
    fieldDefinitionId: number;
    value: string;
}
