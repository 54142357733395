import { Observable } from "rxjs";

import { RbacApiService } from "./../../../../api";
import { IWrap } from "./../../../../models";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class WrapServiceMG {
    constructor(private rbacApiService: RbacApiService) {}

    get getWrap(): Observable<IWrap> {
       return this.rbacApiService.getWrapDetails();
    }
}
