import {
    Component,
    computed,
    EventEmitter,
    Input,
    Output,
    signal,
} from '@angular/core';
import { InitialsPipe } from '@dxp/shared/pipes';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IAgent, IContactAgent, IContactSelected } from '@dxp/shared/models';
import { UserService } from '@dxp/shared/services';

@Component({
    selector: 'contacts-agent',
    standalone: true,
    imports: [CommonModule, FormsModule, InitialsPipe],
    templateUrl: './agent-contact.component.html',
    styleUrl: './agent-contact.component.scss',
})
export class AgentContactComponent {
    constructor(private userService: UserService) {}

    @Input() agent!: IAgent;
    @Input() agentDetails = signal(new Map<string, IContactAgent>());
    @Input() mediaType!: string;
    @Input() mediaAction!: string;
    @Output() contactSelected = new EventEmitter<IContactSelected>();
    @Input() isActive = false;

    selectedContact: IContactSelected = { name: '', identifier: '', type: '' };
    agentPresence = computed(() => {
        return this.userService.user().presence;
    });

    selected() {
        this.isActive = true;
        this.contactSelected.emit({
            ...this.selectedContact,
            name: this.agent.name,
            identifier: this.agent.name,
            type: this.mediaAction,
        });
    }
}
