import { Injectable } from '@angular/core';

import {
    BehaviorSubject,
    Observable,
    catchError,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    startWith,
    switchMap
} from 'rxjs';

import {
    IRbacRole,
    IRbacTenant,
    IRbacUser,
    IRbacUserTenant,
    IScope,
} from '../../../../../models';
import { DirectoryApiService, RbacApiService } from './../../../../../api';

export interface IAuthenticatedUser {
    email: string;
    id: string;
    isAuthenticated: boolean;
    name: string;
}

export interface IAxisUser {
    authenticated: boolean;
    displayName: string;
    emailAddress: string;
    enabled: boolean;
    id: string;
    jobTitle: string;
    name: string;
    permissions: IPermissions[];
    phoneNumber: string;
    photo?: string;
    profilePictureUrl: string | null;
    scopes: IScope[];
    tenant?: IRbacTenant;
    tenantId: string;
    tenants: IRbacUserTenant[];
}

export interface IPermissions {
    businessEntity: string;
    permissionType: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    #authenticatedUser = new BehaviorSubject<IAuthenticatedUser>(
        {} as IAuthenticatedUser,
    );
    #axisUser = new BehaviorSubject<IAxisUser>({} as IAxisUser);

    constructor(
        private rbacApiService: RbacApiService,
        private directoryApiService: DirectoryApiService,
    ) {}

    get getUser(): Observable<IAxisUser> {
        return this.listenAuthenticatedUser().pipe(
            switchMap(authenticatedUser =>
                this.rbacApiService.getUser(authenticatedUser.id).pipe(
                    switchMap(user =>
                        this.rbacApiService
                            .getConnectedUsers(authenticatedUser.id)
                            .pipe(
                                mergeMap(tenants =>
                                    this.directoryApiService.getPhoto().pipe(
                                        startWith(null), 
                                        mergeMap(up =>
                                            this.rbacApiService
                                                .getPermissions()
                                                .pipe(
                                                    map(rbacRole => {
                                                        const fullUser: IAxisUser =
                                                            this.createUserProfile(
                                                                authenticatedUser,
                                                                user,
                                                                tenants,
                                                                up,
                                                                rbacRole,
                                                            );

                                                        return fullUser;
                                                    }),
                                                ),
                                        ),
                                        catchError(() =>
                                            this.rbacApiService
                                                .getPermissions()
                                                .pipe(
                                                    map(rbacRole => {
                                                        const fullUser: IAxisUser =
                                                            this.createUserProfile(
                                                                authenticatedUser,
                                                                user,
                                                                tenants,
                                                                null,
                                                                rbacRole,
                                                            );

                                                        return fullUser;
                                                    }),
                                                ),
                                        ),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        );
    }

    listenAuthenticatedUser() {
        return this.#authenticatedUser.asObservable().pipe(
            filter(authenticatedUser => !!authenticatedUser.id),
            distinctUntilChanged(
                (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
            ),
        );
    }

    listenAxisUser() {
        return this.#axisUser
            .asObservable()
            .pipe(filter(axisUser => !!axisUser.id));
    }

    setAuthenticatedUser(authenticatedUser: IAuthenticatedUser): void {
        this.#authenticatedUser.next(authenticatedUser);
    }

    setAxisUser(user: IAxisUser): void {
        this.#axisUser.next(user);
    }

    private createUserProfile(
        authenticatedUser: IAuthenticatedUser,
        user: IRbacUser,
        tenants: IRbacUserTenant[],
        up: Blob | null,
        rbacRole: IRbacRole,
    ): IAxisUser {
        return {
            authenticated: authenticatedUser.isAuthenticated,
            displayName: user.displayName,
            emailAddress: user.emailAddress,
            enabled: user.enabled,
            id: user.id,
            jobTitle: user.jobTitle,
            name: user.name,
            phoneNumber: user.phoneNumber,
            profilePictureUrl: user.profilePictureUrl,
            tenantId: user.tenantId,
            tenants,
            scopes: [],

            photo: up ? URL.createObjectURL(up) : '', // TODO: Provide default image url

            permissions: rbacRole.permissions.map(p => {
                return {
                    businessEntity: p.businessEntity,
                    permissionType: p.permissionType,
                };
            }),
        };
    }
}
