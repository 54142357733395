import { IWorkItem, MediaType, TaskOrder } from '@dxp/shared/models';

export function sortWorkItemsByMediaAndTime(items: IWorkItem[]): IWorkItem[] {
    return [...items].sort((a, b) => {
        const mediaA = mediaTypeMapping(a);
        const mediaB = mediaTypeMapping(b);

        if (mediaA === mediaB) {
            return a.createdAt.getTime() - b.createdAt.getTime();
        }

        return mediaA - mediaB;
    });
}

const mediaTypeMapping = (workitem: IWorkItem): TaskOrder => {
    return mediaTypeMappings[workitem.primaryMediaType] ?? TaskOrder.Unknown;
};

const mediaTypeMappings: Partial<Record<MediaType, TaskOrder>> = {
    Voice: TaskOrder.Voice,
    Webchat: TaskOrder.Webchat,
    Messaging: TaskOrder.Messaging,
    Email: TaskOrder.Email,
};
