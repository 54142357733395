export interface IVoiceChannel {
    id: string;
    tenantId: string;
    isDefault: boolean;
    channelId: number;
    channelName: string;
    name: string;
    workflowId: number;
    businessHourId: number;
    businessUnitId: number;
    queueId: string;
    queueName: string;
}
