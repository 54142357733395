import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Queue } from '../../../../../models';
import {
    ChannelProviderService,
    ChannelProviderType,
} from '../../../../channel-provider/channel-provider.service';
import { ITeamMG } from '../teams/teams-data.service';

export const NBU_ID = 'nobussinessunit';
export const NBU_NAME = 'No Business Unit';

@Injectable({
    providedIn: 'root',
})
export class QueueServiceMG {
    constructor(
        private channelProviderService: ChannelProviderService,
    ) {}

    getQueues(teams: ITeamMG[]): Observable<Queue[]> {
        return new Observable<Queue[]>(observer => {
            const queues = teams.map(team => this.createQueueNew(team.id));
            const result = queues.map(q => {
                const channelProvider = this.getChannelProviderByWorkflowId(q.id);
                return new Queue(q.id, channelProvider);
            });
            observer.next(result);
            observer.complete();
        });
    }

    private createQueueNew(id: string): Queue {
        return new Queue(id);
    }

    private getChannelProviderByWorkflowId(
        workflowId: string,
    ): ChannelProviderType[] {
        return this.channelProviderService.getChannelProvidersByWorkflowId(
            workflowId,
        );
    }
}
