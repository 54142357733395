import {
    Component,
    ComponentRef,
    EventEmitter,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ContactVoiceTransferComponent } from '../actions/voice/voice-transfer.component';
import { ContactVoiceEnquiryComponent } from '../actions/voice/voice-enquiry.component';
import { IContactSelected } from '@dxp/shared/models';

@Component({
    selector: 'contacts-action',
    standalone: true,
    template: `
        <ng-container #container></ng-container>
    `,
})
export class ContactActionComponent {
    @ViewChild('container', { read: ViewContainerRef, static: true })
    container!: ViewContainerRef;
    componentRef!: ComponentRef<any>;
    selectedComponent: any = null;

    @Output() closeContacts = new EventEmitter<boolean>();
    @Output() disableContacts = new EventEmitter<boolean>();

    loadComponent(
        action: string,
        conversationId: string,
        workItemId: string,
        selected: IContactSelected,
    ) {
        this.container.clear();

        switch (action) {
            case 'voice-transfer':
                this.componentRef = this.container.createComponent(
                    ContactVoiceTransferComponent,
                );
                break;

            case 'voice-enquiry':
                this.componentRef = this.container.createComponent(
                    ContactVoiceEnquiryComponent,
                );
                break;
        }
        this.componentRef.instance.contactSelected = selected;
        this.componentRef.instance.conversationId = conversationId;
        this.componentRef.instance.workItemId = workItemId;
        if (this.componentRef.instance.closeContacts) {
            this.componentRef.instance.closeContacts.subscribe(
                (data: boolean) => {
                    this.closeContacts.emit(data);
                },
            );
        }
        this.componentRef.changeDetectorRef.detectChanges();
    }
}
