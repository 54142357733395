<div class="p-1 w-100 h-100">
    <div
        class="task-item w-100 h-100 justify-content-between p-1"
        [ngClass]="taskItemClass()"
        (click)="navigate()"
        (keypress)="navigate()"
        tabindex="0"
    >
        <div class="d-flex task-content">
            <div class="ms-1 me-2">
                <i [ngClass]="iconClass()"></i>
            </div>
            <div
                class="d-flex flex-column justify-content-between"
                [ngClass]="{ truncate: state() !== 'Connected' }"
            >
                <div class="weight-medium" style="line-height: normal">
                    {{ workitem().primaryMediaType }}

                    @if (state() === 'Preview') {
                        {{
                            workitem().primaryMediaType === 'Voice'
                                ? 'calling'
                                : 'waiting'
                        }}
                    }
                    @if (state() === 'Connected') {
                        @switch (workitem().primaryMediaType) {
                            @case ('Voice') {
                                {{ 'in progress' }}
                            }
                            @case ('Webchat') {
                                {{ 'live' }}
                            }
                            @case ('Email') {
                                {{ 'task' }}
                            }
                            @case ('Messaging') {
                                {{ 'live' }}
                            }
                        }
                    } @else if (state() === 'Afterwork') {
                        in wrap
                    }
                </div>
                <div class="queue shift-up font-xs">
                    {{ queueName() }}
                </div>
                <div class="time shift-up font-xs">
                    {{ workitem().createdAt | date: 'HH:mm' }}
                </div>
            </div>
            @switch (state()) {
                @case ('Preview') {
                    <div
                        class="d-flex flex-column align-items-center justify-content-between me-1"
                    >
                        <telxl-button
                            colour="success"
                            size="xs"
                            [outline]="true"
                            class="w-100"
                            (buttonClick)="accept()"
                        >
                            Accept
                        </telxl-button>
                        <telxl-button
                            colour="danger"
                            size="xs"
                            [outline]="true"
                            class="w-100"
                            (buttonClick)="reject()"
                        >
                            Reject
                        </telxl-button>
                    </div>
                }
                @case ('Afterwork') {
                    <div
                        class="d-flex flex-column align-items-center justify-content-between"
                    >
                        <div class="time font-xs">
                            {{ wrapCountdown().duration | formatDuration }}
                        </div>
                        <telxl-button
                            colour="secondary"
                            size="xs"
                            [outline]="true"
                            class="w-100"
                            [disabled]="!canExtend()"
                            (buttonClick)="reset()"
                        >
                            Reset
                        </telxl-button>
                    </div>
                }
            }
        </div>
    </div>
    <div class="progress-container">
        @if (state() === 'Preview') {
            <telxl-progressbar
                orientation="fixed-bottom"
                [metric]="ronaCountdown()"
                defaultState="danger"
            />
        } @else if (state() === 'Afterwork') {
            <telxl-progressbar
                orientation="fixed-bottom"
                [metric]="wrapCountdown().metric"
                defaultState="danger"
            />
        }
    </div>
</div>
