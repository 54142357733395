import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAgentState } from './../../../../models/agent/agent';
import { IQueueInformation } from './../../../../models/queue/queue';
import { LiveHubService } from './../../../../signalr/live/live.hub.service';

@Injectable({
    providedIn: 'root',
})
export class LiveHubWrapperService {
    constructor(private liveHubService: LiveHubService) {}

    get agents$(): Observable<IAgentState[]> {
        return this.liveHubService.agents$;
    }

    get queueInformation$(): Observable<IQueueInformation> {
        return this.liveHubService.queueInformation$;
    }
}
