import { LogLevel } from './log';

export enum EnvironmentType {
    Local = 'Local',
    Dev = 'Dev',
    QA = 'QA',
    Sand = 'Sand',
    Production = 'Production',
}

export interface IEnvironmentSettings {
    aggregationsUrl: string;
    apiBaseUrl: string;
    odataBaseUrl: string;
    authority: string;
    blenderUrl: string;
    cacheLifeTime: number;
    contentUrl: string;
    directoryUrl: string;
    environment: EnvironmentType;
    heartbeatInterval: number;
    isCacheEnabled: boolean;
    isLoggingEnabled: boolean;
    inActivityTimeOut: number;
    liveUrl: string;
    logLevel: LogLevel;
    pollingInterval: number;
    production: boolean;
    rbacUrl: string;
    resize: {
        app: {
            standard: string;
            expanded: string;
        };
        dashboard: {
            standard: string;
            expanded: string;
        };
        performance: {
            standard: string;
            expanded: string;
        };
        chat: {
            standard: string;
            expanded: string;
        };
    };
    scope: string;
    templatesTenantsUrl: string;
    templatesUrl: string;
    translationUrl: string;
    voiceUrl: string;
    workflowUrl: string;
}
