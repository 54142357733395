import { CommonModule } from '@angular/common';
import { Component, computed, input, output, signal } from '@angular/core';
import { ITransferQueue } from '@dxp/shared/models';
import { TruncatePipe } from '@dxp/shared/pipes';

import { TelXLButtonComponent } from '../../../button/button.component';

@Component({
    selector: 'telxl-queues',
    standalone: true,
    imports: [CommonModule, TruncatePipe, TelXLButtonComponent],
    templateUrl: './queues.component.html',
    styleUrl: './queues.component.scss',
})
export class TransferQueuesComponent {
    filteredItems = computed(() => {
        const term = this.searchTerm().toLowerCase();
        return this.queues().filter(item =>
            item.name.toLowerCase().includes(term),
        );
    });
    isTransfer = signal(false);

    // Default to 3.5 items before scrolling
    listItemHeight = input(80);
    queues = input.required<ITransferQueue[]>();
    searchTerm = signal('');
    transfer = output<ITransferQueue>();
    releaseBackToQueue = output<ITransferQueue>();
    truncateItemLength = input(40);
    visibleItems = input(3.5);

    get maxHeight(): string {
        return `${this.listItemHeight() * 3.5}px`;
    }

    onSearch(searchTerm: Event): void {
        this.searchTerm.set(
            (searchTerm.target as HTMLInputElement)?.value.toLowerCase(),
        );
    }

    onReleaseBacktoQueue(id: string) {
        const queue = this.queues().find(queue => queue.id === id);
        if (queue) {
            this.releaseBackToQueue.emit(queue);
        } else {
            throw new Error('Queue not found');
        }
    }

    onTransfer(id: string) {
        const queue = this.queues().find(queue => queue.id === id);
        if (queue) {
            this.transfer.emit(queue);
        } else {
            throw new Error('Queue not found');
        }
    }
}
