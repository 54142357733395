<telxl-button
    colour="light"
    size="xs"
    (buttonClick)="openModal()"
    (keydown.enter)="openModal()"
    tabindex="1"
>
    <i class="fa fa-exchange-alt me-2"></i>
    Transfer
</telxl-button>
<telxl-modal #modal [title]="'Transfer'">
    <telxl-tab
        [tabs]="tabs"
        [selected]="selectedTab()"
        (selectedChange)="setSelected($event)"
    />

    @if (isShowQueues()) {
        <telxl-queues
            [queues]="chatTransferDetails().queues()"
            (releaseBackToQueue)="onReleaseBackToQueue($event)"
            (transfer)="onTransfer($event)"
        />
    }

    @if (isShowAgents()) {
        <telxl-agents
            [agents]="chatTransferDetails().agents()"
            (selectedAgent)="setSelectedAgent($event)"
        />
    }

    <div modal-footer>
        <button class="btn btn-secondary" (click)="modal.close()">Close</button>
    </div>
</telxl-modal>
