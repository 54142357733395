import { Router } from '@angular/router';
import { IWorkItem, MediaType } from '@dxp/shared/models';

const naviagationMappings: Partial<Record<MediaType, string>> = {
    Voice: '/comms/voice',
    Webchat: '/comms/chat',
    Messaging: '/comms/chat',
    Email: '/comms/email',
};

export const navigate = (router: Router, workItem: IWorkItem) => {
    router.navigate([
        naviagationMappings[workItem.primaryMediaType],
        workItem.workItemId,
    ]);
};
