import { ChannelType } from '../types/channel-type';
import { IHistoricalChannel, IHistoricalWorkItem } from '../workitem/history';

export type VirtualConversationType = 'Callback' | 'Preview';

export type ConversationEndedReason =
    | 'Abandoned'
    | 'AgentJoinFailed'
    | 'BotFailed'
    | 'CallbackCanceled'
    | 'CallbackRequested'
    | 'CancelledByAgent'
    | 'CustomerDisconnected'
    | 'CustomerJoinFailed'
    | 'Default'
    | 'IdleTimeout'
    | 'InternalError'
    | 'TransferredToQueue'
    | 'TransferToAgentFailed'
    | 'TransferToChannel'
    | 'TransferToExternal'
    | 'TransferToExternalFailed'
    | 'Unspecified';

export type EnqueueAttemptRejectReason =
    | 'EstimatedWaitTimeLimitReached'
    | 'NoAgentLoggedIn'
    | 'NoAvailableStaffedAgent'
    | 'NoStaffedAgentLoggedIn'
    | 'QueueSizeLimitReached';

export type DequeuedReason =
    | 'AgentConnected'
    | 'CallbackRequested'
    | 'ConversationEnded'
    | 'QueueTimedOut';

export interface IHistoricalConversations {
    count: number;
    historicalConversations: IHistoricalConversation[];
}

export interface IHistoricalConversation {
    id: string;
    businessUnitId: string | null;
    isOutbound: boolean;
    startedAtUtc: Date;
    endedAtUtc: Date;
    durationInSeconds: number;
    conversationEndedReason: ConversationEndedReason;
    virtualConversationType: VirtualConversationType | null;
    channelId: number;
    channel: IHistoricalChannel | null;
    channelType: ChannelType;
    customerId: string | null;
    customerFirstName: string | null;
    customerLastName: string | null;
    customerMiddleName: string | null;
    workItems: IHistoricalWorkItem[] | null;
    queueSegments: IHistoricalQueueSegment[] | null;
    rejectedEnqueueAttempts: IRejectedEnqueueAttempt[] | null;
    inChannelClosedDurationInSeconds: number;
    inChannelOpenDurationInSeconds: number;
    customerRingDurationInSeconds: number | null;
    nextConversationId: string | null;
    transferInitiatedBy: string | null;
    openingCheckResult: string | null;
}

export interface IHistoricalCustomer {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    emailAddress: string | null;
    phoneNumber: string | null;
}

export interface IHistoricalParticipant {
    joinedAt: Date | null;
    leftAt: Date | null;
    participantId: string;
    agentId: string;
    removedReason: string | null;
}

export interface IRejectedEnqueueAttempt {
    id: string;
    conversationId: string;
    channelId: number;
    queueId: string;
    rejectedAtUtc: Date;
    rejectReason: EnqueueAttemptRejectReason;
}

export interface IHistoricalQueueSegment {
    id: string;
    conversationId: string | null;
    queueId: string | null;
    channelId: number;
    queuedAtUtc: string;
    dequeuedAtUtc: string;
    durationInSeconds: number;
    dequeuedReason: DequeuedReason | null;
}
