import { computed, Injectable, signal } from '@angular/core';
import { Subscription } from 'rxjs';
import { calculateUserPresence } from '../../helpers/presence';
import { IScope } from '../../models/agent/agent-scope';
import { IAgentClientState } from '../../models/agent/agent-state';
import { IUser } from '../../models/auth/user';
import { IRbacRole } from '../../models/rbac/permissions';
import { AgentHubService } from '../../signalr/agent/agent.hub.service';
import { HeartbeatService } from '../heartbeat/heartbeat.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    subscriptions = new Subscription();
    private _agentState = signal<IAgentClientState | undefined>(undefined);
    private _permissions: Map<string, Set<string>> = new Map();
    private _logout = signal<boolean>(false);

    default: IUser = {
        id: '',
        name: '',
        emailAddress: '',
        authenticated: false,
        enabled: false,
        phoneNumber: '',
        profilePictureUrl: null,
        photo: undefined,
        tenantId: '',
        displayName: '',
        jobTitle: '',
        tenants: [],
        tenant: undefined,
        scopes: [] as IScope[],
        presence: calculateUserPresence('LoggedOut', new Date(), ''),
    };

    private _user = signal<IUser>(this.default);
    user = computed<IUser>(() => {
        this.heartbeatService.lastHeartbeat();

        return {
            ...this._user.asReadonly()(),
            presence: this._agentState()
                ? calculateUserPresence(
                      (this._agentState() as IAgentClientState).agentProperties
                          .state,
                      (this._agentState() as IAgentClientState).agentProperties
                          .stateSince,
                      (this._agentState() as IAgentClientState).agentProperties
                          .breakName,
                      (this._agentState() as IAgentClientState).agentProperties
                          .timedBreakExpiresAt,
                  )
                : calculateUserPresence('LoggedOut', new Date(), ''),
        };
    });
    ready = computed<boolean>(() => this._user().authenticated);
    loggingOut = computed<boolean>(() => this._logout.asReadonly()());

    constructor(
        private agentHubService: AgentHubService,
        private heartbeatService: HeartbeatService,
    ) {
        const stateSubscription = this.agentHubService.agentState$.subscribe(
            (agentState: IAgentClientState) => {
                this._agentState.set(agentState);
            },
        );

        this.subscriptions.add(stateSubscription);
    }

    update(user: IUser) {
        this._user.set({ ...this.user(), ...user });
    }

    clear() {
        this._user.set(this.default);
        this._permissions.clear();
    }

    setPermissions(role: IRbacRole): void {
        this._permissions.clear();

        role.permissions.forEach(permission => {
            const businessEntity = permission.businessEntity.toLowerCase();
            const permissionType = permission.permissionType.toLowerCase();

            if (!this._permissions.has(businessEntity)) {
                this._permissions.set(businessEntity, new Set());
            }
            this._permissions.get(businessEntity)?.add(permissionType);
        });
    }

    hasPermissionByEntityAndType(entity: string, type: string): boolean {
        return (
            (this._permissions.has(entity) &&
                this._permissions.get(entity)?.has(type)) ||
            false
        );
    }

    hasPermission(permission: string): boolean {
        const [entity, type] = permission.toLowerCase().split('.');

        if (!entity || !type) {
            return false;
        }

        return this.hasPermissionByEntityAndType(entity, type);
    }

    getPermissionsForEntity(entity: string): Set<string> | undefined {
        return this._permissions.get(entity);
    }

    isSupervisor(): boolean {
        return this.user().scopes.some(
            scope =>
                scope.name === 'bp.access' &&
                scope.claims.some(
                    claim =>
                        claim.type === 'buzzplus.role' &&
                        claim.value === 'supervisor',
                ),
        );
    }

    logout() {
        this._logout.set(true);
    }
}
