<div class="container-fluid w-100 voice-dialpad">
    @for (row of dialpad; track row) {
        <div class="row">
            @for (button of row; track button) {
                <div class="col-4">
                    <div>
                        <button
                            type="button"
                            class="btn btn-dialpad w-100 p-0 mt-2"
                            (click)="onButtonClick(button.number)"
                        >
                            <div class="font-2xl btn-number">
                                {{ button.number }}
                            </div>
                            <div>
                                {{ button.additional }}
                            </div>
                        </button>
                    </div>
                </div>
            }
        </div>
    }
</div>
