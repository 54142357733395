import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { environment } from './../../environments';
import {
    ICustomerDetails,
    ICustomerDirectory,
    ICustomFields,
    JsonPatchData,
} from './../../models';
import { LoggerService } from './../../services';
import { Observable, map, tap } from 'rxjs';

const hasNameFilter =
    '(length(FirstName) gt 0 or length(MiddleName) gt 0 or length(LastName) gt 0)';
const hasPhoneFilter = 'PhoneNumbers/any()';
const hasEmailFilter = 'length(Email) gt 0';

@Injectable({
    providedIn: 'root',
})
export class CustomerApiService {
    customer: WritableSignal<ICustomerDetails | null> =
        signal<ICustomerDetails | null>(null);

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getCustomer(
        customerId: string,
        expand: string[],
    ): Observable<ICustomerDetails> {
        const params = new HttpParams().set('$expand', expand.join(','));

        return this.http
            .get<ICustomerDetails>(
                `${environment.apiBaseUrl}/customer-manager/v3/customers(${customerId})`,
                { params },
            )
            .pipe(
                tap({
                    next: customer => {
                        this.logger.debug(
                            'Customer Service (Get customer) -> Successful',
                            customer,
                        );

                        this.customer.set(customer);
                    },
                    error: error => {
                        this.logger.error(
                            'Customer Service (Get customer) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    getCustomers(
        count: boolean,
        expand: string[],
        skip: number,
        top: number,
        filters: {
            requiresName?: boolean;
            requiresEmail?: boolean;
            requiresPhone?: boolean;
            businessUnitId: string | null;
        },
        excludeCustomer?: string,
        searchQuery?: string,
    ): Observable<ICustomerDirectory> {
        const businessUnitFilter = filters.businessUnitId
            ? `BusinessUnitId eq '${filters.businessUnitId}'`
            : 'BusinessUnitId eq null';

        const searchFilter = searchQuery
            ? `(contains(FirstName, '${searchQuery}') or contains(MiddleName, '${searchQuery}') or contains(LastName, '${searchQuery}'))`
            : '';

        const excludeCustomerFilter = excludeCustomer
            ? `CustomerId ne ${excludeCustomer}`
            : '';

        const filter = [
            filters.requiresName ? hasNameFilter : '',
            filters.requiresPhone ? hasPhoneFilter : '',
            filters.requiresEmail ? hasEmailFilter : '',
            businessUnitFilter,
            searchFilter,
            excludeCustomerFilter,
        ]
            .filter(x => !!x)
            .join(' and ');

        const params = new HttpParams()
            .set('$count', count.toString())
            .set('$expand', expand.join(','))
            .set('$skip', skip.toString())
            .set('$top', top.toString())
            .set('$filter', filter);

        return this.http
            .get<{
                '@odata.count': number;
                value: ICustomerDetails[];
            }>(`${environment.apiBaseUrl}/customer-manager/v3/customers`, {
                params,
            })
            .pipe(
                map(response => {
                    return {
                        count: response['@odata.count'],
                        customerDirectory: response.value,
                    };
                }),
                tap({
                    next: customerDirectory => {
                        this.logger.debug(
                            'Customer Service (Get customers) -> Successful',
                            customerDirectory,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Customer Service (Get customers) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    addCustomer(customer: ICustomerDetails): Observable<ICustomerDetails> {
        return this.http
            .post<ICustomerDetails>(
                environment.rbacUrl + '/v3/customers',
                customer,
            )
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Customer Service (Add customer) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Customer Service (Add customer) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    updateCustomer(
        customer: ICustomerDetails,
        patchData: JsonPatchData,
    ): Observable<ICustomerDetails> {
        return this.http
            .patch<ICustomerDetails>(
                environment.rbacUrl + `/v3/customers/${customer.customerId}`,
                patchData,
            )
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Customer Service (Update customer) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Customer Service (Update customer) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    getCustomFields(businessUnitId: string | null): Observable<ICustomFields> {
        const params = new HttpParams().set(
            'businessUnitId',
            businessUnitId ?? '',
        );

        return this.http
            .get<ICustomFields>(
                `${environment.apiBaseUrl}/customer-manager/customers/fields`,
                {
                    params,
                },
            )
            .pipe(
                tap({
                    next: customFields => {
                        this.logger.debug(
                            'Customer Service (Get custom fields) -> Successful',
                            customFields,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Customer Service (Get custom fields) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }
}
