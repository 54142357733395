import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import {
    ICustomerDetails,
    IEmailMessageHistory,
    IHistoricalConversation,
    IHistoricalConversations,
} from '../../models';
import { LoggerService } from '../../services';
import { map, Observable, tap } from 'rxjs';
import { IMessageHistory } from '../../models/conversation/history/message';

@Injectable({
    providedIn: 'root',
})
export class ConversationApiService {
    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getConversationHistoryByCustomer(
        customerId: string,
        count: boolean,
        expand: string[],
        skip: number,
        top: number,
        orderBy: string,
    ): Observable<IHistoricalConversations> {
        const customerFilter = `CustomerId eq '${customerId}'`;
        const noVirtualChannel = "channelType ne 'virtual'";

        const filter = [customerFilter, noVirtualChannel]
            .filter(x => !!x)
            .join(' and ');

        const params = new HttpParams()
            .set('$count', count.toString())
            .set('$filter', filter)
            .set('$expand', expand.join(','))
            .set('$skip', skip.toString())
            .set('$top', top.toString())
            .set('$orderBy', orderBy);

        return this.http
            .get<{
                '@odata.count': number;
                value: IHistoricalConversation[];
            }>(`${environment.odataBaseUrl}/v1/conversations`, { params })
            .pipe(
                map(response => {
                    return {
                        count: response['@odata.count'],
                        historicalConversations: response.value,
                    };
                }),
                tap({
                    next: workitems => {
                        this.logger.debug(
                            'Conversation Service (Get conversations by customer) -> Successful',
                            workitems,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Conversation Service (Get conversations by customer) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    getConversationMessagingActivityHistory(
        conversationId: string,
    ): Observable<IMessageHistory[]> {
        return this.http
            .get<
                IMessageHistory[]
            >(`${environment.contentUrl}/conversationhistory?conversation_id=${conversationId}`)
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Conversation Service (Get conversation message history) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Conversation Service (Get conversation message active) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    getConversationEmailActivityHistory(
        conversationId: string,
    ): Observable<IEmailMessageHistory[]> {
        return this.http
            .get<
                IEmailMessageHistory[]
            >(`${environment.contentUrl}/messages/email/byconversationid?conversationId=${conversationId}`)
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Conversation Service (Get conversation email history) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Conversation Service (Get conversation email active) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    makeCustomerActive(
        customer: ICustomerDetails,
        conversationId: string,
    ): Observable<void> {
        return this.http
            .post<void>(
                `${environment.workflowUrl}/api/conversation/${conversationId}/setData`,
                [
                    {
                        key: 'CustomerId',
                        value: customer.customerId,
                    },
                ],
            )
            .pipe(
                tap({
                    next: () => {
                        this.logger.debug(
                            'Conversation Service (Set customer active) -> Successful',
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Conversation Service (Set customer active) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }
}
