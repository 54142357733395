export type CustomFieldDefinitionType = 'Text' | 'Choice';

export interface ICustomFields {
    tenantId: string;
    businessUitId: string | null;
    fieldDefinitions: ICustomFieldDefinition[];
}

export interface ICustomFieldDefinition {
    id: number;
    apiName: string;
    label: string;
    type: CustomFieldDefinitionType;
    options: string[] | null;
}
