import { Component, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

interface DialpadButton {
    number: string;
    additional: string;
}

@Component({
    selector: 'voice-dialpad',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './voice-dialpad.component.html',
    styleUrl: './voice-dialpad.component.scss',
})
export class VoiceDialpadComponent {
    buttonClick = output<string>();

    dialpad: DialpadButton[][] = [
        [
            { number: '1', additional: '\u00A0' },
            { number: '2', additional: 'A B C' },
            { number: '3', additional: 'D E F' },
        ],
        [
            { number: '4', additional: 'G H I' },
            { number: '5', additional: 'J K L' },
            { number: '6', additional: 'M N O' },
        ],
        [
            { number: '7', additional: 'P Q R S' },
            { number: '8', additional: 'T U V' },
            { number: '9', additional: 'W X Y Z' },
        ],
        [
            { number: '*', additional: '\u00A0' },
            { number: '0', additional: '+' },
            { number: '#', additional: '\u00A0' },
        ],
    ];

    onButtonClick(number: string): void {
        this.buttonClick.emit(number);
    }
}
