import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { TelXLButtonComponent } from '../button/button.component';

@Component({
    selector: 'telxl-attachment-button',
    standalone: true,
    imports: [CommonModule, TelXLButtonComponent],
    templateUrl: './attachment-button.component.html',
    styleUrl: './attachment-button.component.scss',
})
export class TelXLAttachmentButtonComponent {
    buttonLabel = input<string>('');
    attachClicked = output<void>();

    onAttachClick() {
        this.attachClicked.emit();
    }
}
