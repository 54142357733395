import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'telxl-tag',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
    host: {
        '[class]': 'class || ""',
    },
})
export class TelXLTagComponent {
    @Input({ required: true }) text!: string;
    @Input() outline = false;
}
