import { Injectable } from '@angular/core';

import { BehaviorSubject, map } from 'rxjs';

import { IAgent } from '../../../../../models';

@Injectable({
    providedIn: 'root',
})
export class AgentDataService {
    #agents = new BehaviorSubject<IAgent[]>([]);

    listen() {
        return this.#agents;
    }

    listenForActive() {
        return this.#agents.pipe(
            map(agents => agents.filter(agent => agent.state?.state === 'Idle'))
        )
    }

    setAgents(agents: IAgent[]) {
        const currentAgents = this.#agents.getValue();
        const newAgents = agents.filter(agent => 
            !currentAgents.some(existingAgent => existingAgent.id === agent.id)
        );
        this.#agents.next([...currentAgents, ...newAgents]);
    }
}
