import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { IConversation } from '@dxp/shared/models';
import { InitialsPipe } from '@dxp/shared/pipes';

@Component({
    selector: 'voice-call-info',
    standalone: true,
    imports: [CommonModule, FormsModule, InitialsPipe],
    templateUrl: './voice-call-info.component.html',
    styleUrl: './voice-call-info.component.scss',
})
export class VoiceCallInfoComponent {
    @Input() conversation?: IConversation;
    @Input() customerName = '';
    @Input() showRecording = false;
    @Input() callTime?: string;
}
