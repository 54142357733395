import { Injectable, computed, signal } from '@angular/core';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { delay, of, retryWhen, scan, take, tap, throwError } from 'rxjs';
import { LoggerService } from '../services/logger/logger.service';

export interface ISignalRStatistics {
    state: HubConnectionState;
    lastMessage?: Date;
    count: number;
}

const SendRetryInterval = 1000;
const sendRetryLimit = 3;

@Injectable({
    providedIn: 'root',
})
export abstract class SignalRHubService {
    protected hubConnection!: HubConnection;

    private _lastMessageSignal = signal<Date | undefined>(undefined);
    private _messageCountSignal = signal<number>(0);
    private _connectionStateSignal = signal<HubConnectionState>(
        HubConnectionState.Disconnected,
    );

    statistics = computed<ISignalRStatistics>(() => {
        return {
            state: this._connectionStateSignal(),
            lastMessage: this._lastMessageSignal(),
            count: this._messageCountSignal(),
        };
    });

    constructor(
        private name: string,
        private url: string,
        private oidcSecurityService: OidcSecurityService,
        protected logger: LoggerService,
    ) {}

    start(parameters?: Record<string, string[]>) {
        this.oidcSecurityService
            .getAccessToken()
            .pipe(take(1))
            .subscribe(token => {
                this.hubConnection = new HubConnectionBuilder()
                    .withUrl(`${this.url}${this.parseParameters(parameters)}`, {
                        accessTokenFactory: () => token,
                    })
                    .configureLogging(LogLevel.Error)
                    .withAutomaticReconnect()
                    .build();

                this.hubConnection.onreconnected(() => {
                    this.logger.info(
                        `${this.name} Service -> Reconnected successfully`,
                    );

                    this.updateConnectionState(HubConnectionState.Connected);
                });

                this.hubConnection.onclose(() => {
                    this.logger.info(
                        `${this.name} Service -> Connection closed`,
                    );

                    this.updateConnectionState(HubConnectionState.Disconnected);
                });

                this.hubConnection
                    .start()
                    .then(() => {
                        this.updateConnectionState(
                            HubConnectionState.Connected,
                        );
                        this.registerHandlers();
                        this.onStart();

                        this.logger.info(
                            `${this.name} Service -> Connection started`,
                        );
                    })
                    .catch(error =>
                        this.logger.error(
                            `${this.name} Service -> Error while starting connection`,
                            error,
                        ),
                    );
            });
    }

    stop(): Promise<void> {
        if (this.hubConnection) {
            this.unregisterHandlers();

            return this.hubConnection
                .stop()
                .then(() => {
                    this.updateConnectionState(HubConnectionState.Disconnected);
                    this.onStop();

                    this.logger.info(
                        `${this.name} Service -> Connection stopped`,
                    );
                })
                .catch(error =>
                    this.logger.error(
                        `${this.name} Service -> Error while stopping connection`,
                        error,
                    ),
                );
        }

        return Promise.resolve();
    }

    restart(parameters?: Record<string, string[]>) {
        this.stop()
            .then(() => {
                this.logger.info(
                    `${this.name} Service -> Restarting connection`,
                );
                this.start(parameters);
            })
            .catch(error => {
                this.logger.error(
                    `${this.name} Service -> Error while restarting connection`,
                    error,
                );
            });
    }

    send(command: string, ...args: any[]) {
        if (this.state === HubConnectionState.Connected) {
            this.hubConnection
                .send(command, ...args)
                .then(() => {
                    this.logger.info(
                        `${this.name} Service -> Command (${command}) sent successfully`,
                    );
                })
                .catch(error => {
                    this.logger.error(
                        `${this.name} Service -> Error sending command (${command})`,
                        error,
                    );
                    of(null)
                        .pipe(
                            retryWhen(errors =>
                                errors.pipe(
                                    scan((retryCount, err) => {
                                        if (retryCount >= sendRetryLimit) {
                                            throw err;
                                        }
                                        this.logger.warn(
                                            `${this.name} Service -> Retry ${retryCount + 1} for command (${command})`,
                                        );
                                        return retryCount + 1;
                                    }, 0),
                                    delay(SendRetryInterval),
                                ),
                            ),
                            tap(() => {
                                if (
                                    this.state === HubConnectionState.Connected
                                ) {
                                    this.hubConnection.send(command, ...args);
                                } else {
                                    throwError(
                                        () =>
                                            new Error(
                                                `${this.name} Service -> Unable to send command (${command}) due to disconnected state.`,
                                            ),
                                    );
                                }
                            }),
                        )
                        .subscribe({
                            error: retryError => {
                                this.logger.error(
                                    `${this.name} Service -> Failed to send command (${command}) after retries.`,
                                    retryError,
                                );
                            },
                        });
                });
        } else {
            this.logger.error(
                `${this.name} Service -> Failed to send command (${command}), connection is not active.`,
            );
        }
    }

    get state(): HubConnectionState {
        return this.hubConnection
            ? this.hubConnection.state
            : HubConnectionState.Disconnected;
    }

    protected messageReceived() {
        this._lastMessageSignal.set(new Date());
        this._messageCountSignal.update(count => count + 1);
    }

    private parseParameters(parameters?: Record<string, string[]>): string {
        if (parameters) {
            return `?${Object.keys(parameters)
                .map(key => `${key}=${parameters[key].join(',')}`)
                .join('&')}`;
        }

        return '';
    }

    private updateConnectionState(state: HubConnectionState) {
        this._connectionStateSignal.set(state);
    }

    protected onStart() {}
    protected onStop() {}
    protected registerHandlers() {}
    protected unregisterHandlers() {}
}
