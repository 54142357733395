<div class="container-fluid">
    <div class="row box-details">
        <div class="col-12">
            <div
                class="d-flex justify-content-center align-items-center pt-4 pb-4 mt-2 mb-2"
            >
                <div>
                    <i
                        class="fa-sharp-duotone fa-light fa-3x fa-arrow-right-arrow-left"
                    ></i>
                </div>
                <div class="d-flex flex-column">
                    <div class="font-lg ms-4">
                        Call Transfered to {{ contactSelected.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
