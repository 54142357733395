import { Injectable } from '@angular/core';
import { OperatorFunction, timer } from 'rxjs';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import isEqual from 'lodash/isEqual';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class DataChangeOperatorsService {
    constructor(private logger: LoggerService) {}
    
    /**
     * Returns an operator that only emits when the value has changed.
     * Uses a deep equality check so it works for both simple and complex types.
     */
    distinctChange<T>(): OperatorFunction<T, T> {
        return distinctUntilChanged((prev, curr) => {
            const hasNotChanged = isEqual(prev, curr);
            if (hasNotChanged) {
                this.logger.debug(
                    'DataChangeOperatorsService',
                    'distinctChange',
                    {
                        prev,
                        curr,
                        hasNotChanged,
                    },
                );
            }
            return hasNotChanged;
        });
    }

    /**
     * Returns an operator that delays each emission by the specified time.
     * The value is emitted after the delay.
     * @param delayMs The delay in milliseconds.
     */
    delayedEmit<T>(delayMs: number): OperatorFunction<T, T> {
        return switchMap(value => timer(delayMs).pipe(map(() => value)));
    }

    /**
     * Returns an operator that "clears" the value by replacing each emission with a provided default.
     * For example, you could use this operator to force the stream to emit a cleared state.
     * @param defaultValue The value to use when clearing.
     */
    clear<T>(defaultValue: T): OperatorFunction<T, T> {
        return map(() => defaultValue);
    }
}
