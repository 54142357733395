<ng-template #modalTemplate let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ title() }}</h5>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <ng-content select="[modal-footer]"></ng-content>
    </div>
</ng-template>
