<div class="h-100">
    <div class="d-flex align-items-center h-100">
        <div class="align-self-start mt-2 ms-2 font-sm">Current Status</div>
        <div class="ms-4">
            <i class="fa-light fa-2x fa-circle-check"></i>
        </div>
        <div class="ms-4 me-1 fs-3">Available</div>
        <div class="ms-5 fs-2">
            {{ stateSince | formatDuration }}
        </div>
    </div>
</div>
