import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { LoggerService } from '../logger/logger.service'; // Import the LoggerService

const CACHE_KEY = 'userInputMessages';

@Injectable({ providedIn: 'root' })
export class ChatInputService {
    // Use a BehaviorSubject to always have the latest value
    #messagesSubject: BehaviorSubject<string>;

    // Expose an observable of the messages
    messages$: Observable<string>;

    constructor(private logger: LoggerService) {
        // Inject the LoggerService
        // Load any cached value from localStorage on initialization
        const cachedValue = localStorage.getItem(CACHE_KEY) || '';
        this.#messagesSubject = new BehaviorSubject<string>(cachedValue);
        this.messages$ = this.#messagesSubject
            .asObservable()
            .pipe(filter(value => !!value));
        this.log('UserInputService initialized with cached value', cachedValue);
    }

    /**
     * Replace the current message with a new one.
     * @param newMessage The new message string.
     */
    addMessage(newMessage: string): void {
        this.#messagesSubject.next(newMessage);
        this.cacheMessages(newMessage);
        this.log('New message added', newMessage);
    }

    /**
     * Append a new string to the current message.
     * @param message The message string to append.
     */
    appendMessage(message: string): void {
        const current = this.#messagesSubject.getValue();
        // You can customize the separator; here we use a space.
        const newMessages = current ? `${current} ${message}` : message;
        this.#messagesSubject.next(newMessages);
        this.cacheMessages(newMessages);
        this.log('Message appended', message);
    }

    /**
     * Private helper to cache the current messages in localStorage.
     * @param value The current message string.
     */
    private cacheMessages(value: string): void {
        localStorage.setItem(CACHE_KEY, value);
        this.log('Messages cached', value);
    }

    /**
     * Clear all messages.
     */
    clearMessages(): void {
        this.#messagesSubject.next('');
        localStorage.removeItem(CACHE_KEY);
        this.log('All messages cleared', '');
    }

    /**
     * * Get the current message.
     * @returns The current message string.
     */
    getMessage(): string {
        return this.#messagesSubject.getValue();
    }

    /**
     * Private helper to log messages.
     * @param method The method name.
     * @param value The value to log.
     */
    private log(method: string, value: any): void {
        this.logger.debug(`${method}:`, value);
    }
}
