import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAgentReport } from './../../../../models/agent/agent';
import { IQueueWithChannelsReport } from './../../../../models/queue/queue';
import { AggregationsHubService } from './../../../../signalr/aggregations/aggregations.hub.service';

@Injectable({
    providedIn: 'root',
})
export class AggregationsHubWrapperService {
    constructor(private aggregationsHubService: AggregationsHubService) {}

    get agentReport$(): Observable<IAgentReport> {
        return this.aggregationsHubService.agentReport$;
    }

    get queueReport$(): Observable<IQueueWithChannelsReport> {
        return this.aggregationsHubService.queueReport$;
    }
}
