@if (orientation === 'fixed-bottom') {
    <div
        class="progress-bar fixed-bottom"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        [style.width]="percent() + '%'"
        [ngClass]="['bg-' + state(), percent() === 100 ? 'rounded-end' : '']"
    ></div>
} @else if (orientation === 'vertical') {
    <div class="h-100">
        <div class="font-sm weight-medium p-0 m-0">{{ label }}</div>
        <div class="font-xxl weight-medium p-0 m-0">
            {{ overall() }}
            <span class="font-sm weight-heavy">
                @if (percentage) {
                    %
                }
            </span>
        </div>
        @if (metric()) {
            <ngb-progressbar
                [type]="state()"
                [value]="percent()"
                height=".3rem"
            />
        } @else {
            <span style="height: 0.3rem; display: flex"></span>
        }
    </div>
} @else if (orientation === 'horizontal') {
    <div [ngClass]="class">
        <div
            [ngStyle]="{
                display: 'flex',
                'justify-content': 'space-between',
                'align-items': emphasis === 'light' ? 'center' : 'flex-start',
            }"
        >
            <span
                class="p-0 m-0"
                [ngClass]="emphasis === 'light' ? 'font-sm ' : ' weight-medium'"
            >
                {{ label }}
            </span>
            <span
                class="weight-medium p-0 m-0"
                [ngClass]="[
                    percentage || emphasis === 'light' ? '' : 'pb-1',
                    emphasis === 'light' ? 'font-lg' : 'font-xxl',
                ]"
                [ngStyle]="
                    emphasis === 'light' ? { 'line-height': '1rem' } : {}
                "
            >
                {{ overall() }}
                @if (percentage) {
                    <span
                        class="font-sm weight-heavy"
                        [ngClass]="{
                            'fix-small-percentage': emphasis === 'light',
                        }"
                    >
                        %
                    </span>
                }
            </span>
        </div>
        @if (metric()) {
            <ngb-progressbar
                [type]="state()"
                [value]="percent()"
                height=".3rem"
            />
        }
    </div>
}
