import { HttpClient, HttpParams } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { LoggerService } from '../../services/logger/logger.service';
import { environment } from './../../environments/environment';
import {
    IAgent,
    IAssignToMe,
    ICompleteWorkItemParams,
    IInternalHistoricalWorkItem,
    IInternalHistoricalWorkItems,
} from './../../models';

@Injectable({
    providedIn: 'root',
})
export class AgentApiService {
    private _unableToAssign = signal<string[]>([]);
    unableToAssign = computed<string[]>(() =>
        this._unableToAssign.asReadonly()(),
    );

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getByBusinessUnit(businessUnitId: string) {
        return this.http.get<IAgent[]>(
            `${environment.blenderUrl}/agents?businessUnitId=${businessUnitId}`,
        );
    }

    reserveForLookup() {
        this.http
            .post<string>(
                environment.blenderUrl + '/resource/reserveForLookup',
                null,
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Agent Service (Look up activated) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Agent Service (Look up activated) -> Failed',
                        error,
                    );
                },
            });
    }

    assignToMe(conversationId: string): Observable<IAssignToMe> {
        return this.http
            .post<IAssignToMe>(
                environment.blenderUrl +
                    `/resource/${conversationId}/assignToMe`,
                null,
            )
            .pipe(
                tap({
                    next: result => {
                        this.logger.debug(
                            'Agent Service (Conversation assigned to Agent) -> Successful',
                        );

                        if (result.result !== 'Ok') {
                            this._unableToAssign.update(u => [
                                ...u,
                                conversationId,
                            ]);
                        }
                    },
                    error: error => {
                        this.logger.error(
                            'Agent Service (Conversation assigned to Agent) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }

    resetWrapup(workitemId: string) {
        this.http
            .post<void>(
                environment.blenderUrl +
                    `/resource/resetwrapup?workitemId=${workitemId}`,
                null,
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Agent Service (Reset wrapup) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Agent Service (Reset wrapup) -> Failed',
                        error,
                    );
                },
            });
    }

    completeWorkItem(params: ICompleteWorkItemParams) {
        this.http
            .post<void>(
                environment.blenderUrl + '/resource/completeWorkItem',
                params,
            )
            .subscribe({
                next: () => {
                    this.logger.debug(
                        'Agent Service (Work item completed) -> Successful',
                    );
                },
                error: error => {
                    this.logger.error(
                        'Agent Service (Work item completed) -> Failed',
                        error,
                    );
                },
            });
    }

    getWorkitemHistoryByAgent(
        agentId: string,
        count: boolean,
        expand: string[],
        skip: number,
        top: number,
        orderBy: string,
        mediaType: {
            voice?: boolean;
            webchat?: boolean;
            email?: boolean;
            messaging?: boolean;
        },
    ): Observable<IInternalHistoricalWorkItems> {
        const mediatypes: string[] = Object.entries(mediaType)
            .filter(([_, value]) => value)
            .map(([key, _]) => key);

        const agentFilter = `AgentId eq '${agentId}'`;
        const mediaFilter = `mediatype in (${mediatypes.map(type => `'${type}'`).join(',')})`;

        const filter = [agentFilter, mediaFilter]
            .filter(x => !!x)
            .join(' and ');

        const params = new HttpParams()
            .set('$count', count.toString())
            .set('$expand', expand.join(','))
            .set('$skip', skip.toString())
            .set('$top', top.toString())
            .set('$orderBy', orderBy)
            .set('$filter', filter);

        return this.http
            .get<{
                '@odata.count': number;
                value: IInternalHistoricalWorkItem[];
            }>(`${environment.odataBaseUrl}/v1/workItems`, {
                params,
            })
            .pipe(
                map(response => {
                    return {
                        count: response['@odata.count'],
                        historicalWorkitems: response.value,
                    };
                }),
                tap({
                    next: customerDirectory => {
                        this.logger.debug(
                            'Agent Service (Get Workitem History By Agent) -> Successful',
                            customerDirectory,
                        );
                    },
                    error: error => {
                        this.logger.error(
                            'Agent Service (Get Workitem History By Agent) -> Failed',
                            error,
                        );
                    },
                }),
            );
    }
}
