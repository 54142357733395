import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DirectoryApiService } from '../../api/directory/directory.api.service';
import { PresenceApiService } from '../../api/presence/presence.api.service';
import { RbacApiService } from '../../api/rbac/rbac.api.service';
import { VoiceApiService } from '../../api/voice/voice.api.service';
import { IUser } from '../../models/auth/user';
import { NBU_ID } from '../../models/business-unit/business-unit';
import { IRbacChannelProviderConfiguration } from '../../models/rbac/channel-provider';
import { IRbacRole } from '../../models/rbac/permissions';
import { AgentService } from '../../services/agent/agent.service';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import {
    ChannelProviderService,
    ChannelProviderTypes,
} from '../../services/channel-provider/channel-provider.service';
import { QueueService } from '../../services/queue/queue.service';
import { SettingsService } from '../../services/settings/settings.service';
import { TeamService } from '../../services/team/team.service';
import { TenantService } from '../../services/tenant/tenant.service';
import { UserService } from '../../services/user/user.service';
import { AgentHubService } from '../../signalr/agent/agent.hub.service';
import { AggregationsHubService } from '../../signalr/aggregations/aggregations.hub.service';
import { ChatHubService } from '../../signalr/chat/chat.hub.service';
import { EmailHubService } from '../../signalr/email/email.hub.service';
import { LiveHubService } from '../../signalr/live/live.hub.service';
import { NotificationHubService } from '../../signalr/notification/notification.hub.service';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    constructor(
        private liveHubService: LiveHubService,
        private aggregationsHubService: AggregationsHubService,
        private agentHubService: AgentHubService,
        private notificationHubService: NotificationHubService,
        private chatHubService: ChatHubService,
        private emailHubService: EmailHubService,
        private presenceApiService: PresenceApiService,
        private rbacApiService: RbacApiService,
        private userService: UserService,
        private queueService: QueueService,
        private businessUnitService: BusinessUnitService,
        private tenantService: TenantService,
        private directoryApiService: DirectoryApiService,
        private agentService: AgentService,
        private teamService: TeamService,
        private channelProviderService: ChannelProviderService,
        private settingsService: SettingsService,
        private voiceApiService: VoiceApiService,
    ) {}

    onLogin() {
        this.presenceApiService.login().subscribe({
            complete: () => {
                // Get the Breaknames
                this.presenceApiService.getBreakNames();

                this.voiceApiService.getAsteriskServers();
                this.voiceApiService.getAsteriskStunServers();
                this.voiceApiService.getAsteriskLogin();

                // Get conversation sorting method.
                this.rbacApiService.getConversationSortingMode().subscribe({
                    next: data => {
                        this.settingsService.setConversationSorting(
                            data.sortingType,
                        );
                    },
                });

                // Get the user details.
                this.rbacApiService
                    .getUser(this.userService.user().id)
                    .subscribe({
                        next: user => {
                            this.userService.update(user as IUser);

                            // Get the current tenant deatils.
                            this.rbacApiService
                                .getTenant(this.userService.user().tenantId)
                                .subscribe({
                                    next: tenant => {
                                        this.userService.update({
                                            tenant,
                                        } as IUser);

                                        this.tenantService.set(tenant);

                                        this.rbacApiService
                                            .getWrapDetails()
                                            .subscribe({
                                                next: data => {
                                                    this.settingsService.setWrapDetails(
                                                        data,
                                                    );
                                                },
                                            });

                                        // Get the configured business units.
                                        this.rbacApiService
                                            .getBusinessUnits()
                                            .subscribe({
                                                next: businessUnits => {
                                                    businessUnits.push({
                                                        name: `${tenant.name} Queues`,
                                                        id: NBU_ID,
                                                    });

                                                    this.businessUnitService.set(
                                                        businessUnits,
                                                    );

                                                    const channelProviderConfigurationRequests =
                                                        ChannelProviderTypes.map(
                                                            provider =>
                                                                this.rbacApiService.getChannelProvidersConfiguration(
                                                                    provider,
                                                                ),
                                                        );

                                                    // Get the channel provider configurations
                                                    forkJoin(
                                                        channelProviderConfigurationRequests,
                                                    ).subscribe({
                                                        next: (
                                                            channelProviderConfigurations: IRbacChannelProviderConfiguration[][],
                                                        ) => {
                                                            channelProviderConfigurations.forEach(
                                                                (
                                                                    configuration,
                                                                    index,
                                                                ) => {
                                                                    this.channelProviderService.setChannelProviderConfigurations(
                                                                        ChannelProviderTypes[
                                                                            index
                                                                        ],
                                                                        configuration,
                                                                    );
                                                                },
                                                            );

                                                            // Get the user's teams & queues
                                                            this.rbacApiService
                                                                .getTeams(
                                                                    this.userService.user()
                                                                        .id,
                                                                )
                                                                .subscribe({
                                                                    next: teams => {
                                                                        const queueData =
                                                                            teams
                                                                                ? [
                                                                                      ...new Set(
                                                                                          teams.reduce(
                                                                                              (
                                                                                                  accumulator,
                                                                                                  currentValue,
                                                                                              ) => [
                                                                                                  ...accumulator,
                                                                                                  ...currentValue.queueIds,
                                                                                              ],
                                                                                              [] as string[],
                                                                                          ),
                                                                                      ),
                                                                                  ]
                                                                                : [];
                                                                        this.queueService.set(
                                                                            queueData,
                                                                        );

                                                                        this.teamService.set(
                                                                            teams,
                                                                        );

                                                                        this.agentService.set(
                                                                            teams
                                                                                ? [
                                                                                      ...new Set(
                                                                                          teams.reduce(
                                                                                              (
                                                                                                  accumulator,
                                                                                                  currentValue,
                                                                                              ) => [
                                                                                                  ...accumulator,
                                                                                                  ...currentValue.memberIds,
                                                                                              ],
                                                                                              [] as string[],
                                                                                          ),
                                                                                      ),
                                                                                  ]
                                                                                : [],
                                                                        );

                                                                        // SignalR queues can start after queues are set.
                                                                        this.startSignalRHubs();
                                                                    },
                                                                });
                                                        },
                                                    });
                                                },
                                            });
                                    },
                                });
                        },
                    });

                // Get and populate list of tenants the user has access to.
                this.rbacApiService
                    .getConnectedUsers(this.userService.user().id)
                    .subscribe({
                        next: tenants => {
                            this.userService.update({ tenants } as IUser);
                        },
                    });

                // Get user image.
                this.directoryApiService.getPhoto().subscribe({
                    next: (photo: Blob | null) => {
                        if (photo) {
                            this.userService.update({
                                photo: URL.createObjectURL(photo),
                            } as IUser);
                        }
                    },
                });

                // Get user permissions.
                this.rbacApiService.getPermissions().subscribe({
                    next: (role: IRbacRole) => {
                        this.userService.setPermissions(role);
                    },
                });
            },
        });
    }

    onActivate() {
        this.presenceApiService.activate().subscribe();
    }

    onLogoff() {
        this.presenceApiService.logout().subscribe();
    }

    onAccessTokenChange() {
        this.agentHubService.restart();
        this.notificationHubService.restart();

        this.liveHubService.restart({
            queueIds: this.queueService.queues().map(q => q.id),
        });

        this.aggregationsHubService.restart({
            queueIds: this.queueService.queues().map(q => q.id),
            agentIds: [this.userService.user().id],
        });

        this.chatHubService.restart();
        this.emailHubService.restart();
    }

    startSignalRHubs() {
        this.agentHubService.start();
        this.notificationHubService.start();

        this.liveHubService.start({
            queueIds: this.queueService.queues().map(q => q.id),
        });

        this.aggregationsHubService.start({
            queueIds: this.queueService.queues().map(q => q.id),
            agentIds: this.agentService.allAgents().map(a => a.id),
        });

        this.chatHubService.start();
        this.emailHubService.start();
    }

    stopSignalRHubs() {
        this.agentHubService.stop();
        this.notificationHubService.stop();
        this.aggregationsHubService.stop();
        this.liveHubService.stop();
        this.chatHubService.stop();
        this.emailHubService.stop();
    }
}
