import { CommonModule } from '@angular/common';
import { Component, ViewChild, input, output, signal } from '@angular/core';
import {
    IAxisTransferDetails,
    ITransferAgent,
    ITransferQueue,
} from '@dxp/shared/models';

import { TelXLButtonComponent } from '../../button/button.component';
import { TelXLModalComponent } from '../../modal/modal.component';
import { ITab, TelXLTabComponent } from '../../tab/tab.component';
import { TransferAgentsComponent } from './agents/agents.component';
import { TransferQueuesComponent } from './queues/queues.component';

@Component({
    selector: 'telxl-transfer',
    standalone: true,
    imports: [
        CommonModule,
        TelXLModalComponent,
        TelXLTabComponent,
        TransferQueuesComponent,
        TransferAgentsComponent,
        TelXLButtonComponent,
    ],
    templateUrl: './transfer.component.html',
    styleUrl: './transfer.component.scss',
})
export class TelXLTransferComponent {
    chatTransferDetails = input.required<IAxisTransferDetails>();
    isShowAgents = signal(false);
    isShowQueues = signal(true);
    @ViewChild('modal') modal!: TelXLModalComponent;
    selectedTab = signal<string>('queues');
    tabs: ITab[] = [
        { label: 'Queues', index: 'queues' },
        // Commented out for V1
        // { label: 'Agents', index: 'agents' },
    ];
    unfilteredQueues = signal<ITransferQueue[]>([]);
    selectedQueue = output<ITransferQueue>();

    openModal() {
        this.modal.open('xl');
    }

    onReleaseBackToQueue(queue: ITransferQueue) {
        this.selectedQueue.emit(queue);
    }

    setSelected(tab: string) {
        this.selectedTab.set(tab);
        this.isShowAgents.set(tab === 'agents');
        this.isShowQueues.set(tab === 'queues');
    }

    setSelectedAgent(agent: ITransferAgent) {
        console.log('Selected Agent:', agent);
    }

    onTransfer(queue: ITransferQueue) {
        this.modal.close();

        this.selectedQueue.emit(queue);
    }
}
