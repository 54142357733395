import { Component, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskBeltService } from './service/task-belt.service';
import { IWorkItem } from '@dxp/shared/models';
import { TaskBeltStatusPanelComponent } from './status-panel/task-belt-status-panel.component';
import { TaskBeltContainerPanelComponent } from './container/task-belt-container-panel.component';

@Component({
    selector: 'app-shell-task-belt',
    templateUrl: './task-belt.component.html',
    styleUrls: ['./task-belt.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TaskBeltStatusPanelComponent,
        TaskBeltContainerPanelComponent,
    ],
})
export class TaskBeltComponent {
    workItems = computed<IWorkItem[]>(() => this.taskbeltService.workItems());
    hasWorkItems = computed<boolean>(() => this.workItems().length > 0);

    constructor(private taskbeltService: TaskBeltService) {}
}
