import { IMessageAttachment } from './message';

interface IEmailContent {
    id: string;
    content: string;
    contentType: string;
}

interface IEmailContact {
    name: string | null;
    address: string;
}

export interface IEmailMessageHistory {
    messageId: string;
    conversationId: string;
    tenantId: string;
    threadId: string;
    creationDate: string;
    subject: string | null;
    from: IEmailContact;
    participantId: string | null;
    bodyPreview: string | null;
    bodyId: string | null;
    toRecipients: IEmailContact[];
    ccRecipients: IEmailContact[];
    bccRecipients: IEmailContact[];
    replyTo: IEmailContact[] | null;
    senderType: string | null;
    lastSeenByUserId: string | null;
    body: IEmailContent | null;
    attachments: IMessageAttachment[] | null;
}
