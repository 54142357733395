import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    EventEmitter,
    Input,
    Output,
    signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { CustomerApiService, VoiceApiService } from '@dxp/shared/api';
import { IConversation, ICustomerDetails, IWorkItem } from '@dxp/shared/models';
import { FormatDurationPipe, InitialsPipe } from '@dxp/shared/pipes';
import {
    HeartbeatService,
    SipService,
    WorkItemService,
} from '@dxp/shared/services';
import { Duration, intervalToDuration } from 'date-fns';

@Component({
    selector: 'voice-notification',
    standalone: true,
    imports: [CommonModule, FormatDurationPipe, InitialsPipe],
    templateUrl: './voice-notification.component.html',
    styleUrl: './voice-notification.component.scss',
})
export class VoiceNotificationComponent {
    constructor(
        private workItemService: WorkItemService,
        private customerApiService: CustomerApiService,
        private sipService: SipService,
        private voiceApiService: VoiceApiService,
        private router: Router,
        private heartbeatService: HeartbeatService,
    ) {
        this._now = this.heartbeatService.lastHeartbeat;
    }

    @Input() workItemId!: string;
    @Output() close = new EventEmitter<void>();

    private _now = signal<Date>(new Date());
    private _startTimer = new Date();

    navigateTo = '';
    timeout = 0;

    customer = computed<ICustomerDetails | null>(() =>
        this.customerApiService.customer(),
    );
    _workItem = computed<IWorkItem | null>(() => {
        const workItemId = this.workItemId;
        if (!workItemId) return null;
        return (
            this.workItemService
                .workItemsSignal()
                .find(item => item.workItemId === workItemId) || null
        );
    });

    customerName = computed(() => this.customer()?.name ?? 'Unknown customer');
    _conversation = computed<IConversation | undefined>(() => {
        const workItem = this.workItem();
        if (workItem) {
            if (workItem.customerId) {
                this.customerApiService.getCustomer(workItem.customerId, [
                    'PhoneNumbers',
                    'ExternalProfiles',
                    'CustomFields',
                ]);
            }
            return workItem.conversations.find(
                c => c.conversationId === workItem.primaryConversationId,
            );
        }
        return undefined;
    });

    get workItem() {
        return this._workItem;
    }

    get conversation() {
        return this._conversation;
    }

    onAcceptClick(): void {
        const workItem = this.workItem();
        if (workItem) {
            this.sipService.answerCall();
            this.voiceApiService.changeCallState({
                conversationId: workItem.primaryConversationId,
                callState: 'Answer',
            });
            this.router.navigate([this.navigateTo, this.workItemId]);
        }
        this.close.emit();
    }

    onRejectClick(): void {
        const workItem = this.workItem();
        if (workItem) {
            this.voiceApiService.changeCallState({
                conversationId: workItem.primaryConversationId,
                callState: 'Reject',
            });
            this.sipService.hangUp();
        }
        this.close.emit();
    }

    get countdown(): Duration {
        return intervalToDuration({
            start: new Date(),
            end: new Date().setSeconds(
                this._startTimer.getSeconds() + this.timeout,
            ),
        });
    }

    get progress(): number {
        const remainingTime =
            this.timeout -
            Math.round(
                (this._now().getTime() - this._startTimer.getTime()) / 1000,
            );
        if (remainingTime <= 0) {
            this.onRejectClick();
        }
        return (remainingTime / this.timeout) * 100;
    }
}
