import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { delay, of, OperatorFunction, retry, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    constructor(private logger: LoggerService) {}

    handleError(message: string, error?: any) {
        const appendedMessage = `${message}: ${error?.message}`;
        this.logger.error(appendedMessage, error);

        return throwError(() => new Error(appendedMessage));
    }

    logErrorAndDelayRetry<T>(
        loggerMessage = 'Error received, retrying',
        retryTimes = 3,
        delayMileseconds = 2000,
    ): OperatorFunction<T, T> {
        return retry({
            count: retryTimes,
            delay: (error, retryCount) => {
                this.logger.error(loggerMessage, error);

                const incrementalDelay = delayMileseconds * retryCount;
                this.logger.info(
                    `Retry #${retryCount} after ${incrementalDelay}ms`,
                );

                return of(null).pipe(delay(incrementalDelay));
            },
        });
    }
}
