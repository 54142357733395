import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TelXLAttachmentSelectorComponent } from './selector/attachment-selector.component';
import { AttachmentListComponent } from "./list/attachment-list.component";

@Component({
    selector: 'telxl-attachment',
    standalone: true,
    imports: [CommonModule, TelXLAttachmentSelectorComponent, TelXLAttachmentComponent, AttachmentListComponent],
    templateUrl: './attachment.component.html',
    styleUrl: './attachment.component.scss',
})
export class TelXLAttachmentComponent {}
