import { CommonModule } from '@angular/common';
import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { ColourPaletteType, MediaType } from '@dxp/shared/models';

@Component({
    selector: 'telxl-panel',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './panel.component.html',
    styleUrls: [
        './panel.component.scss',
        './panel.component.default.scss',
        './panel.component.webchat.scss',
        './panel.component.email.scss',
    ],
    host: {
        '[class]': 'class || ""',
        '(mouseenter)': 'onMouseEnter()',
        '(mouseleave)': 'onMouseLeave()',
    },
})
export class TelXLPanelComponent {
    @Input() theme: ColourPaletteType | MediaType | 'default' = 'default';
    @Input() minHeight?: string;
    @Input() maxHeight?: string;
    @Input() isExpanded = true;
    @Input() highlight = false;
    @Input() hover = false;
    @Input() scroll = false;
    @Input() cardClass = '';
    @Input() headerClass = '';
    @Input() bodyClass = '';
    @Input() footerClass = '';

    @Output() hoverState = new EventEmitter<boolean>();

    @ContentChild('cardHeader', { static: true })
    headerTemplate!: TemplateRef<unknown>;
    @ContentChild('cardBody', { static: true })
    bodyTemplate!: TemplateRef<unknown>;
    @ContentChild('cardFooter', { static: true })
    footerTemplate!: TemplateRef<unknown>;

    onMouseEnter(): void {
        this.hoverState.emit(true);
    }

    onMouseLeave(): void {
        this.hoverState.emit(false);
    }

    get cardClassName(): string {
        return `${this.theme.toLowerCase()} ${this.cardClass} ${this.hover ? 'hover' : ''} ${this.highlight ? 'shadow' : ''} ${this.scroll ? 'scroll' : ''}`;
    }
}
