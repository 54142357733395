import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';

@Component({
    selector: 'telxl-container-overlay',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './container-overlay.component.html',
    styleUrl: './container-overlay.component.scss',
})
export class TelXLContainerOverlayComponent {
    topOpacity = input('0.5');
    overlayBackground = input('108,117,125');
    isOverlayVisible = input<boolean>(false);

    opacity = computed(() => {
        const result = `rgb(${this.overlayBackground()}, ${this.topOpacity()})`;

        return result;
    });
}
