<div *ngIf="workitem()" class="p-3 wrap-container">
    <div class="font-xl text-start">
        <span class="weight-medium">Task wrap up</span>
    </div>
    <div class="mt-3 d-flex align-items-start">
        <telxl-dropdown
            colour="light"
            [menuItems]="menuItems()"
            [selected]="selectedCode"
            placeholder="Completion Codes"
            class="mw-40"
            (selectedChange)="onCodeChange($event)"
        />
    </div>
    <div class="font-sm mt-3 text-start mb-2">Any notes from this task?</div>
    <div>
        <textarea
            placeholder="Notes.."
            class="w-100 notes-text p-2"
            rows="5"
            [(ngModel)]="notes"
            (ngModelChange)="onNotesChange($event)"
        ></textarea>
    </div>
    <div class="font-sm weight-medium mt-3">
        <div class="mt-1">
            <div class="d-flex flex-row align-items-center">
                <div class="flex-grow-1">
                    <div>
                        <telxl-progressbar
                            orientation="horizontal"
                            [metric]="wrapCountdown().metric"
                            defaultState="danger"
                        />
                    </div>
                </div>
                <div class="ms-2 flex-shrink-1 text-end">
                    {{ wrapCountdown().duration | formatDuration }}
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-4"></div>
        <div class="col-4 pe-0">
            <div class="me-3">
                <telxl-button
                    colour="light"
                    (buttonClick)="extend()"
                    [disabled]="!canExtend()"
                >
                    Reset Wrap
                </telxl-button>
            </div>
        </div>
        <div class="col-4 ps-0">
            <telxl-button colour="dark" (buttonClick)="complete()">
                Complete Task
            </telxl-button>
        </div>
    </div>
    @if (canExtend()) {
        <div class="row">
            <div class="col-4"></div>
            <div class="col-4 font-xs text-center wrapResets">
                {{ resetDescription() }}
            </div>
        </div>
    }
</div>
