import { QueueMediaType } from '../models/queue/queue';
import { ChannelType } from '../models/types/channel-type';

export const channelTypeToQueueMediaType = (
    channelType: ChannelType,
): QueueMediaType => {
    switch (channelType) {
        case 'voice':
            return 'Voice';
        case 'email':
            return 'Email';
        case 'webchat':
            return 'Webchat';
        default:
            return 'Socials';
    }
};
