import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import {
    IConnectCommand,
    IConversationCommand,
    IDisconnectCommand,
    IMessageCommand,
    ITypingCommand,
    ITransferToAgentCommand,
} from '../../models/conversation/webchat/commands';
import {
    IChatHistoryEvent,
    IChatMessageEvent,
    IConversationEvent,
    IConversationInfoEvent,
    IErrorEvent,
    IParticipantTypingEvent,
    IRecommendationEvent,
} from '../../models/conversation/webchat/events';
import { LoggerService } from '../../services/logger/logger.service';
import { SignalRHubService } from '../signalr.hub.service';

@Injectable({
    providedIn: 'root',
})
export class ChatHubService extends SignalRHubService {
    private chatEndedSubject = new Subject<IConversationEvent>();
    private chatHistorySubject = new Subject<IChatHistoryEvent>();
    private chatMessageSubject = new Subject<IChatMessageEvent>();
    private conversationInfoSubject = new Subject<IConversationInfoEvent>();
    private errorSubject = new Subject<IErrorEvent>();
    private participantTypingSubject = new Subject<IParticipantTypingEvent>();
    private recommendationsSubject = new Subject<IRecommendationEvent>();
    private refreshSubject = new Subject<IConversationEvent>();

    chatEnded$ = this.chatEndedSubject.asObservable();
    chatHistory$ = this.chatHistorySubject.asObservable();
    chatMessage$ = this.chatMessageSubject.asObservable();
    conversationInfo$ = this.conversationInfoSubject.asObservable();
    error$ = this.errorSubject.asObservable();
    participantTyping$ = this.participantTypingSubject.asObservable();
    recommendations$ = this.recommendationsSubject.asObservable();
    refresh$ = this.refreshSubject.asObservable();

    constructor(
        logger: LoggerService,
        oidcSecurityService: OidcSecurityService,
    ) {
        super(
            'Chat',
            `${environment.apiBaseUrl}/chat/hub`,
            oidcSecurityService,
            logger,
        );
    }

    accept(command: IConversationCommand) {
        this.send('Accept', command);
    }

    connect(command: IConnectCommand) {
        this.send('Connect', command);
    }

    peekOrConnect(command: IConversationCommand) {
        this.send('PeekOrConnect', command);
    }

    reject(command: IConversationCommand) {
        this.send('Reject', command);
    }

    sendDisconnect(command: IDisconnectCommand) {
        this.send('Disconnect', command);
    }

    sendMessage(command: IMessageCommand) {
        this.send('SendMessage', command);
    }
    initiateTransferToAgent = (command: ITransferToAgentCommand) => {
        this.send('InitiateTransfer', command);
      };
    sendTyping(command: ITypingCommand) {
        this.send('SendIsTyping', command);
    }

    protected override registerHandlers() {
        this.hubConnection.on(
            'ReceiveChatHistory',
            (chatHistory: IChatHistoryEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (ReceiveChatHistory) ->');
                this.logger.table(chatHistory);

                this.chatHistorySubject.next(chatHistory);
            },
        );

        this.hubConnection.on(
            'ReceiveChatMesssage',
            (chatMessage: IChatMessageEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (ReceiveChatMesssage) ->');
                this.logger.table(chatMessage);

                this.chatMessageSubject.next(chatMessage);
            },
        );

        this.hubConnection.on(
            'ReceiveChatMessage',
            (chatMessage: IChatMessageEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (ReceiveChatMessage) ->');
                this.logger.table(chatMessage);

                this.chatMessageSubject.next(chatMessage);
            },
        );

        this.hubConnection.on(
            'OnParticipantTyping',
            (participantTyping: IParticipantTypingEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (OnParticipantTyping) ->');
                this.logger.table(participantTyping);

                this.participantTypingSubject.next(participantTyping);
            },
        );

        this.hubConnection.on(
            'ReceiveChatEnded',
            (receiveChatEnded: IConversationEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (ReceiveChatEnded) ->');
                this.logger.table(receiveChatEnded);

                this.chatEndedSubject.next(receiveChatEnded);
            },
        );

        this.hubConnection.on(
            'ReceiveConversationInfo',
            (receiveConversationInfo: IConversationInfoEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (ReceiveConversationInfo) ->');
                this.logger.table(receiveConversationInfo);

                this.conversationInfoSubject.next(receiveConversationInfo);
            },
        );

        this.hubConnection.on(
            'ReceiveRecommendations',
            (receiveRecommendations: IRecommendationEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (ReceiveRecommendations) ->');
                this.logger.table(receiveRecommendations);

                this.recommendationsSubject.next(receiveRecommendations);
            },
        );

        this.hubConnection.on('ReceiveError', (receiveError: IErrorEvent) => {
            this.messageReceived();

            this.logger.debug('Chat Service (ReceiveError) ->');
            this.logger.table(receiveError);

            this.errorSubject.next(receiveError);
        });

        this.hubConnection.on(
            'RequestRefresh',
            (requestRefresh: IConversationEvent) => {
                this.messageReceived();

                this.logger.debug('Chat Service (RequestRefresh) ->');
                this.logger.table(requestRefresh);

                this.refreshSubject.next(requestRefresh);
            },
        );
    }

    protected override unregisterHandlers() {
        this.hubConnection.off('ReceiveChatHistory');
        this.hubConnection.off('ReceiveChatMesssage');
        this.hubConnection.off('ReceiveChatMessage');
        this.hubConnection.off('OnParticipantTyping');
        this.hubConnection.off('ReceiveChatEnded');
        this.hubConnection.off('ReceiveConversationInfo');
        this.hubConnection.off('ReceiveRecommendations');
        this.hubConnection.off('ReceiveError');
        this.hubConnection.off('RequestRefresh');
    }
}
