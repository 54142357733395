import {
    Component,
    computed,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    IContactSelected,
    IConversation,
    IVoiceCommand,
} from '@dxp/shared/models';
import { VoiceApiService } from '@dxp/shared/api';
import { InitialsPipe } from '@dxp/shared/pipes';
import { VoiceService, WorkItemService } from '@dxp/shared/services';

@Component({
    selector: 'contacts-voice-enquiry',
    standalone: true,
    imports: [CommonModule, InitialsPipe],
    templateUrl: './voice-enquiry.component.html',
    styleUrl: './voice-enquiry.component.scss',
})
export class ContactVoiceEnquiryComponent implements OnInit {
    constructor(
        private voiceApiService: VoiceApiService,
        private voiceService: VoiceService,
        private workItemService: WorkItemService,
    ) {}
    @Input({ required: true }) contactSelected!: IContactSelected;
    @Input({ required: true }) conversationId!: string;
    @Input({ required: true }) workItemId!: string;
    @Output() closeContacts = new EventEmitter<boolean>();

    gotoCommand = signal('GotoCaller');
    switchText = signal('Switch Call');
    patched = signal(false);

    conversation = computed<IConversation | undefined>(() => {
        const workItemId = this.workItemId;
        if (!workItemId) return undefined;
        const workItem =
            this.workItemService
                .workItemsSignal()
                .find(item => item.workItemId === workItemId) || null;
        if (!workItem) return undefined;

        const { conversations } = workItem;
        const foundConversation = conversations.find(
            c => c.conversationId === this.conversationId,
        );

        return foundConversation;
    });

    ngOnInit() {
        this.callCommand(
            'Enquiry',
            this.contactSelected.type === 'agent'
                ? `Enquiry call:vcc_operators+${this.contactSelected.identifier}`
                : `Enquiry call:${this.contactSelected.identifier}`,
        );
        this.voiceService.panelHighlighted.set('enquiry');
    }

    endEnquiry() {
        this.callCommand('EndEnquiry');
        this.voiceService.panelHighlighted.set('main');
        this.closeContacts.emit(true);
    }

    get enquiryState(): string {
        if (this.conversation()?.enquiryCallState === 'Disconnected') {
            this.endEnquiry();
        }
        if (this.switchText() === 'Switch Back') {
            return 'Enquiry Call';
        }
        switch (this.conversation()?.enquiryCallState) {
            case 'Trying':
                return 'Calling...';
            case 'Connected':
                return 'In Enquiry';
            default:
                return '';
        }
    }

    get panelHighlighted() {
        return this.voiceService.panelHighlighted();
    }

    get enableButtons(): boolean {
        return this.enquiryState === 'Calling...';
    }

    callCommand(command: string, destination = '') {
        switch (command) {
            case 'GotoCaller':
                this.gotoCommand.set('GotoEnquiry');
                this.voiceService.panelHighlighted.set('main');
                this.switchText.set('Switch Back');
                break;
            case 'GotoEnquiry':
                this.gotoCommand.set('GotoCaller');
                this.voiceService.panelHighlighted.set('enquiry');
                this.switchText.set('Switch Call');
                break;
            case 'Patch':
                this.patched.set(true);
                break;
        }
        const params: IVoiceCommand = {
            conversationId: this.conversationId,
            destination: destination,
            newCallCommand: command,
        };
        this.voiceApiService.callCommand(params);
    }
}
