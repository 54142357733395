import { BehaviorSubject, Subscription, interval, tap } from 'rxjs';

import { Injectable, effect, signal } from '@angular/core';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class HeartbeatService {
    #heartbeatSub: Subscription | null = null;
    #lastHeartbeat = new BehaviorSubject<Date | null>(null);

    intervalMs = signal(1000);
    lastHeartbeat = signal<Date>(new Date());
    lastHeartbeat$ = this.#lastHeartbeat.asObservable();

    constructor(private logger: LoggerService) {
        effect(() => {
            this.restartHeartbeat();
        });
    }

    setIntervalMs(newInterval: number) {
        this.logger.debug(`⏳ Updating heartbeat interval to ${newInterval}ms`);
        this.intervalMs.set(newInterval);
    }

    private restartHeartbeat() {
        if (this.#heartbeatSub) {
            this.#heartbeatSub.unsubscribe();
        }

        this.#heartbeatSub = interval(this.intervalMs())
            .pipe(
                tap(() => {
                    const timestamp = new Date();
                    this.logger.debug('💓 Heartbeat sent at', timestamp);
                    this.lastHeartbeat.set(timestamp);
                    this.#lastHeartbeat.next(timestamp);
                }),
            )
            .subscribe();
    }
}
