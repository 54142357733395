<div
    class="d-flex flex-column justify-content-center align-items-center h-100 telxl-loading-background"
>
    <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
            <filter id="goo">
                <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="30"
                    result="blur"
                />
                <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 50 -10"
                    result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
            </filter>
        </defs>
    </svg>
    <div class="gradients-container">
        <div class="g1"></div>
        <div class="g2"></div>
        <div class="g3"></div>
        <div class="g4"></div>
    </div>
    <telxl-shared-loading-panel style="position: absolute">
        <ng-contaner [ngSwitch]="state()">
            <ng-container *ngSwitchCase="'LoggingOut'">
                <ng-container *ngTemplateOutlet="LoggingOut" />
            </ng-container>
            <ng-container *ngSwitchCase="'Authenticating'">
                <ng-container *ngTemplateOutlet="Authenticating" />
            </ng-container>
            <ng-container *ngSwitchCase="'Loading'">
                <ng-container *ngTemplateOutlet="Loading" />
            </ng-container>
            <ng-container *ngSwitchCase="'GoLive'">
                <ng-container *ngTemplateOutlet="GoLive" />
            </ng-container>
        </ng-contaner>
    </telxl-shared-loading-panel>
</div>

<ng-template #Authenticating>
    <div class="mt-3 telxl-loading-text">
        <telxl-user-data-text />
    </div>
</ng-template>

<ng-template #Loading>
    <div class="mt-3 telxl-loading-text">
        <telxl-user-data-text />
    </div>
</ng-template>

<ng-template #LoggingOut>
    <div class="mt-3 telxl-loading-text">
        <p>Signing you out...</p>
    </div>
</ng-template>

<ng-template #GoLive>
    <div class="mt-3 text-center">
        <div class="telxl-loading-text-title">Welcome back</div>
        <div class="telxl-loading-text pb-5">Are you ready to go live?</div>
        <div class="d-flex justify-content-center">
            <telxl-button
                class="pt-5 w-50"
                colour="transparent"
                (buttonClick)="goLive()"
            >
                Go Live
            </telxl-button>
        </div>
    </div>
</ng-template>
