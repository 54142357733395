<div class="d-flex align-items-center h-100">
    <div class="row h-100 w-100">
        @for (
            workitem of orderedWorkitems().slice(0, 6);
            track workitem.workItemId
        ) {
            <div
                class="col-2"
                [@dropInOut]="{
                    value: '',
                    params: {
                        transformStart:
                            workitem.primaryMediaType === 'Voice'
                                ? 'translateX(-25%)'
                                : 'translateY(-20px)',
                    },
                }"
            >
                <app-shell-task-belt-item-panel [workitem]="workitem" />
            </div>
        }
    </div>
    @if (orderedWorkitems().length > 6) {
        <div class="p-1">
            <span class="badge rounded-pill bg-primary">
                +{{ orderedWorkitems().length - 6 }}
            </span>
        </div>
    }
</div>
<ng-container #voice></ng-container>
