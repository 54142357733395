import { IConversation, IConversations } from '../conversation/conversation';
import { ChannelType } from '../types/channel-type';
import { MediaType } from '../types/media-type';
import { WorkItemState } from '../types/workitem-type';

export interface IWorkItems {
    [workItemId: string]: {
        workItemId: string;
        conversations: IConversations;
        businessUnitId: string | null;
        customerId: string | null;
        workItemState: WorkItemState;
        lastStateChangeDate: Date;
        createdAt: Date;
        primaryConversationId: string;
        usableClosureCodes: string[];
        channelId: number;
        primaryMediaType: MediaType;
        primaryChannelType: ChannelType;
        remainingWrapUpResets: number;
        wrapupDurationSeconds: number;
    };
}

export interface IWorkItem {
    workItemId: string;
    conversations: IConversation[];
    businessUnitId: string | null;
    customerId: string | null;
    workItemState: WorkItemState;
    lastStateChangeDate: Date;
    createdAt: Date;
    primaryConversationId: string;
    usableClosureCodes: string[];
    channelId: number;
    primaryMediaType: MediaType;
    primaryChannelType: ChannelType;
    remainingWrapUpResets: number;
    wrapupDurationSeconds: number;
    forceCompletionCodeSelection: boolean;
    queueName: string;
    acceptedAt?: Date;
}

export interface IWorkItemKpis {
    handledCount: number;
    missedCount: number;
    averageHandlingTimeInSeconds: number;
}

export interface IHandledCountKpi {
    handledCount: number;
}
