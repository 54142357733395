<div
    class="col-12 p-0 text-left p-2 mb-2 contact-div"
    [class.active]="isActive"
    (click)="selected()"
    (keydown.enter)="selected()"
    (keydown.space)="selected()"
    tabindex="0"
>
    @if (agent) {}
    @if (agentDetails().has(agent.id)) {
        <div class="d-flex w-100 align-items-center">
            <div class="position-relative px-1 d-none d-xxl-flex">
                <div
                    class="rounded-circle contact-initials"
                    id="header-user-initials"
                >
                    {{ agent.name | initials }}
                </div>
                <span
                    class="position-absolute top-0 p-1 border border-white border-2 rounded-circle contact-badge bg-{{
                        agentPresence().class
                    }}"
                ></span>
            </div>

            <div class="d-flex flex-column ms-3">
                <div>{{ agent.name }}</div>
                <div class="font-sm text-details">
                    @if (agentDetails().get(agent.id)?.phoneNumber) {
                        <span class="me-2">
                            Phone:
                            {{ agentDetails().get(agent.id)?.phoneNumber }}
                        </span>
                    }
                    @if (agentDetails().get(agent.id)?.emailAddress) {
                        <span>
                            Email:
                            {{ agentDetails().get(agent.id)?.emailAddress }}
                        </span>
                    }
                </div>
            </div>
            <div class="flex-grow-1 text-end">
                <button type="button" class="btn p-3">
                    <i
                        class="fa-xl fa-light solid fa-phone"
                        [ngClass]="{ 'icon-sel fa-solid': isActive }"
                    ></i>
                </button>
            </div>
        </div>
    }
</div>
