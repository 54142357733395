import { computed, Injectable, OnDestroy, signal } from '@angular/core';
import {
    IQueue,
    IQueueInformation,
    IQueueReport,
    IQueueWithChannelsReport,
    Queue,
} from '../../models/queue/queue';
import {
    IKpiQueueThreshold,
    ITelXLKpiQueueThreshold,
    TelXLKpiQueueThresholdDefaultState,
} from '../../models/rbac/queue-kpi';
import { CacheHandler, CacheService } from '../../services/cache/cache-service';

import { forkJoin, map, Subscription } from 'rxjs';
import { RbacApiService } from '../../api/rbac/rbac.api.service';
import { NBU_ID } from '../../models/business-unit/business-unit';
import { BusinessUnitService } from '../../services/business-unit/business-unit.service';
import { ChannelProviderService } from '../../services/channel-provider/channel-provider.service';
import { LoggerService } from '../../services/logger/logger.service';
import { AggregationsHubService } from '../../signalr/aggregations/aggregations.hub.service';
import { LiveHubService } from '../../signalr/live/live.hub.service';
import { HeartbeatService } from '../heartbeat/heartbeat.service';

const CACHE_TYPE = 'queue';

@Injectable({
    providedIn: 'root',
})
export class QueueService implements OnDestroy {
    private _queues = signal<Queue[]>([]);
    private _subscriptions = new Subscription();
    private _loading = signal<boolean>(true);

    queues = computed<IQueue[]>(() => {
        this.heartbeatService.lastHeartbeat();

        return this._queues
            .asReadonly()()
            .map(
                q =>
                    new Queue(
                        q.id,
                        q.channelProviders,
                        q.information,
                        q.report,
                        q.kpiTelXLThreshold,
                    ),
            );
    });

    ready = computed<boolean>(
        () =>
            this._loading() === false &&
            this._queues().every(q => q.information),
    );

    constructor(
        private aggregationsHubService: AggregationsHubService,
        private liveHubService: LiveHubService,
        private rbacApiService: RbacApiService,
        private businesUnitService: BusinessUnitService,
        private channelProviderService: ChannelProviderService,
        private loggerService: LoggerService,
        private cacheService: CacheService,
        private heartbeatService: HeartbeatService,
    ) {
        const reportSubscription =
            this.aggregationsHubService.queueReport$.subscribe(
                (queueReport: IQueueWithChannelsReport) => {
                    this.setReport(queueReport);
                },
            );

        const informationSubscription =
            this.liveHubService.queueInformation$.subscribe(
                (queueInformation: IQueueInformation) => {
                    this.setInformation(queueInformation);
                },
            );

        this._subscriptions.add(reportSubscription);
        this._subscriptions.add(informationSubscription);
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    set(queueIds: string[]) {
        const queues = queueIds.map(id => new Queue(id));

        this._queues.set(queues);

        this._queues
            .asReadonly()()
            .forEach(queue => {
                const cacheHandlers: CacheHandler<
                    IQueueInformation | IQueueReport | ITelXLKpiQueueThreshold
                >[] = [
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'information',
                        setter: data =>
                            this.setInformation(
                                data as IQueueInformation,
                                false,
                            ),
                    },
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'report',
                        setter: data =>
                            this.setReport(
                                data as IQueueWithChannelsReport,
                                false,
                            ),
                    },
                    {
                        cacheType: CACHE_TYPE,
                        cacheKey: 'threshold',
                        setter: data =>
                            this.setKpi(
                                queue.id,
                                undefined,
                                data as ITelXLKpiQueueThreshold,
                                false,
                            ),
                    },
                ];

                cacheHandlers.forEach(handler =>
                    this.cacheService.handleCache(
                        handler.cacheType,
                        handler.cacheKey,
                        queue.id,
                        handler.setter,
                    ),
                );
            });

        forkJoin(
            this._queues
                .asReadonly()()
                .map(q =>
                    this.rbacApiService.getKpiThresholds(q.id, 'queues').pipe(
                        map(kpiThresholds => ({
                            queueId: q.id,
                            kpiThresholds,
                        })),
                    ),
                ),
        ).subscribe({
            next: results => {
                results.forEach(({ queueId, kpiThresholds }) => {
                    if (kpiThresholds) {
                        this.setKpi(
                            queueId,
                            kpiThresholds.kpiThreshold as IKpiQueueThreshold,
                            kpiThresholds.kpiTelXLThreshold as ITelXLKpiQueueThreshold,
                        );
                    }
                });
            },
            complete: () => this._loading.set(false),
        });
    }

    setInformation(queueInformation: IQueueInformation, writeToCache = true) {
        queueInformation.businessUnitId =
            queueInformation.businessUnitId ?? NBU_ID;

        const queueUpdate = this._queues
            .asReadonly()()
            .map(q =>
                q.id === queueInformation.id
                    ? new Queue(
                          q.id,
                          this.channelProviderService.getChannelProvidersByWorkflowId(
                              queueInformation.workflowId,
                          ),
                          queueInformation,
                          q.report,
                          q.kpiTelXLThreshold,
                      )
                    : q,
            );

        this._queues.set(queueUpdate);

        const businessUnit = queueUpdate.find(
            q => q.id === queueInformation.id,
        );
        if (businessUnit) {
            this.businesUnitService.updateQueue(businessUnit);
        } else {
            this.loggerService.error(
                `Queue ${queueInformation.name} not found as expected`,
                queueInformation,
            );
        }

        if (writeToCache) {
            this.cacheService.saveToCache(
                `queue-information-${queueInformation.id}`,
                queueInformation,
            );
        }
    }

    setKpi(
        queueId: string,
        kpiThreshold?: IKpiQueueThreshold,
        telXLKpiThreshold?: ITelXLKpiQueueThreshold,
        writeToCache = true,
    ) {
        const threshold =
            telXLKpiThreshold ??
            this.assignKpiThresholdFromLegacy(kpiThreshold);

        const queueUpdate = this._queues
            .asReadonly()()
            .map(q =>
                q.id === queueId
                    ? new Queue(
                          q.id,
                          q.channelProviders,
                          q.information,
                          q.report,
                          threshold,
                      )
                    : q,
            );

        this._queues.set(queueUpdate);

        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueId) as Queue,
        );

        if (writeToCache && threshold) {
            this.cacheService.saveToCache(
                `queue-threshold-${queueId}`,
                threshold,
            );
        }
    }

    getKpi(queueId: string): ITelXLKpiQueueThreshold {
        return (
            this._queues
                .asReadonly()()
                .find(q => q.id === queueId)?.kpiTelXLThreshold ??
            TelXLKpiQueueThresholdDefaultState
        );
    }

    setReport(queueReport: IQueueWithChannelsReport, writeToCache = true) {
        if (queueReport.name === 'Not found') return;

        const queueUpdate = this._queues
            .asReadonly()()
            .map(q =>
                q.id === queueReport.id
                    ? new Queue(
                          q.id,
                          q.channelProviders,
                          q.information,
                          queueReport,
                          q.kpiTelXLThreshold,
                      )
                    : q,
            );

        this._queues.set(queueUpdate);

        this.businesUnitService.updateQueue(
            queueUpdate.find(q => q.id === queueReport.id) as Queue,
        );

        if (writeToCache) {
            this.cacheService.saveToCache(
                `queue-report-${queueReport.id}`,
                queueReport,
            );
        }
    }

    private assignKpiThresholdFromLegacy(
        legacyKpiThreshold?: IKpiQueueThreshold,
    ): ITelXLKpiQueueThreshold | undefined {
        if (!legacyKpiThreshold) return undefined;

        return {
            slaThreshold: {
                low: legacyKpiThreshold.slaThreshold ?? 0,
                high: legacyKpiThreshold.slaThreshold ?? 0,
                enabled:
                    legacyKpiThreshold.slaThreshold !== undefined &&
                    legacyKpiThreshold.slaThreshold !== null,
            },
            maxConversationsWaiting: {
                low: legacyKpiThreshold.maxConversationsWaiting ?? 0,
                high: legacyKpiThreshold.maxConversationsWaiting ?? 0,
                enabled:
                    legacyKpiThreshold.maxConversationsWaiting !== undefined &&
                    legacyKpiThreshold.maxConversationsWaiting !== null,
            },
            averageWaitTimeSeconds: {
                low: legacyKpiThreshold.averageWaitTimeSeconds ?? 0,
                high: legacyKpiThreshold.averageWaitTimeSeconds ?? 0,
                enabled:
                    legacyKpiThreshold.averageWaitTimeSeconds !== undefined &&
                    legacyKpiThreshold.averageWaitTimeSeconds !== null,
            },
            maxLongestWaitTimeSeconds: {
                low: legacyKpiThreshold.maxLwtSeconds ?? 0,
                high: legacyKpiThreshold.maxLwtSeconds ?? 0,
                enabled:
                    legacyKpiThreshold.maxLwtSeconds !== undefined &&
                    legacyKpiThreshold.maxLwtSeconds !== null,
            },
        };
    }
}
