import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    effect,
    ElementRef,
    OnDestroy,
    OnInit,
    Signal,
    ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    IAppReady,
    INotificationModel,
    IVoiceLogin,
    IVoiceServers,
    IVoiceStunServers,
} from '@dxp/shared/models';
import {
    AuthService,
    AgentService,
    BusinessUnitService,
    QueueService,
    SettingsService,
    SipService,
    StateService,
    TeamService,
    TenantService,
    UserService,
    WorkItemService,
} from '@dxp/shared/services';
import { NotificationHubService } from '@dxp/shared/signalr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { HeaderComponent } from './header/header.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NotificationModalComponent } from './notifications/notification-modal/notification-modal.component';
import { NotificationToastComponent } from './notifications/notification-toast/notification-toast.component';
import { SystemService } from './services/system.service';
import { SideNavComponent } from './side-nav/container/side-nav.component';
@Component({
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        MainLayoutComponent,
        NotificationToastComponent,
        SideNavComponent,
    ],
    selector: 'app-shell-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
    private _notificationSubscription!: Subscription;

    asteriskLogin = computed<IVoiceLogin | undefined>(() =>
        this.settingsService.asteriskLogin(),
    );
    asteriskRegister = false;
    asteriskServers = computed<IVoiceServers | undefined>(() =>
        this.settingsService.asteriskServers(),
    );
    asteriskStunServers = computed<IVoiceStunServers | undefined>(() =>
        this.settingsService.asteriskStunServers(),
    );

    user = computed(() => this.userService.user());

    @ViewChild('localAudio') localAudio!: ElementRef<HTMLAudioElement>;
    @ViewChild('remoteAudio') remoteAudio!: ElementRef<HTMLAudioElement>;

    isAuthenticated!: Signal<boolean>;
    notification: Observable<INotificationModel | null>;

    ready = computed<IAppReady>(() => {
        const result = {
            queue: this.queueService.ready(),
            agent: this.agentService.ready(),
            user: this.userService.ready(),
            team: this.teamService.ready(),
            business: this.businessUnitService.ready(),
            tenant: this.tenantService.ready(),
            workitem: this.workItemService.ready(),
            overall:
                this.queueService.ready() &&
                this.agentService.ready() &&
                this.userService.ready() &&
                this.teamService.ready() &&
                this.businessUnitService.ready() &&
                this.workItemService.ready() &&
                this.tenantService.ready(),
        };

        return result;
    });

    constructor(
        private agentService: AgentService,
        private authService: AuthService,
        private modalService: NgbModal,
        private notificationHubService: NotificationHubService,
        private settingsService: SettingsService,
        private sipService: SipService,
        private userService: UserService,
        private tenantService: TenantService,
        private queueService: QueueService,
        private teamService: TeamService,
        private businessUnitService: BusinessUnitService,
        private workItemService: WorkItemService,
        private stateService: StateService,
        private systemService: SystemService,
    ) {
        this.notification = this.notificationHubService.notificationAlert;

        effect(() => {
            if (this.localAudio && this.sipService.localStream()) {
                this.localAudio.nativeElement.srcObject =
                    this.sipService.localStream();
            }

            if (
                this.asteriskServers() &&
                this.asteriskStunServers() &&
                this.asteriskLogin() &&
                !this.asteriskRegister
            ) {
                this.asteriskRegister = true;
                if (this.userService.hasPermission('voice.read')) {
                    this.registerAsterisk();
                }
            }
        });

        this.isAuthenticated = toSignal(
            this.authService.initializeAuthentication().pipe(),
            { initialValue: false },
        );
    }

    ngOnDestroy() {
        if (this._notificationSubscription) {
            this._notificationSubscription.unsubscribe();
        }

        this.userService.subscriptions.unsubscribe();
    }

    ngOnInit() {
        this._notificationSubscription =
            this.notificationHubService.notificationAlert.subscribe(
                notification => {
                    if (notification) {
                        const modalRef = this.modalService.open(
                            NotificationModalComponent,
                            {
                                centered: true,
                                keyboard: false,
                                backdrop: 'static',
                                windowClass: 'modal-wrap',
                            },
                        );

                        modalRef.componentInstance.notification = notification;
                        modalRef.componentInstance.show();
                    } else {
                        this.modalService.dismissAll();
                    }
                },
            );

        this.systemService.initialise();
    }

    registerAsterisk() {
        const servers = (this.asteriskServers()!.data || []).map(item => ({
            uri: `${item.server}.call-view.com`,
        }));

        const stunServers = (this.asteriskStunServers()!.data || []).map(
            item => item.dataValue,
        );

        const login = this.asteriskLogin()!.data?.[0];

        if (login) {
            this.sipService.initializeSipClients(
                servers,
                stunServers,
                `webrtc_${login.username}`,
                login.secret,
            );
        }
    }

    goLive() {
        this.stateService.onActivate();
    }
}
