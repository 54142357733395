import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@dxp/shared/environment';
import { EnvironmentType, INavLink, navbarLinks } from '@dxp/shared/models';
import { ReplaceSpacesPipe } from '@dxp/shared/pipes';
import { UserService } from '@dxp/shared/services';
import { SideNavItemComponent } from './../item/side-nav-item.component';

@Component({
    selector: 'app-shell-side-nav',
    standalone: true,
    templateUrl: './side-nav.component.html',
    styleUrl: './side-nav.component.scss',
    imports: [
        CommonModule,
        RouterModule,
        ReplaceSpacesPipe,
        SideNavItemComponent,
    ],
})
export class SideNavComponent {
    ready = input.required<boolean>();

    presence = computed(() => {
        return this.userService.user().presence;
    });

    constructor(private userService: UserService) {}

    get links(): INavLink[] {
        return navbarLinks;
    }

    get allowResponsive(): boolean {
        return (
            environment.environment === EnvironmentType.Local ||
            environment.environment === EnvironmentType.Dev
        );
    }

    canRender(permission?: string, environments?: EnvironmentType[]): boolean {
        if (
            environments &&
            environments.length > 0 &&
            !environments.includes(environment.environment)
        ) {
            return false;
        }

        return permission ? this.userService.hasPermission(permission) : true;
    }
}
