import { Injectable } from '@angular/core';

import {
    Observable,
    combineLatest,
    filter,
    map,
    tap,
    withLatestFrom
} from 'rxjs';

import { Agent, IAgent } from '../../../../../models';
import { AggregationsHubWrapperService } from '../../wrappers/aggregations-hub-wrapper.service';
import { LiveHubWrapperService } from '../../wrappers/live-hub-wrapper.service';
import { AgentDataService } from './agent-data.service';

@Injectable({
    providedIn: 'root',
})
export class AgentServiceMG {
    constructor(
        private aggregationsHubService: AggregationsHubWrapperService,
        private liveHubService: LiveHubWrapperService,
        private agentDataService: AgentDataService,
    ) {}

    getAgents(memberIds: string[]): Observable<IAgent[]> {
        const agentsWithNameAndMatchingMemberId$ =
            this.liveHubService.agents$.pipe(
                withLatestFrom(memberIds),
                map(([agents, memberIds]) =>
                    agents.filter(
                        agent =>
                            agent.agentName !== '' &&
                            memberIds.includes(agent.agentId),
                    ),
                ),
            );

        const agentsWithMatchingMemberIdAndReport$ =
            this.aggregationsHubService.agentReport$.pipe(
                withLatestFrom(memberIds),
                map(([agentReport, memberIds]) =>
                    memberIds.includes(agentReport.id) ? agentReport : null,
                ),
                filter(agentReport => agentReport !== null),
                filter(agentReport => agentReport?.name !== ''),
            );

        return combineLatest([
            agentsWithNameAndMatchingMemberId$,
            agentsWithMatchingMemberIdAndReport$,
        ]).pipe(
            map(([agents, agentReport]) =>
                agents
                    .filter(agent => agent.agentId === agentReport.id)
                    .map(agent => new Agent(agent.agentId, agentReport, agent)),
            ),

            tap(agents => this.agentDataService.setAgents(agents)),
        );
    }
}
