import { calculateUserPresence } from '../../helpers/presence';
import {
    KeyValuePair,
    aggregateWorkItemsPerMediaType,
    calculateWithPercentage,
    convertToMediaTypeRecord,
} from '../../helpers/team-agents';
import { NBU_ID } from '../../models/business-unit/business-unit';
import { IValueWithMetric } from '../../models/metrics/metric';
import { AgentState, AgentStates, IPresence } from '../presence/presence';
import { MediaType, MediaTypes } from '../types/media-type';
import { IHandledCountKpi, IWorkItemKpis } from '../workitem/workitem';

export interface IAgentState {
    agentId: string;
    agentName: string;
    state: AgentState;
    stateSince: Date;
    breakName: string;
    businessUnitIds: string[];
}

export interface IAgentReport {
    id: string;
    name: string;
    tenantId: string;
    workItemKpisPerMediaType: KeyValuePair<IWorkItemKpis>[];
    workItemKpisPerDispositionCode: KeyValuePair<IHandledCountKpi>[];
    secondsSpentPerState: KeyValuePair<number>[];
    secondsSpentPerBreakName: KeyValuePair<number>[];
    loggedInAt?: Date;
    loggedOutAt?: Date;
}

export interface IAgent {
    id: string;
    name: string;
    emailAddress?: string;
    phoneNumber?: string;
    report?: IAgentReport;
    hasActivity: boolean;
    businessUnits: string[];
    state?: IAgentState;
    status: { state: AgentState; since: Date; breakName: string };
    presence: IPresence;

    statistics: IAgentStatistics;
}

export interface IAgentStatistics {
    timeSpentInAgentStates: Record<
        AgentState,
        { value: number; percentage: number }
    >;
    totalTimeLoggedIn: number;
    timeSpentInBreakNames: Record<
        string,
        { value: number; percentage: number }
    >;
    workItemDispositionCodes: Record<
        string,
        { value: number; percentage: number }
    >;
    workItemsPerMediaType: Record<
        MediaType,
        {
            split: number;
            handledCount: number;
            missedCount: number;
            averageHandlingTimeInSeconds: IValueWithMetric;
            ratio: number;
        }
    >;
    tasks: {
        handled: number;
        missed: number;
        ratio: number;
        averageHandlingTimeInSeconds: IValueWithMetric;
    };
}

export class Agent implements IAgent {
    id: string;
    private _report?: IAgentReport;
    private _state?: IAgentState;

    constructor(id: string, report?: IAgentReport, state?: IAgentState) {
        this.id = id;
        this._report = report;
        this._state = state;
    }

    get name(): string {
        return this._report?.name ?? this._state?.agentName ?? '';
    }

    get status(): { state: AgentState; since: Date; breakName: string } {
        return this._state
            ? {
                  state: this._state.state,
                  since: this._state.stateSince,
                  breakName: this._state.breakName ?? '',
              }
            : {
                  state: 'LoggedOut',
                  since: new Date(),
                  breakName: '',
              };
    }

    get businessUnits(): string[] {
        return [...(this._state?.businessUnitIds ?? []), NBU_ID];
    }

    get hasActivity(): boolean {
        return (
            (this._report !== undefined && this._report.name.length > 0) ||
            (this._state?.state !== 'LoggedOut' &&
                (this.state?.agentName ?? '').length > 0)
        );
    }

    get report(): IAgentReport | undefined {
        return this._report;
    }

    get state(): IAgentState | undefined {
        return this._state;
    }

    get presence(): IPresence {
        return calculateUserPresence(
            this.status.state,
            this.status.since,
            this.status.breakName,
        );
    }

    get statistics(): IAgentStatistics {
        return {
            timeSpentInAgentStates: calculateWithPercentage(
                AgentStates.filter(
                    as => as !== 'LoggedOut',
                ) as readonly string[],
                this._report?.secondsSpentPerState ?? [],
                value => value,
            ),
            totalTimeLoggedIn:
                this._report?.secondsSpentPerState
                    .filter(pair => pair.key !== 'LoggedOut')
                    .reduce((total, pair) => total + pair.value, 0) ?? 0,
            timeSpentInBreakNames: calculateWithPercentage(
                this._report?.secondsSpentPerBreakName.map(kvp => kvp.key) ??
                    [],
                this._report?.secondsSpentPerBreakName ?? [],
                value => value,
            ),
            workItemDispositionCodes: calculateWithPercentage(
                this._report?.workItemKpisPerDispositionCode.map(
                    kvp => kvp.key,
                ) ?? [],
                this._report?.workItemKpisPerDispositionCode ?? [],
                item => item.handledCount,
            ),
            workItemsPerMediaType: convertToMediaTypeRecord(
                this._report?.workItemKpisPerMediaType.filter(w =>
                    MediaTypes.includes(w.key as MediaType),
                ) ?? [],
            ),
            tasks: aggregateWorkItemsPerMediaType(
                this._report?.workItemKpisPerMediaType.filter(w =>
                    MediaTypes.includes(w.key as MediaType),
                ) ?? [],
            ),
        };
    }
}
