<div class="d-flex flex-row">
    @if (workItemId !== 'new') {
        <div class="flex-grow-1">
            <telxl-panel
                cardClass="h-100"
                [minHeight]="height()"
                [maxHeight]="height()"
            >
                <ng-template #cardBody>
                    <div class="d-flex">
                        <div class="ps-2">
                            <voice-call />
                        </div>
                        <div class="flex-grow-1 ps-2">
                            @if (contacts().display) {
                                <contacts-panel
                                    (contactsClose)="contactsClose($event)"
                                    [title]="contacts().title"
                                    mediaType="voice"
                                    [action]="contacts().action"
                                    [workItemId]="workItem()?.workItemId"
                                    [conversationId]="conversationId()"
                                ></contacts-panel>
                            }
                        </div>
                    </div>
                </ng-template>
            </telxl-panel>
        </div>
    } @else {
        <div class="container-fluid">
            <div class="row">
                <div class="col custom-col-37 p-0">
                    <telxl-panel
                        cardClass="h-100"
                        [minHeight]="height()"
                        [maxHeight]="height()"
                    >
                        <ng-template #cardBody>
                            <div class="ps-2">
                                <voice-call-new
                                    [contactSelected]="newCallContact()"
                                />
                            </div>
                        </ng-template>
                    </telxl-panel>
                </div>
                <div class="col pe-1">
                    <telxl-panel
                        cardClass="h-100"
                        [minHeight]="height()"
                        [maxHeight]="height()"
                    >
                        <ng-template #cardBody>
                            <div class="ps-2">
                                <contacts-panel
                                    (contactsClose)="contactsClose($event)"
                                    [title]=""
                                    [showClose]="false"
                                    mediaType="voice"
                                    action="voice-new"
                                    [conversationId]="conversationId()"
                                    [workItemId]="workItem()?.workItemId"
                                    (contactSelected)="
                                        contactSelected($event, true)
                                    "
                                    [newCall]="true"
                                ></contacts-panel>
                            </div>
                        </ng-template>
                    </telxl-panel>
                </div>
            </div>
        </div>
    }
</div>
