import { Component, input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TelXLTabComponent } from '../tab/tab.component';

@Component({
    selector: 'telxl-modal',
    standalone: true,
    imports: [CommonModule, TelXLTabComponent],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
})
export class TelXLModalComponent {
    @ViewChild('modalTemplate', { static: true })
    modalTemplate!: TemplateRef<any>;

    title = input.required<string>();
    constructor(private modalService: NgbModal) {}

    open(size: 'sm' | 'lg' | 'xl' = 'lg') {
        this.modalService.open(this.modalTemplate, {
            backdrop: 'static', // Optional: prevent closing when clicking outside
            size,
        });
    }

    close() {
        this.modalService.dismissAll();
    }
}
