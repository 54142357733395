export interface IContact {
    id: number;
    fullName: string;
    emailAddress?: string;
    phoneNumber?: string;
}

export interface IContactDirectory {
    id: string;
    name: string;
}
