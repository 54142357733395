import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ITab {
    label: string;
    index: string;
}

@Component({
    selector: 'telxl-tab',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './tab.component.html',
    styleUrl: './tab.component.scss',
})
export class TelXLTabComponent {
    @Input({ required: true }) tabs: ITab[] = [];
    @Input({ required: true }) selected!: string;
    @Input() class = '';
    @Input() alignment: 'left' | 'centered' = 'left';

    @Output() selectedChange = new EventEmitter<string>();

    setSelected(index: string) {
        this.selectedChange.emit(index);
    }

    isSelected(selected: string) {
        return this.selected === selected;
    }

    handleKeyPress(event: KeyboardEvent, selected: string) {
        if (event.key === 'Enter' || event.key === ' ') {
            event.stopPropagation();
            event.preventDefault();
            this.setSelected(selected);
        }
    }
}
