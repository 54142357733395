<div class="container-fluid cont-new">
    <div class="row ps-0">
        <div class="col-12 ps-0">
            <div
                class="d-flex flex-row align-items-center justify-content-between w-100 pt-2"
            >
                <div class="text-secondary font-xs">Outbound Call</div>
                <div class="mx-auto font-sm weight-medium">
                    Outbound CLI:&nbsp;{{ opCLI() }}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 ps-0 pt-2">
            <div class="input-group" [ngClass]="{ 'can-call': canCall() }">
                <span class="input-group-text">
                    <button
                        type="button"
                        class="btn btn-dialpad p-1 text-start"
                        (click)="showDialpad()"
                        [ngClass]="{ 'dialpad-clicked': dialpad() }"
                    >
                        <i class="fa-xl fa-light fa-grid-round"></i>
                    </button>
                </span>
                <input
                    type="text"
                    class="form-control form-control-new weight-medium font-xxl p-2 input-group-input"
                    placeholder="Type number"
                    aria-label="Type number"
                    (input)="onNumberChange($event)"
                    value="{{ numberDisplay }}"
                />
                <span class="input-group-text">
                    <button
                        type="button"
                        class="btn rounded-circle p-2"
                        [ngClass]="canCall() ? 'btn-call' : 'btn-call-disabled'"
                    >
                        <i class="fa-lg fa-solid fa-phone"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 ps-0 pt-2">
            <div
                class="d-flex flex-row justify-content-between align-items-center"
            >
                <div>
                    <telxl-dropdown
                        colour="dropdown"
                        class="mw-20"
                        placeholder="Outbound Channel"
                        [outline]="true"
                        [menuItems]="costCentresData"
                        [selected]="costCentreId()"
                        maxHeight="200px"
                        (selectedChange)="onCostCentreChange($event)"
                    />
                </div>
                <telxl-button
                    [colour]="buttonState()"
                    [outline]="true"
                    (buttonClick)="withholdClick()"
                    [ngClass]="{ 'withheld-disabled': overrideCLI() !== null }"
                >
                    <i class="fa-light fa-ban pe-1"></i>
                    Withhold CLI
                </telxl-button>
            </div>
        </div>
    </div>
    @if (dialpad()) {
        <div class="row">
            <div class="col-12">
                <div class="pt-4">
                    <voice-dialpad
                        (buttonClick)="dialpadClick($event)"
                    ></voice-dialpad>
                </div>
            </div>
        </div>
    } @else {
        <div class="row ps-0 pt-4">
            <div class="col-12 ps-0">
                <div class="text-secondary font-xs pb-3">Recent Calls</div>
                @if (recentCalls()) {
                    @for (
                        call of recentCalls()?.historicalWorkitems;
                        track call.id;
                        let i = $index
                    ) {
                        <voice-recent-call
                            [isActive]="isSelected(i)"
                            [call]="call"
                            (callSelected)="callSelected($event, i)"
                        ></voice-recent-call>
                    }
                }
            </div>
        </div>
    }
</div>
