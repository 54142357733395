import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomerApiService, VoiceApiService } from '@dxp/shared/api';
import {
    ICallState,
    IConversation,
    ICustomerDetails,
} from '@dxp/shared/models';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import {
    CacheService,
    HeartbeatService,
    SipService,
    VoiceService,
} from '@dxp/shared/services';
import { Duration, intervalToDuration } from 'date-fns';
import { VoiceCallButtonComponent } from '../voice-call-buttons/voice-call-button.component';
import { VoiceCallInfoComponent } from '../voice-call-info/voice-call-info.component';

@Component({
    selector: 'voice-call',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        VoiceCallButtonComponent,
        FormatDurationPipe,
        VoiceCallInfoComponent,
    ],
    templateUrl: './voice-call.component.html',
    styleUrl: './voice-call.component.scss',
})
export class VoiceCallComponent implements OnInit, OnDestroy {
    private _now = signal<Date>(new Date());
    taskClosing = false;
    initialWrapLength = 0;
    callEnded = false;
    callAccepted: Date | undefined;
    muted = signal(false);
    hold = signal(false);
    transfer = signal(false);
    enquiry = signal(false);
    action = '';

    _conversation = computed<IConversation | undefined>(() => {
        const workItem = this.voiceService.workItem();
        if (!workItem) return undefined;

        return (
            workItem.conversations.find(
                c => c.conversationId === workItem.primaryConversationId,
            ) || undefined
        );
    });

    contacts = computed(() => this.voiceService?.contacts?.() ?? []);

    contactsDisplay = computed(() => {
        if (this.contacts()) {
            return this.contacts().display;
        }
        return false;
    });

    displayContacts = computed(() => {
        return this.voiceService.displayContacts;
    });

    customerName = 'Unknown customer';
    customer = computed<ICustomerDetails | null>(() =>
        this.customerApiService.customer(),
    );

    constructor(
        private sipService: SipService,
        private voiceService: VoiceService,
        private voiceApiService: VoiceApiService,
        private heartbeatService: HeartbeatService,
        private cacheService: CacheService,
        private customerApiService: CustomerApiService,
    ) {
        this._now = this.heartbeatService.lastHeartbeat;
    }

    ngOnInit() {
        this.muted.set(this.cacheService.getFromCache('voice-mute') ?? false);
        this.hold.set(this.cacheService.getFromCache('voice-hold') ?? false);
        this.transfer.set(
            this.cacheService.getFromCache('voice-transfer') ?? false,
        );
        this.enquiry.set(
            this.cacheService.getFromCache('voice-enquiry') ?? false,
        );

        const customer = this.customer();
        if (customer?.name) {
            this.customerName = customer.name;
        }

        /* if (!this.showContacts()) {
            this.transfer.set(false);
            this.enquiry.set(false);
        } */
    }

    get workItem() {
        return this.voiceService.workItem;
    }

    get conversation() {
        return this._conversation;
    }

    get panelHighlighted() {
        return this.voiceService.panelHighlighted();
    }

    ngOnDestroy() {
        this.cacheService.removeCache('voice-mute');
        this.cacheService.removeCache('voice-hold');
        this.cacheService.removeCache('voice-transfer');
        this.cacheService.removeCache('voice-enquiry');
        this.transfer.set(false);
        this.enquiry.set(false);
        this.contacts().display = false;
    }

    endCall(): void {
        const workItem = this.workItem();
        if (workItem) {
            const params: ICallState = {
                conversationId: workItem.primaryConversationId as string,
                callState: 'Hangup',
            };
            this.voiceApiService.changeCallState(params);
        }
        this.sipService.hangUp();
    }

    holdCall() {
        const params: ICallState = {
            conversationId: this.workItem()?.primaryConversationId as string,
            callState: 'Hold',
        };
        this.voiceApiService.changeCallState(params);
        this.cacheService.saveToCache('voice-hold', true);
        this.hold.set(true);
    }

    get callTime(): Duration {
        const workItem = this.workItem();
        if (workItem) {
            return intervalToDuration({
                start: workItem.lastStateChangeDate,
                end: this._now(),
            });
        }
        return { hours: 0, minutes: 0, seconds: 0 };
    }

    openTransfer() {
        if (!this.displayContacts()) {
            return true;
        }
        return false;
    }

    btnClicked(title: string): void {
        switch (title) {
            case 'Mute':
                this.sipService.mute();
                this.cacheService.saveToCache('voice-mute', true);
                this.muted.set(true);
                break;

            case 'Unmute':
                this.sipService.unmute();
                this.cacheService.removeCache('voice-mute');
                this.muted.set(false);
                break;

            case 'Hold': {
                this.holdCall();
                break;
            }

            case 'Resume': {
                const params: ICallState = {
                    conversationId: this.workItem()
                        ?.primaryConversationId as string,
                    callState: 'Resume',
                };
                this.voiceApiService.changeCallState(params);
                this.cacheService.removeCache('voice-hold');
                this.hold.set(false);
                break;
            }

            case 'Transfer':
                if (!this.displayContacts()) {
                    this.voiceService.setContacts(
                        true,
                        'Transfer Call',
                        'voice',
                        'voice-transfer',
                    );
                    this.cacheService.saveToCache('voice-transfer', true);
                    this.transfer.set(true);
                    this.enquiry.set(false);
                } else {
                    this.voiceService.setContacts(false, '', '', '');
                    this.cacheService.removeCache('voice-transfer');
                    this.transfer.set(false);
                }
                break;

            case 'Enquiry':
                if (!this.displayContacts()) {
                    this.voiceService.setContacts(
                        true,
                        'Enquiry Call',
                        'voice',
                        'voice-enquiry',
                    );
                    this.cacheService.saveToCache('voice-enquiry', true);
                    this.enquiry.set(true);
                    this.transfer.set(false);
                } else {
                    this.voiceService.setContacts(false, '', '', '');
                    this.cacheService.removeCache('voice-enquiry');
                    this.enquiry.set(false);
                }

                break;
        }
    }
}
