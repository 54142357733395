import { IScope } from '../../models/agent/agent-scope';
import { IRbacTenant, IRbacUserTenant } from '../../models/rbac/tenant';
import { IRbacUser } from '../../models/rbac/user';
import { IPresence } from '../presence/presence';

export interface IUser extends IRbacUser {
    authenticated: boolean;
    photo?: string;
    tenant?: IRbacTenant;
    tenants: IRbacUserTenant[];
    scopes: IScope[];
    presence: IPresence;
}
