import { ChannelType } from '../types/channel-type';

export interface IConversations {
    [conversationId: string]: IConversation;
}

export interface IConversation {
    conversationId: string;
    channelType: ChannelType;
    queueName: string;
    queuedAt: Date;
    dequeuedAt: Date | null;
    workflowProperties: object;
    fromNumber: string;
    dialedNumber: string;
    callerIdName: string;
    position: number;
    enquiryCallState: string;
}
