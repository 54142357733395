import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { ITenant } from './../../../../../models';

@Injectable({
    providedIn: 'root',
})
export class TenantDataService {
    #tenantSubject = new BehaviorSubject<ITenant>({} as ITenant);

    setTenant(tenant: ITenant) {
        this.#tenantSubject.next(tenant);
    }

    listen() {
        return this.#tenantSubject
            .asObservable()
            .pipe(filter(tenant => !!tenant.id));
    }
}
