import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TelXLAttachmentsService {
    #attachments = new BehaviorSubject<File[]>([]);
    attachments$ = this.#attachments.asObservable();

    clearAttachments() {
        this.#attachments.next([]);
    }

    setAttachments(attachments: File[]) {
        this.#attachments.next(attachments);
    }

    attachments() {
        return this.#attachments.getValue();
    }
}
