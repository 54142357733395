import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InitialsPipe } from '@dxp/shared/pipes';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IContact, IContactSelected } from '@dxp/shared/models';

@Component({
    selector: 'contacts-contact',
    standalone: true,
    imports: [CommonModule, FormsModule, InitialsPipe],
    templateUrl: './contact-contact.component.html',
    styleUrl: './contact-contact.component.scss',
})
export class ContactContactComponent {
    @Output() contactSelected = new EventEmitter<IContactSelected>();
    @Input() contact!: IContact;
    @Input() mediaType!: string;
    @Input() mediaAction!: string;
    @Input() isActive = false;

    selectedContact: IContactSelected = { name: '', identifier: '', type: '' };

    selected() {
        this.isActive = true;
        this.contactSelected.emit({
            ...this.selectedContact,
            name: this.contact.fullName,
            identifier:
                this.mediaType === 'voice'
                    ? this.contact.phoneNumber
                    : this.contact.emailAddress,
            type: 'contact',
        });
    }
}
