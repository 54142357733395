import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments';
import { IContact } from '../../models/contact/contact';
import { IContactAgent } from '../../models/contact/contact-agent-details';
import { IContactDirectory } from '../../models/contact/contact';
import { IOperatorIdResponse } from '../../models/voice/voice-operatorid';

@Injectable({
    providedIn: 'root',
})
export class ContactsApiService {
    constructor(private http: HttpClient) {}

    getContactDirectories(): Observable<IContactDirectory[]> {
        return this.http.get<IContactDirectory[]>(
            `${environment.rbacUrl}/contactdirectories`,
        );
    }

    getContactsByDirectory(directoryId: string): Observable<IContact[]> {
        return this.http.post<IContact[]>(
            `${environment.rbacUrl}/contactdirectories/${directoryId}/contacts/query`,
            {},
        );
    }

    getAgentContactDetails(agentId: string): Observable<IContactAgent> {
        return this.http.get<IContactAgent>(
            `${environment.rbacUrl}/users/${agentId}`,
            {},
        );
    }

    getOperatorId(agentId?: string): Observable<IOperatorIdResponse> {
        return this.http.get<IOperatorIdResponse>(
            `${environment.voiceUrl}/data/operator/id/${agentId}`,
            {},
        );
    }
}
