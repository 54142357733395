import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    Signal,
    signal,
    computed,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ITab } from '@dxp/elements';
import { TelXLTabComponent, TelXLDialpadComponent } from '@dxp/elements';
import { ContactsService } from './contacts.service';
import {
    IAgent,
    IContact,
    IContactAgent,
    IContactSelected,
    IUser,
} from '@dxp/shared/models';
import { ContactContactComponent } from './components/contacts/contact-contact.component';
import { AgentContactComponent } from './components/agents/agent-contact.component';
import { CacheService, AgentService, UserService } from '@dxp/shared/services';
import { SearchPipe } from '@dxp/shared/pipes';
import { ContactActionComponent } from './components/action/contacts-action.component';

@Component({
    selector: 'contacts-panel',
    standalone: true,
    imports: [
        CommonModule,
        TelXLTabComponent,
        ContactContactComponent,
        AgentContactComponent,
        FormsModule,
        SearchPipe,
        ContactActionComponent,
        TelXLDialpadComponent,
    ],
    templateUrl: './contacts.component.html',
    styleUrl: './contacts.component.scss',
})
export class ContactsPanelComponent implements OnInit {
    @Input() title?: string;
    @Input() mediaType!: string;
    @Input() action!: string;
    @Input() conversationId?: string;
    @Input() workItemId?: string;
    @Input() showClose = true;
    @Input() newCall = false;
    @Output() contactsClose = new EventEmitter<boolean>();
    @Output() contactSelected = new EventEmitter<IContactSelected>();

    user!: IUser;
    contacts!: Signal<IContact[]>;
    agents = computed<IAgent[]>(() =>
        this.agentService.agents().filter(agent => agent.id !== this.user.id),
    );

    agentDetails = signal(new Map<string, IContactAgent>());
    selected = 'contacts';
    searchText = '';
    contactAction?: IContactSelected;
    actionVisible = false;
    loading = signal(true);
    selectedContact?: IContactSelected;
    itemSelected = '';

    canCall = computed(() => this.numberDisplay !== '');
    callNumber = signal('');
    dialpad = signal(false);
    contactsDisplay = signal(true);
    contactsDisabled = signal(false);

    tabs: ITab[] = [
        { label: 'Contacts', index: 'contacts' },
        { label: 'Agents', index: 'agents' },
    ];

    constructor(
        private contactsService: ContactsService,
        private cacheService: CacheService,
        private agentService: AgentService,
        private userService: UserService,
    ) {
        this.user = this.userService.user();
    }

    async ngOnInit() {
        if (this.mediaType === 'voice') {
            this.contacts = this.contactsService.getVoiceContacts();
        }
        await this.updateAgentDetails();
    }

    private async updateAgentDetails() {
        const agents = this.agents();
        const updatedDetailsMap = new Map<string, IContactAgent>();

        for (const agent of agents) {
            try {
                const updatedDetails =
                    await this.contactsService.getContactAgentDetails(agent.id);
                const operatorIdResponse =
                    await this.contactsService.getContactAgentOperatorId(
                        agent.id,
                    );
                const operatorId =
                    operatorIdResponse?.isSuccess &&
                    operatorIdResponse.data.length > 0
                        ? operatorIdResponse.data[0].ID
                        : '';
                if (updatedDetails) {
                    updatedDetailsMap.set(agent.id, {
                        ...updatedDetails,
                        operatorId,
                    });
                }
            } catch (error) {
                console.error(
                    `Failed to fetch details for agent ${agent.id}`,
                    error,
                );
            }
        }
        this.agentDetails.set(updatedDetailsMap);
        this.loading.set(false);
    }

    setSelected(panel: string) {
        this.selected = panel;
    }

    closeContacts() {
        this.contactsClose.emit(true);
        this.cacheService.removeCache(this.action);
    }

    get numberDisplay() {
        return this.callNumber();
    }

    dialpadClick(number: string) {
        this.callNumber.set(`${this.callNumber()}${number}`);
    }

    showDialpad() {
        this.dialpad.set(!this.dialpad());
        this.contactsDisplay.set(!this.contactsDisplay());
    }

    onNumberChange(event: Event) {
        const inputValue = (event.target as HTMLInputElement).value;
        this.callNumber.set(inputValue);
    }

    loadActionComponent(
        selected: IContactSelected,
        action: string,
        contactsaction: any,
        id: string,
    ) {
        if (!this.newCall) {
            contactsaction.loadComponent(
                action,
                this.conversationId,
                this.workItemId,
                selected,
            );
            this.actionVisible = true;
            this.contactsDisabled.set(true);
        } else {
            this.contactSelected.emit(selected);
        }
        this.itemSelected = id;
        this.selectedContact = selected;
    }

    loadActionComponentFromNumber(action: string, contactsaction: any) {
        const selected: IContactSelected = {
            name: 'Dialled Number',
            identifier: this.callNumber(),
            type: 'number',
        };
        contactsaction.loadComponent(
            action,
            this.conversationId,
            this.workItemId,
            selected,
        );
        this.actionVisible = true;
        this.contactsDisabled.set(true);
    }

    isSelected(id: string): boolean {
        return this.itemSelected === id;
    }
}
