import { Injectable, OnDestroy, inject } from '@angular/core';
import {
    EventTypes,
    OidcClientNotification,
    OidcSecurityService,
    PublicEventsService,
} from 'angular-auth-oidc-client';
import {
    Observable,
    Subscription,
    catchError,
    delay,
    filter,
    map,
    of,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { IAgentClientState } from '../../models/agent/agent-state';
import { IUser } from '../../models/auth/user';
import { AgentHubService } from '../../signalr/agent/agent.hub.service';
import { UserDataService } from '../hub/mikey/actors/user/user-data.service';
import { LoggerService } from '../logger/logger.service';
import { RetryService } from '../retry/retry.service';
import { SessionService } from '../session/session.service';
import { StateService } from '../state/state.service';
import { UserService } from '../user/user.service';

function getEventTypeName(eventType: EventTypes): string {
    switch (eventType) {
        case EventTypes.CheckingAuth:
            return 'CheckingAuth';
        case EventTypes.CheckingAuthFinished:
            return 'CheckingAuthFinished';
        case EventTypes.CheckingAuthFinishedWithError:
            return 'CheckingAuthFinishedWithError';
        case EventTypes.ConfigLoadingFailed:
            return 'ConfigLoadingFailed';
        case EventTypes.CheckSessionReceived:
            return 'CheckSessionReceived';
        case EventTypes.UserDataChanged:
            return 'UserDataChanged';
        case EventTypes.NewAuthenticationResult:
            return 'NewAuthenticationResult';
        case EventTypes.TokenExpired:
            return 'TokenExpired';
        case EventTypes.IdTokenExpired:
            return 'IdTokenExpired';
        case EventTypes.SilentRenewStarted:
            return 'SilentRenewStarted';
        case EventTypes.SilentRenewFailed:
            return 'SilentRenewFailed';
        default:
            return 'Unknown Event';
    }
}

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private _subscriptions = new Subscription();
    private _lastAccessToken: string | null = null;
    private readonly eventService = inject(PublicEventsService);

    constructor(
        private logger: LoggerService,
        private oidcSecurityService: OidcSecurityService,
        private retryService: RetryService,
        private stateService: StateService,
        private userService: UserService,
        private userDataService: UserDataService,
        private agentHubService: AgentHubService,
        private sessionService: SessionService,
    ) {
        this._subscriptions.add(
            this.eventService
                .registerForEvents()
                .pipe(
                    filter((notification: OidcClientNotification<any>) =>
                        [
                            EventTypes.NewAuthenticationResult,
                            EventTypes.TokenExpired,
                            EventTypes.IdTokenExpired,
                            EventTypes.SilentRenewStarted,
                            EventTypes.SilentRenewFailed,
                        ].includes(notification.type),
                    ),
                    switchMap((notification: OidcClientNotification<any>) =>
                        this.oidcSecurityService.getAccessToken().pipe(
                            take(1),
                            map(currentToken => ({
                                eventType: notification.type,
                                currentToken,
                                eventValue: notification.value,
                            })),
                        ),
                    ),
                )
                .subscribe(({ eventType, currentToken, eventValue }) => {
                    const eventTypeName = getEventTypeName(eventType);

                    this.logger.debug(
                        `Authentication event: ${eventTypeName}`,
                        eventValue,
                    );

                    if (
                        currentToken &&
                        currentToken !== this._lastAccessToken
                    ) {
                        this._lastAccessToken = currentToken;
                        this.stateService.onAccessTokenChange();
                        this.logger.debug(
                            `Access token changed. Token updated at ${new Date().toISOString()}`,
                        );
                    } else {
                        this.logger.debug(
                            `Access token unchanged. No update triggered.`,
                        );
                    }
                }),
        );

        this._subscriptions.add(
            this.agentHubService.agentState$
                .pipe(
                    filter(
                        (agentState: IAgentClientState) =>
                            agentState.agentProperties.state === 'LoggedOut',
                    ),
                    delay(1000),
                )
                .subscribe(() => this.logout()),
        );

        this._subscriptions.add(
            this.sessionService.activityTimeout$.subscribe(() => {
                this.stateService.onLogoff();
            }),
        );
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    private onLogin(userData: any) {
        this.userService.update({
            id: userData.sub,
            name: userData.name,
            emailAddress: userData.email,
            authenticated: true,
        } as IUser);

        this.userDataService.setAuthenticatedUser({
            id: userData.sub,
            name: userData.name,
            email: userData.email,
            isAuthenticated: true,
        });

        this.logger.debug('User information', userData);

        this.stateService.onLogin();
    }

    initializeAuthentication(): Observable<boolean> {
        return this.oidcSecurityService.checkAuth().pipe(
            tap(({ isAuthenticated, userData, accessToken }) => {
                this.logger.info(
                    `Authentication initialization the user is ${
                        isAuthenticated ? '' : 'not '
                    }authenticated.`,
                );

                if (isAuthenticated) {
                    this._lastAccessToken = accessToken;

                    if (environment.inActivityTimeOut > 0) {
                        this.sessionService.startMonitoring(
                            environment.inActivityTimeOut,
                        );
                    }

                    this.onLogin(userData);
                    this.logger.debug('Access token', accessToken);
                } else {
                    this.userService.clear();
                    this.stateService.onLogoff();
                }
            }),
            map(a => a.isAuthenticated),
            this.retryService.logErrorAndDelayRetry(
                3,
                2000,
                'Retrying authentication initialization',
            ),
            catchError(error => {
                this.logger.error(
                    'Error during authentication initialization',
                    error,
                );
                this.userService.clear();
                this.stateService.onLogoff();
                return of(false);
            }),
        );
    }

    login() {
        this.oidcSecurityService.authorize();
    }

    logout() {
        this.userService.logout();
        this.stateService.stopSignalRHubs();

        this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
            this.sessionService.stopMonitoring();
            localStorage.clear();
            this.userService.clear();
        });
    }
}
