import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@dxp/shared/environment';
import { recalculateHeight } from '@dxp/shared/helpers';
import { EnvironmentType, IAppReady, IPresence } from '@dxp/shared/models';
import { MainLayoutBreakComponent } from './main-layout-break/main-layout-break.component';
import { MainLayoutInitialisationComponent } from './main-layout-initialisation/main-layout-initialisation.component';
import { TaskBeltComponent } from './../task-belt/task-belt.component';
import { UserService } from '@dxp/shared/services';

@Component({
    selector: 'app-shell-main-layout',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MainLayoutBreakComponent,
        MainLayoutInitialisationComponent,
        TaskBeltComponent,
    ],
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {
    isAuthenticated = input.required<boolean>();
    ready = input.required<IAppReady>();
    presence = input.required<IPresence>();

    constructor(private userService: UserService) {}

    state = computed<
        | 'Authenticating'
        | 'Loading'
        | 'GoLive'
        | 'Break'
        | 'Active'
        | 'LoggingOut'
    >(() => {
        if (
            this.presence().state === 'LoggedOut' &&
            this.userService.loggingOut()
        ) {
            return 'LoggingOut';
        }

        if (!this.isAuthenticated()) {
            return 'Authenticating';
        }

        if (!this.ready().overall) {
            return 'Loading';
        }

        if (
            this.presence().state === 'Break' &&
            this.presence().label === 'Pre-Live'
        ) {
            return 'GoLive';
        }

        if (this.presence().state === 'Break') {
            return 'Break';
        }

        return 'Active';
    });

    get calculateHeight(): string {
        return recalculateHeight('app', 'standard');
    }

    get calculateFullHeight(): string {
        return recalculateHeight('app', 'expanded');
    }

    get allowStatus(): boolean {
        return environment.environment !== EnvironmentType.Production;
    }
}
