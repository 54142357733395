import { CommonModule } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    IDropdown,
    TelXLButtonComponent,
    TelXLDropdownComponent,
    TelXlProgressbarComponent,
} from '@dxp/elements';
import { AgentApiService } from '@dxp/shared/api';
import { secondsToDuration } from '@dxp/shared/helpers';
import { IMetric, IWorkItem } from '@dxp/shared/models';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import {
    CacheService,
    HeartbeatService,
    SettingsService,
} from '@dxp/shared/services';
import { Duration } from 'date-fns';

@Component({
    selector: 'telxl-shared-wrap',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TelXLButtonComponent,
        FormatDurationPipe,
        TelXLDropdownComponent,
        TelXlProgressbarComponent,
    ],
    templateUrl: './wrap.component.html',
    styleUrl: './wrap.component.scss',
})
export class TelXLWrapComponent {
    private _now = signal<Date>(new Date());

    workitem = input.required<IWorkItem>();

    constructor(
        private agentApiService: AgentApiService,
        private settingsService: SettingsService,
        private cacheService: CacheService,
        private heartbeatService: HeartbeatService,
    ) {
        this._now = this.heartbeatService.lastHeartbeat;
    }

    readonly canExtend = computed<boolean>(
        () => this.workitem().remainingWrapUpResets > 0,
    );

    readonly canComplete = computed<boolean>(
        () =>
            !this.workitem().forceCompletionCodeSelection ||
            this.selectedCode !== '',
    );

    readonly resetDescription = computed<string>(() => {
        if (this.workitem().remainingWrapUpResets > 1) {
            return `${this.workitem().remainingWrapUpResets} remaining resets`;
        } else if (this.workitem().remainingWrapUpResets === 1) {
            return `${this.workitem().remainingWrapUpResets} remaining reset`;
        }

        return '';
    });

    readonly wrapCountdown = computed<{
        duration: Duration;
        metric: IMetric;
    }>(() => {
        const workitem = this.workitem();
        const now = this._now();

        const remainingTime =
            workitem.wrapupDurationSeconds -
            Math.round(
                (now.getTime() -
                    new Date(workitem.lastStateChangeDate).getTime()) /
                    1000,
            );

        const timeout = this.settingsService.wrap()?.timeoutSeconds ?? 30;

        return {
            duration: secondsToDuration(remainingTime),
            metric: {
                percent: Math.min(
                    Math.max(0, (remainingTime / timeout) * 100),
                    100,
                ),
                states: [
                    { value: 33, state: 'warning' },
                    { value: 66, state: 'success' },
                ],
            },
        };
    });

    readonly menuItems = computed<IDropdown<string>[]>(() => {
        return (this.workitem().usableClosureCodes ?? [])
            .map(code => ({
                label: code,
                value: code,
                disabled: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    });

    extend() {
        this.agentApiService.resetWrapup(this.workitem().workItemId);
    }

    complete() {
        this.agentApiService.completeWorkItem({
            notes: this.notes ? this.notes : '',
            workItemId: this.workitem().workItemId,
            closureCode:
                this.selectedCode && this.selectedCode.trim()
                    ? this.selectedCode
                    : '<Default>',
        });
    }

    onCodeChange(code: string | null): void {
        this.cacheService.saveToCache(
            `wrap-${this.workitem().workItemId}-code`,
            code,
        );
    }

    onNotesChange(value: string): void {
        this.cacheService.saveToCache(
            `wrap-${this.workitem().workItemId}-notes`,
            value,
        );
    }

    get notes(): string {
        return (
            this.cacheService.getFromCache(
                `wrap-${this.workitem().workItemId}-notes`,
            ) ?? ''
        );
    }

    get selectedCode(): string {
        return (
            this.cacheService.getFromCache(
                `wrap-${this.workitem().workItemId}-code`,
            ) || ''
        );
    }
}
