@if (conversation) {
    <div class="container-fluid p-0 w-auto">
        <div class="row ps-1">
            <div class="col-2 p-0">
                <div
                    class="rounded-circle call-initials mt-2 ms-2"
                    [ngClass]="{ 'call-initials-notification': !callTime }"
                >
                    {{ customerName | initials }}
                </div>
            </div>
            <div class="col-10">
                <div class="font-xxl">
                    {{ conversation.fromNumber }}
                </div>
                <div class="font-lg">
                    {{ customerName }}
                </div>
                @if (callTime) {
                    <div class="font-lg text-secondary">
                        {{ callTime }}
                    </div>
                }
                <div class="d-flex align-items-start mt-1 mb-3">
                    <div class="flex-grow-1">
                        <div class="d-flex align-items-center">
                            <div class="col-5 font-xs me-1 text-secondary">
                                Number dialled:
                            </div>
                            <div class="col-7 font-sm">
                                {{ conversation.dialedNumber }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-5 font-xs me-1 text-secondary">
                                Queue name:
                            </div>
                            <div class="col-7 font-sm">
                                {{ conversation.queueName }}
                            </div>
                        </div>
                    </div>
                </div>
                @if (showRecording) {
                    <div class="d-flex justify-content-between pt-2">
                        <div class="d-flex flex-row align-items-center">
                            <div>
                                <i class="fa-light fa-lg fa-record-vinyl"></i>
                            </div>
                            <div class="text-secondary ms-3 font-xs">
                                Recording
                            </div>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            <div>
                                <i class="fa-light fa-lg fa-display"></i>
                            </div>
                            <div class="text-secondary ms-3 font-xs">
                                Screen Recording
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
}
