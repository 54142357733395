import { Observable } from 'rxjs';

import { ChannelProviderTypes } from '../../../channel-provider/channel-provider.service';
import { RbacApiService } from './../../../../api';
import { IRbacChannelProviderConfiguration } from './../../../../models';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ProvidersConfiguratationServiceMG {
    constructor(private rbacApiService: RbacApiService) {}

    get getProviderConfigurations(): Observable<
        IRbacChannelProviderConfiguration[]
    >[] {
        return ChannelProviderTypes.map(provider =>
            this.rbacApiService.getChannelProvidersConfiguration(provider),
        );
    }
}
