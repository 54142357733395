import { CommonModule } from '@angular/common';
import { Component, computed, input, Input } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { IMetric, MetricPaletteType } from '@dxp/shared/models';
import { findStateForMetric } from '@dxp/shared/helpers';

/**
 * Component to display progress
 *
 * @selector progressbar
 * @standalone true
 * @component ProgressComponent
 * @imports CommonModule
 *
 * @Input metric - Object conforming to IMetric interface, representing the progress.
 * @Input overall - The summary value for the data.
 * @Input label - Text to display.
 * @Input percentage - Display the % symbol
 *
 * Usage:
 * <telxl-progressbar [metric]="data" [overall]="overall" [label]="SLA" [percentage]="true"></progressbar>
 *
 * Note:
 * A data item contains the percent value of the progress bar and state change values.
 * The state change value is an array of numbers to set the RAG status.
 *
 * e.g. { percent: 80, state: [{25, 'success'}, {75, 'danger'}]}
 * In this scenario a percent value over 25 will render success state and over 75 will render danger state.
 *
 * e.g. { percent: 30, state: [{50, 'warning'}]}
 * In this scenario a percent value over 50 will render warning state. No red state is configured.
 *
 */
@Component({
    selector: 'telxl-progressbar',
    standalone: true,
    imports: [CommonModule, NgbProgressbarModule],
    templateUrl: './progress.component.html',
    styleUrl: './progress.component.scss',
})
export class TelXlProgressbarComponent {
    @Input() defaultState: MetricPaletteType = 'success';
    @Input() emptyState: MetricPaletteType = 'dark';
    @Input() label = '';
    @Input({ required: true }) orientation!:
        | 'horizontal'
        | 'vertical'
        | 'fixed-bottom';
    @Input() percentage = false;
    @Input() emphasis: 'standard' | 'light' | 'heavy' = 'standard';
    @Input() class = '';

    metric = input<IMetric>();
    overall = input<string>('');
    cutout = input<string>('90%');

    state = computed<MetricPaletteType>(() =>
        this.metric()
            ? findStateForMetric(
                  this.metric() as IMetric,
                  this.defaultState,
                  this.emptyState,
              )
            : this.defaultState,
    );

    percent = computed(() => this.metric()?.percent ?? 0);
}
