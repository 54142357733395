import { AgentState } from '../presence/presence';
import { IWorkItems } from '../workitem/workitem';

export type ManualAssignResult = 'Ok' | 'NotFound' | 'NotStaffed';

export enum TaskOrder {
    Voice = 1,
    Webchat = 2,
    Messaging = 3,
    Email = 4,
    Unknown = 5,
}

export interface IAssignToMe {
    result: ManualAssignResult;
}

export interface ICompleteWorkItemParams {
    workItemId: string;
    closureCode: string;
    notes: string;
}

export interface IAgentProperties {
    id: string;
    state: AgentState;
    stateSince: Date;
    breakName: string;
    capabilities: string[];
    displaySettings: {
        hideSensitiveAgentInformation: boolean;
        hideExternalContacts: boolean;
    };
    timedBreakExpiresAt: Date;
}

export interface IAgentClientState {
    agentProperties: IAgentProperties;
    workItems: IWorkItems;
    timeStamp: Date;
}

export interface IAgentStateTransitionRequested {
    toState: string;
    initiatedBy: string;
}
