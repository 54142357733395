import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { TelXLLoadingPanelComponent } from '@dxp/components';
import {
    TelXLButtonComponent,
    TelXLUserDataTextComponent,
} from '@dxp/elements';
import { StateService } from '@dxp/shared/services';

@Component({
    selector: 'app-shell-main-layout-initialisation',
    standalone: true,
    imports: [
        CommonModule,
        TelXLLoadingPanelComponent,
        TelXLButtonComponent,
        TelXLUserDataTextComponent,
    ],
    templateUrl: './main-layout-initialisation.component.html',
    styleUrl: './main-layout-initialisation.component.scss',
})
export class MainLayoutInitialisationComponent {
    state = input.required<
        | 'Authenticating'
        | 'Loading'
        | 'GoLive'
        | 'Break'
        | 'Active'
        | 'LoggingOut'
    >();

    constructor(private stateService: StateService) {}

    goLive() {
        this.stateService.onActivate();
    }
}
