export type WorkItemState =
    | 'LookUp'
    | 'Preview'
    | 'Connected'
    | 'Afterwork'
    | 'Closed';

export type WorkItemResult =
    | 'Abandoned'
    | 'Cancelled'
    | 'Failed'
    | 'Handled'
    | 'Missed'
    | 'Rejected';
