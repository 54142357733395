<div
    class="col-12 text-left p-1 mb-2 contact-div"
    [class.active]="isActive"
    (click)="selected()"
    (keydown.enter)="selected()"
    (keydown.space)="selected()"
    tabindex="0"
>
    <div class="d-flex w-100 align-items-center">
        <div class="rounded-circle contact-initials ms-1">
            {{ contact.fullName | initials }}
        </div>
        <div class="d-flex flex-column ms-3">
            <div>{{ contact.fullName }}</div>
            <div class="font-sm text-details">
                @if (contact.phoneNumber) {
                    <span class="me-2">Phone: {{ contact.phoneNumber }}</span>
                }
                @if (contact.emailAddress) {
                    <span>Email: {{ contact.emailAddress }}</span>
                }
            </div>
        </div>
        <div class="flex-grow-1 text-end">
            <button type="button" class="btn p-3">
                <i
                    class="fa-xl fa-light solid fa-phone"
                    [ngClass]="{ 'icon-sel fa-solid': isActive }"
                ></i>
            </button>
        </div>
    </div>
</div>
