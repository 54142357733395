import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TelXLAttachmentButtonComponent } from '../../../attachment-button/attachment-button.component';
import { TelXLAttachmentsService } from '../attachments.service';

@Component({
    selector: 'telxl-attachment-selector',
    standalone: true,
    imports: [CommonModule, TelXLAttachmentButtonComponent],
    templateUrl: './attachment-selector.component.html',
    styleUrl: './attachment-selector.component.scss',
})
export class TelXLAttachmentSelectorComponent {
    constructor(private attachmentsService: TelXLAttachmentsService) {}

    handleAttachmentClick() {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.addEventListener('change', (event: any) =>
            this.onFileSelected(event),
        );
        fileInput.click();
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files) {
            const validMimeTypes = [
                'application/msword', // .doc
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
                'application/pdf', // .pdf
                'application/vnd.ms-powerpoint', // .ppt
                'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
                'application/vnd.ms-excel', // .xls
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                'text/csv', // .csv
                'text/plain', // .txt
                'application/rtf', // .rtf
                'image/gif', // .gif
                'image/jpeg', // .jpeg & .jpg
                'image/png', // .png
                'image/bmp', // .bmp
                'image/tiff', // .tiff
            ];

            const isValidFileTypes = Array.from(input.files).filter(file =>
                validMimeTypes.includes(file.type),
            ).length;

            if (!isValidFileTypes) {
                alert('File type is not supported');
            }

            const isValidFileSize = Array.from(input.files).filter(
                file => file.size < 10 * 1024 * 1024,
            );

            if (isValidFileSize.length !== input.files.length) {
                alert('File exceeds 10MB size limit.');
            }

            this.attachmentsService.setAttachments([
                ...this.attachmentsService.attachments(),
                ...isValidFileSize,
            ]);
        }
    }
}
