import {
    Component,
    EventEmitter,
    Output,
    input,
    signal,
    WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'voice-call-button',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './voice-call-button.component.html',
    styleUrl: './voice-call-button.component.scss',
})
export class VoiceCallButtonComponent {
    @Output() buttonClicked = new EventEmitter<string>();

    icon = input<string>('');
    iconSelected = input<string>('');
    title = input<string>('');
    titleSelected = input<string>('');
    isClickedInput = input<boolean>(false);
    disabled = input<boolean>(false);

    isClicked: WritableSignal<boolean> = signal(false);

    constructor() {
        this.isClicked = signal(this.isClickedInput());
    }

    onButtonClick(): void {
        this.buttonClicked.emit(
            this.isClicked() ? this.titleSelected() : this.title(),
        );
        this.isClicked.set(!this.isClicked());
    }
}
