import { IHistoricalConversation } from '../conversation/history';
import { MediaType } from '../types/media-type';
import { RoutingModeType } from '../types/routing-mode-type';
import { WorkItemResult } from '../types/workitem-type';

type WorkItemOverrideProps =
    | 'startTimeUtc'
    | 'endTimeUtc'
    | 'conversations'
    | 'agent'
    | 'channel'
    | 'queue';
type ConversationOverrideProps = 'startedAtUtc' | 'endedAtUtc' | 'customer';

export interface IHistoricalWorkItem {
    id: string;
    businessUnitId: string | null;
    isOutbound: boolean;
    mediaType: MediaType | 'Lookup';
    startTimeUtc: Date;
    endTimeUtc: Date;
    durationInSeconds: number;
    customerId: string | null;
    scriptId: string;
    result: WorkItemResult;
    completionCode: string | null;
    notes: string;
    agentId: string;
    agentPhoneNumber: string;
    agent?: IHistoricalAgent;
    queueId: string | null;
    queue?: IHistoricalQueue;
    primaryChannelId: number | null;
    channel?: IHistoricalChannel;
    campaignId: string | null;
    campaignRecordId: string | null;
    primaryConversationId: string | null;
    conversations: IHistoricalConversation[];
    lookupDurationInSeconds: number;
    previewDurationInSeconds: number;
    activeDurationInSeconds: number;
    wrapUpDurationInSeconds: number;
}

export interface IHistoricalAgent {
    id: string;
    name: string;
    deletedAt: Date;
}

export interface IHistoricalQueue {
    id: string;
    name: string;
    deletedAt: Date;
    routingMode: RoutingModeType;
}

export interface IInternalHistoricalWorkItems {
    count: number;
    historicalWorkitems: IInternalHistoricalWorkItem[];
}

export interface IInternalHistoricalWorkItem
    extends Omit<IHistoricalWorkItem, WorkItemOverrideProps> {
    startTimeUtc: Date;
    endTimeUtc: Date;
    conversations: IInternalHistoricalConversation[];
    agent?: Omit<IHistoricalAgent, 'deletedAt'> & { deletedAt: Date };
    channel?: Omit<IHistoricalChannel, 'deletedAt'> & { deletedAt: Date };
    queue?: Omit<IHistoricalQueue, 'deletedAt'> & { deletedAt: Date };
}

export interface IInternalHistoricalConversation
    extends Omit<IHistoricalConversation, ConversationOverrideProps> {
    startedAtUtc: Date;
    endedAtUtc: Date;
    customer?: {
        id: string;
        firstName: string;
        middleName: string;
        lastName: string;
        emailAddress: string | null;
        phoneNumber: string | null;
    };
}

export interface IHistoricalBusinessUnit {
    id: string;
    name: string;
}

export interface IHistoricalWorkItemRelatedEntity {
    id: string;
    name: string;
}

export interface IHistoricalChannel {
    id: number;
    name: string;
    channelType: string;
    deletedAt: Date;
    businessUnitId: string;
}
