import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'telxl-shared-loading-panel',
    templateUrl: './loading-panel.component.html',
    styleUrl: './loading-panel.component.scss',
    standalone: true,
    imports: [CommonModule],
})
export class TelXLLoadingPanelComponent {}
