import { Injectable, signal } from '@angular/core';
import { ViewType } from '../../helpers/style';
import { ChannelGroupType } from '../../models/queue/queue';
import { RbacConversationSortType } from '../../models/rbac/conversation-sorting-mode';
import { IVoiceLogin } from '../../models/voice/voice-login';
import { IVoiceServers } from '../../models/voice/voice-servers';
import { IVoiceStunServers } from '../../models/voice/voice-stun-servers';
import { IWrap } from '../../models/wrap/wrap';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    wrap = signal<IWrap | undefined>(undefined);

    asteriskServers = signal<IVoiceServers | undefined>(undefined);
    asteriskStunServers = signal<IVoiceStunServers | undefined>(undefined);
    asteriskLogin = signal<IVoiceLogin | undefined>(undefined);

    viewType = signal<ViewType>('standard');
    channelGroupFilter = signal<ChannelGroupType | undefined>(undefined);
    conversationSortingMode = signal<RbacConversationSortType>('ByQueueTime');

    setConversationSorting(conversationSortingMode: RbacConversationSortType) {
        this.conversationSortingMode.set(conversationSortingMode);
    }

    setWrapDetails(wrap: IWrap) {
        this.wrap.set(wrap);
    }

    setAsteriskServers(servers: IVoiceServers) {
        this.asteriskServers.set(servers);
    }

    setAsteriskStunServers(servers: IVoiceStunServers) {
        this.asteriskStunServers.set(servers);
    }

    setAsteriskLogin(login: IVoiceLogin) {
        this.asteriskLogin.set(login);
    }
}
