<div class="position-relative w-100 h-100">
    <!-- Overlay adjusted to stop 50px above the bottom -->
    <div
        class="overlay position-absolute top-0 start-0 w-100"
        [ngClass]="{
            'd-none': !isOverlayVisible(),
            'slide-in': isOverlayVisible(),
            'slide-out': !isOverlayVisible(),
        }"
        [ngStyle]="{
            'background-color': overlayBackground(),
            'z-index': 1050,
            bottom: '146px',
        }"
    >
        <!-- Internal flex container remains the same -->
        <div class="d-flex flex-column h-100">
            <div
                class="flex-grow-1"
                [ngStyle]="{ 'background-color': opacity() }"
            ></div>
            <div class="bg-white p-3">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
