<div class="container cont-new">
    @if (!loading()) {
        @if (showClose) {
            <div class="row ps-3">
                <div class="col-12 ps-0">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="text-secondary font-xs">
                            {{ title }}
                        </div>
                        <div>
                            <button
                                type="button"
                                aria-label="Close"
                                class="btn-close text-reset btn-tiny"
                                (click)="closeContacts()"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        }
        <div class="row action-row p-0" [class.active]="actionVisible">
            <div class="col-12">
                <contacts-action
                    (closeContacts)="closeContacts()"
                    #contactsaction
                ></contacts-action>
            </div>
        </div>
        <div [ngClass]="{ 'div-disabled': contactsDisabled() }">
            @if (!newCall) {
                <div class="row">
                    <div class="col-12 pe-5 pt-3">
                        <div
                            class="input-group ms-3 me-3"
                            [ngClass]="{ 'can-call': canCall() }"
                        >
                            <span class="input-group-text">
                                <button
                                    type="button"
                                    class="btn btn-dialpad p-1 text-start"
                                    (click)="showDialpad()"
                                    [ngClass]="{ 'dialpad-clicked': dialpad() }"
                                >
                                    <i class="fa-xl fa-light fa-grid-round"></i>
                                </button>
                            </span>
                            <input
                                type="text"
                                class="form-control form-control-new weight-medium font-xxl p-2 input-group-input"
                                placeholder="Type number"
                                aria-label="Type number"
                                (input)="onNumberChange($event)"
                                value="{{ numberDisplay }}"
                            />
                            <span class="input-group-text">
                                <button
                                    type="button"
                                    class="btn rounded-circle p-2"
                                    [ngClass]="
                                        canCall()
                                            ? 'btn-call'
                                            : 'btn-call-disabled'
                                    "
                                    (click)="
                                        loadActionComponentFromNumber(
                                            action,
                                            contactsaction
                                        )
                                    "
                                >
                                    <i class="fa-lg fa-solid fa-phone"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            }
            @if (contactsDisplay()) {
                <div>
                    <div class="row justify-content-center mx-0 pt-2">
                        <div class="col-12 p-0 my-1">
                            <telxl-tab
                                class="weight-normal font-sm mb-3"
                                [tabs]="tabs"
                                [selected]="selected"
                                (selectedChange)="setSelected($event)"
                            />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-12">
                            <div class="d-flex w-50">
                                <div class="input-group input-group-sm mb-2">
                                    <input
                                        type="text"
                                        class="form-control font-sm"
                                        placeholder="Search..."
                                        [(ngModel)]="searchText"
                                        aria-label="Search"
                                        aria-describedby="teams-agents-search"
                                    />
                                    <span
                                        class="input-group-text"
                                        id="teams-agents-search"
                                    >
                                        <i
                                            class="fa-solid fa-magnifying-glass"
                                        ></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    @if (selected === 'contacts') {
                        @if (contacts().length > 0) {
                            @for (
                                contact of contacts()
                                    | filter: searchText : 'fullName';
                                track contact.id;
                                let i = $index
                            ) {
                                <contacts-contact
                                    [contact]="contact"
                                    [isActive]="isSelected('c' + i)"
                                    [mediaType]="mediaType"
                                    [mediaAction]="action"
                                    (contactSelected)="
                                        loadActionComponent(
                                            $event,
                                            action,
                                            contactsaction,
                                            'c' + i
                                        )
                                    "
                                ></contacts-contact>
                            }
                        }
                    }
                    @if (selected === 'agents') {
                        @if (agents()) {
                            @for (
                                agent of agents() | filter: searchText : 'name';
                                track agent.id;
                                let i = $index
                            ) {
                                <contacts-agent
                                    [agent]="agent"
                                    [isActive]="isSelected('a' + i)"
                                    [agentDetails]="agentDetails"
                                    [mediaType]="mediaType"
                                    [mediaAction]="action"
                                    (contactSelected)="
                                        loadActionComponent(
                                            $event,
                                            action,
                                            contactsaction,
                                            'a' + i
                                        )
                                    "
                                ></contacts-agent>
                            }
                        }
                    }
                </div>
            }

            @if (dialpad()) {
                <div class="row">
                    <div class="col-12">
                        <div class="pt-3 pe-5 text-end">
                            <button
                                type="button"
                                aria-label="Close"
                                class="btn-close text-reset btn-tiny"
                                (click)="showDialpad()"
                            ></button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="pt-5">
                            <telxl-dialpad
                                (buttonClick)="dialpadClick($event)"
                            ></telxl-dialpad>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>
