import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'telxl-user-data-text',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './user-data-text.component.html',
    styleUrl: './user-data-text.component.scss',
})
export class TelXLUserDataTextComponent {}
