<nav class="navbar navbar-expand-lg ps-1 pe-1 pt-1 pb-1">
    <div class="container-fluid">
        <div
            class="d-flex align-items-center justify-content-between w-100 pt-2"
        >
            <div class="me-2 pe-4" id="header-current-date">
                {{ now() | date: 'HH:mm | EEEE dd/MM/YYYY' }}
            </div>

            <div
                class="d-flex align-items-center"
                [style.visibility]="ready() ? 'visible' : 'hidden'"
            >
                <!--
                <div
                    tabindex="0"
                    class="w-60 me-2 text-center"
                    id="header-notification"
                    (click)="toggleNotificationPanel()"
                    (keypress)="toggleNotificationPanel()"
                >
                    <i class="fa-light fa-lg fa-bell position-relative">
                        @if (unread() > 0) {
                            <span
                                class="position-absolute top-0 start-100 badge rounded-pill bg-danger"
                            >
                                {{ unread() }}
                                <span class="visually-hidden">
                                    unread messages
                                </span>
                            </span>
                        }
                    </i>
                </div>
                -->
                <div
                    ngbDropdown
                    class="d-inline-block pe-1"
                    [ngClass]="{
                        'header-user-status-menu-top': isUserDropdownOpen,
                    }"
                    (openChange)="toggleDropdownOpenState($event)"
                    [autoClose]="'outside'"
                    id="header-user-control"
                >
                    <div
                        class="avatar d-flex align-items-center justify-content-{{
                            isUserDropdownOpen ? 'between' : 'end'
                        }}"
                    >
                        @if (isUserDropdownOpen) {
                            <div
                                class="ms-3 form-check form-switch"
                                id="header-user-presence-switch"
                            >
                                <input
                                    type="checkbox"
                                    role="switch"
                                    class="form-check-input"
                                    [ngClass]="[borderClass, backgroundClass]"
                                    [checked]="isOnline()"
                                    (click)="changePresence($event)"
                                    (mousedown)="stopDropdownClose($event)"
                                />
                            </div>
                        }
                        <div
                            class="position-relative px-1 header-dropdown"
                            ngbDropdownToggle
                            role="button"
                            id="header-user-menu-toggle"
                        >
                            <div
                                class="rounded-circle user-initials"
                                id="header-user-initials"
                            >
                                @if (user().photo) {
                                    <img
                                        [src]="user().photo"
                                        class="rounded-circle"
                                        alt="{{ user.name | initials }}"
                                        id="header-user-avatar"
                                        style="
                                            width: 60px;
                                            height: 60px;
                                            object-fit: cover;
                                        "
                                    />
                                } @else {
                                    {{ user().name | initials }}
                                }
                            </div>

                            <span
                                class="position-absolute top-0 start-100 translate-middle p-2 border border-white border-2 rounded-circle status-badge bg-{{
                                    user().presence.class
                                }}"
                            ></span>
                        </div>
                    </div>

                    <div
                        ngbDropdownMenu
                        class="dropdown-menu-end"
                        id="header-user-status-menu"
                    >
                        <div
                            class="d-flex flex-column align-items-start ms-3 me-3"
                        >
                            <span
                                class="font-lg weight-medium mb-2"
                                id="header-user-name"
                            >
                                {{ user().name }}
                            </span>

                            <div class="mb-2">
                                <span class="font-sm me-2">Status:</span>
                                <span
                                    class="badge font-sm weight-normal border border-{{
                                        user().presence.class
                                    }} text-bg-{{ user().presence.class }}"
                                    id="header-user-presence"
                                >
                                    {{ user().presence.label }}
                                </span>
                            </div>
                            <span
                                class="font-lg weight-medium mb-2"
                                id="header-user-time"
                            >
                                {{ stateSince | formatDuration }}
                                @if (user().presence.timedBreakExpiresAt) {
                                    remaining
                                }
                            </span>

                            @if (user().presence.online) {
                                <telxl-dropdown
                                    colour="dropdown"
                                    class="w-100"
                                    placeholder="Select break type"
                                    [outline]="true"
                                    [menuItems]="menuItems"
                                    [selected]="selectedBreak"
                                    maxHeight="200px"
                                    (selectedChange)="onBreakNameChange($event)"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
