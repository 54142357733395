<div class="container-fluid">
    <div class="row ps-0">
        <div class="col-12 pt-1 pb-1 ps-0 text-secondary font-xs">
            Incoming Call
        </div>
    </div>
    <div
        class="row call-details ps-0 pt-1 pb-3 mb-2"
        [ngClass]="{ highlighted: panelHighlighted === 'main' }"
    >
        <div class="col-10 pt-1">
            @if (conversation()) {
                <voice-call-info
                    [conversation]="conversation()"
                    [customerName]="customerName"
                    [callTime]="callTime | formatDuration"
                    [showRecording]="true"
                ></voice-call-info>
            }
        </div>
        <div class="col-2 text-center">
            <div
                class="d-flex flex-column align-items-center h-100 justify-content-between me-3"
            >
                <div class="mt-3">
                    <button
                        type="button"
                        class="btn btn-reject rounded-circle p-2"
                        (click)="endCall()"
                    >
                        <i class="fa-xl fa-solid fa-phone-hangup"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0">
        <voice-call-button
            class="p-0"
            icon="fa-microphone-slash"
            iconSelected="fa-microphone"
            title="Mute"
            titleSelected="Unmute"
            [isClickedInput]="muted()"
            (buttonClicked)="btnClicked($event)"
            [disabled]="false"
        ></voice-call-button>
    </div>
    <div class="row p-0">
        <voice-call-button
            class="p-0"
            icon="fa-circle-pause"
            iconSelected="fa-play"
            title="Hold"
            titleSelected="Resume"
            [isClickedInput]="hold()"
            (buttonClicked)="btnClicked($event)"
            [disabled]="false"
        ></voice-call-button>
    </div>
    <div class="row p-0">
        <voice-call-button
            class="p-0"
            icon="fa-magnifying-glass-arrow-right"
            iconSelected="fa-magnifying-glass-arrow-right"
            title="Enquiry"
            titleSelected="Enquiry"
            [isClickedInput]="enquiry() && displayContacts()"
            (buttonClicked)="btnClicked($event)"
            [disabled]="false"
        ></voice-call-button>
    </div>
    <div class="row p-0">
        <voice-call-button
            class="p-0"
            icon="fa-right-left"
            iconSelected="fa-right-left"
            title="Transfer"
            titleSelected="Transfer"
            [isClickedInput]="transfer() && displayContacts()"
            (buttonClicked)="btnClicked($event)"
            [disabled]="false"
        ></voice-call-button>
    </div>
</div>
