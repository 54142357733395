import { ChannelType } from '../../types/channel-type';
import { WorkItemResult } from '../../types/workitem-type';
import { IHistoricalAgent, IHistoricalQueue } from '../../workitem/history';
import {
    ConversationEndedReason,
    DequeuedReason,
    EnqueueAttemptRejectReason,
} from '../history';

export interface IHistoricActivities {
    count: number;
    historicActivity: IHistoricActivity[];
}

export interface IHistoricActivity {
    id: string;
    workItemId: string;
    channelType: ChannelType;
    agent?: IHistoricalAgent;
    queue?: IHistoricalQueue;
    date: Date;
    duration: number;
    tag: string;
    channelId: number;
    channelName: string;
    notes: string;
    result:
        | WorkItemResult
        | DequeuedReason
        | EnqueueAttemptRejectReason
        | ConversationEndedReason;
    isOutbound: boolean;
}
