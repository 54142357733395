@switch (orientation()) {
    @case ('Stacked') {
        <div class="row mb-4">
            <div class="col-xl-10 col-xxl-10 col-lg-12 font-lg">
                <telxl-progressbar
                    label="Available"
                    orientation="horizontal"
                    [overall]="utilisation().Idle.percentage.toString()"
                    [metric]="{
                        percent: utilisation().Idle.percentage,
                        states: [{ value: 0, state: 'success' }],
                    }"
                    defaultState="success"
                    [percentage]="true"
                    emphasis="light"
                />
            </div>
            <div
                class="col-2 d-none d-xl-flex font-sm d-flex align-items-center justify-content-end"
            >
                ({{
                    secondsToDuration(utilisation().Idle.value)
                        | formatDuration: '[dd]:hh:mm:ss'
                }})
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-xl-10 col-xxl-10 col-lg-12">
                <telxl-progressbar
                    label="Busy"
                    orientation="horizontal"
                    [overall]="utilisation().Busy.percentage.toString()"
                    [metric]="{
                        percent: utilisation().Busy.percentage,
                        states: [{ value: 0, state: 'danger' }],
                    }"
                    defaultState="danger"
                    [percentage]="true"
                    emphasis="light"
                />
            </div>
            <div
                class="col-2 d-none d-xl-flex font-sm d-flex align-items-center justify-content-end"
            >
                ({{
                    secondsToDuration(utilisation().Busy.value)
                        | formatDuration: '[dd]:hh:mm:ss'
                }})
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-xl-10 col-xxl-10 col-lg-12">
                <telxl-progressbar
                    label="On Break"
                    orientation="horizontal"
                    [overall]="utilisation().Break.percentage.toString()"
                    [metric]="{
                        percent: utilisation().Break.percentage,
                        states: [{ value: 0, state: 'warning' }],
                    }"
                    defaultState="warning"
                    [percentage]="true"
                    emphasis="light"
                />
            </div>
            <div
                class="col-2 d-none d-xl-flex font-sm d-flex align-items-center justify-content-end"
            >
                ({{
                    secondsToDuration(utilisation().Break.value)
                        | formatDuration: '[dd]:hh:mm:ss'
                }})
            </div>
        </div>
    }
    @case ('Inline') {
        <div class="row mb-4">
            <div
                class="col-xl-2 xol-xxl-2 col-lg-3 align-content-end text-end nowrap"
            >
                Available
            </div>
            <div class="col-6 align-content-end pb-1">
                <telxl-progressbar
                    label=""
                    orientation="horizontal"
                    overall=""
                    [metric]="{
                        percent: utilisation().Idle.percentage,
                        states: [{ value: 0, state: 'success' }],
                    }"
                    defaultState="success"
                    emphasis="light"
                />
            </div>
            <div
                class="col-xl-2 xol-xxl-2 col-lg-3 align-content-end font-lg weight-medium"
            >
                {{ utilisation().Idle.percentage }}%
            </div>
            <div
                class="col-2 d-none d-xl-flex font-sm d-flex align-items-center justify-content-center"
            >
                ({{
                    secondsToDuration(utilisation().Idle.value)
                        | formatDuration: '[dd]:hh:mm:ss'
                }})
            </div>
        </div>
        <div class="row mb-4">
            <div
                class="col-xl-2 xol-xxl-2 col-lg-3 align-content-end text-end nowrap"
            >
                Busy
            </div>
            <div class="col-6 align-content-end pb-1">
                <telxl-progressbar
                    label=""
                    orientation="horizontal"
                    overall=""
                    [metric]="{
                        percent: utilisation().Busy.percentage,
                        states: [{ value: 0, state: 'danger' }],
                    }"
                    defaultState="danger"
                    emphasis="light"
                />
            </div>
            <div
                class="col-xl-2 xol-xxl-2 col-lg-3 align-content-end font-lg weight-medium"
            >
                {{ utilisation().Busy.percentage }}%
            </div>
            <div
                class="col-2 d-none d-xl-flex font-sm d-flex align-items-center justify-content-center"
            >
                ({{
                    secondsToDuration(utilisation().Busy.value)
                        | formatDuration: '[dd]:hh:mm:ss'
                }})
            </div>
        </div>
        <div class="row mb-4">
            <div
                class="col-xl-2 xol-xxl-2 col-lg-3 align-content-end text-end nowrap"
            >
                Break
            </div>
            <div class="col-6 align-content-end pb-1">
                <telxl-progressbar
                    label=""
                    orientation="horizontal"
                    overall=""
                    [metric]="{
                        percent: utilisation().Break.percentage,
                        states: [{ value: 0, state: 'warning' }],
                    }"
                    defaultState="warning"
                    emphasis="light"
                />
            </div>
            <div
                class="col-xl-2 xol-xxl-2 col-lg-3 align-content-end font-lg weight-medium"
            >
                {{ utilisation().Break.percentage }}%
            </div>
            <div
                class="col-2 d-none d-xl-flex font-sm d-flex align-items-center justify-content-center"
            >
                ({{
                    secondsToDuration(utilisation().Break.value)
                        | formatDuration: '[dd]:hh:mm:ss'
                }})
            </div>
        </div>
    }
}
