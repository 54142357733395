import { Injectable } from '@angular/core';
import { delay, of, OperatorFunction, retry } from 'rxjs';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class RetryService {
    constructor(private logger: LoggerService) {}

    logErrorAndDelayRetry<T>(
        retryTimes = 3,
        delayMileseconds = 2000,
        loggerMessage = 'Error received, retrying',
    ): OperatorFunction<T, T> {
        return retry({
            count: retryTimes,
            delay: (error, retryCount) => {
                this.logger.error(loggerMessage, error);

                const incrementalDelay = delayMileseconds * retryCount;
                this.logger.info(
                    `Retry #${retryCount} after ${incrementalDelay}ms`,
                );

                return of(null).pipe(delay(incrementalDelay));
            },
        });
    }
}
