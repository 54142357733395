/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
    standalone: true,
})
export class SortPipe implements PipeTransform {
    transform(
        value: any[],
        property: string,
        order: 'asc' | 'desc' = 'asc',
        thenBy?: string,
    ): any[] {
        if (!value || !property) {
            return value;
        }

        return value.sort((a, b) => {
            const aPrimaryValue = this.getNestedProperty(a, property);
            const bPrimaryValue = this.getNestedProperty(b, property);

            const comparison = this.compareValues(
                aPrimaryValue,
                bPrimaryValue,
                order,
            );

            if (comparison === 0 && thenBy) {
                const aSecondaryValue = this.getNestedProperty(a, thenBy);
                const bSecondaryValue = this.getNestedProperty(b, thenBy);

                return this.compareValues(
                    aSecondaryValue,
                    bSecondaryValue,
                    order,
                );
            }

            return comparison;
        });
    }

    private getNestedProperty(obj: any, path: string): any {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    private compareValues(
        aValue: any,
        bValue: any,
        order: 'asc' | 'desc',
    ): number {
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            // No need to transform numbers
        } else if (aValue instanceof Date && bValue instanceof Date) {
            aValue = aValue.getTime();
            bValue = bValue.getTime();
        } else {
            // If strings are dates, convert them
            aValue = new Date(aValue).getTime();
            bValue = new Date(bValue).getTime();
        }

        let comparison = 0;
        if (aValue < bValue) {
            comparison = -1;
        } else if (aValue > bValue) {
            comparison = 1;
        }

        return order === 'asc' ? comparison : -comparison;
    }
}
