import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';
import { ColourPaletteType, SizeType } from '@dxp/shared/models';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'telxl-button',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './button.component.html',
    styleUrls: [
        './button.component.primary.scss',
        './button.component.secondary.scss',
        './button.component.success.scss',
        './button.component.warning.scss',
        './button.component.info.scss',
        './button.component.light.scss',
        './button.component.danger.scss',
        './button.component.dark.scss',
        './button.component.dropdown.scss',
        './button.component.transparent.scss',
    ],
    host: {
        '[class]': 'class || ""',
    },
})
export class TelXLButtonComponent {
    @Input() active = false;
    @Input() aria: string | null = null;
    @Input() className: string | null = null;
    @Input({ required: true }) colour!: ColourPaletteType;
    @Input() disabled = false;
    @Input() outline = false;
    @Input() routerLink: string | null = null;
    @Input() size: SizeType | null = null;
    @Input() type: 'button' | 'submit' | 'reset' = 'button';
    @Output() buttonClick = new EventEmitter<Event>();

    get buttonClass(): string {
        let btnClass = 'w-100 h-100 btn btn-';

        if (this.outline) btnClass += 'outline-';
        btnClass += this.colour;

        if (this.size) {
            btnClass += ` btn-${this.size}`;
        }

        return btnClass;
    }

    @HostListener('click', ['$event'])
    onClick(event: Event): void {
        if (this.disabled) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        this.buttonClick.emit(event);
    }
}
