interface PatchAdd {
    op: 'add';
    path: string;
    value: unknown;
}

interface PatchRemove {
    op: 'remove';
    path: string;
}

interface PatchReplace {
    op: 'replace';
    path: string;
    value: unknown;
}

interface PatchMove {
    op: 'move';
    path: string;
    from: string;
}

interface PatchCopy {
    op: 'copy';
    path: string;
    from: string;
}

interface PatchTest {
    op: 'test';
    path: string;
    value: unknown;
}

export type JsonPatchData = (
    | PatchAdd
    | PatchRemove
    | PatchReplace
    | PatchMove
    | PatchCopy
    | PatchTest
)[];
