export type IMessageHistory = {
    chatServiceId?: number;
    customerId?: string;
    from: string;
    messageBody: string;
    attachments?: IMessageAttachment[];
    createdAt: string;
    channelId?: string;
    conversationId?: string;
    suggestedActions?: ISuggestedActions;
    attachmentLayout?: string;
    summary?: string;
    additionalData?: { [key: string]: string };
    messageFrom?: string;
    messageTo?: string;
    speak?: string;
    inputHint?: string;
    reactionsAdded?: Array<IMessageReaction>;
    reactionsRemoved?: Array<IMessageReaction>;
    stringValue?: string;
    channelData?: string;
    id: string;
    creationDate: Date;
    sessionId?: string;
    tenantId?: string;
};

interface IMessageReaction {
    type?: string;
}

interface ISuggestedActions {
    to?: Array<string>;
    actions?: Array<ICardAction>;
}

interface ICardAction {
    type?: string;
    title?: string;
    image?: string;
    text?: string;
    displayText?: string;
    value?: unknown;
}

export type IMessageAttachment = {
    contentType: string;
    contentUrl: string;
    content: Record<string, unknown>;
    name: string;
    thumbnailUrl: string;
};

export type SenderType = 'Customer' | 'Agent' | 'Supervisor' | 'Bot';
