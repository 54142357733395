import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { IAxisUser } from '../user/user-data.service';
import { RbacApiService } from './../../../../../api';
import { ITenant, Tenant } from './../../../../../models';
import { TenantDataService } from './tenant-data.service';

@Injectable({
    providedIn: 'root',
})
export class TenantServiceMG {
    constructor(
        private rbacApiService: RbacApiService,
        private tenantDataService: TenantDataService,
    ) {}

    getTenant(axisUser: IAxisUser): Observable<ITenant> {
        return this.rbacApiService.getTenant(axisUser.tenantId).pipe(
            map(tenant => new Tenant(tenant.id, tenant.name, [])),

            tap(tenant => this.tenantDataService.setTenant(tenant)),
            catchError(() => of({} as ITenant)),
        );
    }
}
