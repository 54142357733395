import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
} from '@angular/core';
import { InitialsPipe } from '@dxp/shared/pipes';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    IContactSelected,
    IInternalHistoricalWorkItem,
} from '@dxp/shared/models';
import { Duration } from 'date-fns';
import { secondsToDuration } from '@dxp/shared/helpers';
import { FormatDurationPipe } from '@dxp/shared/pipes';

@Component({
    selector: 'voice-recent-call',
    standalone: true,
    imports: [CommonModule, FormsModule, InitialsPipe, FormatDurationPipe],
    templateUrl: './voice-recent-call.component.html',
    styleUrl: './voice-recent-call.component.scss',
})
export class VoiceRecentCallComponent implements OnInit {
    @Output() callSelected = new EventEmitter<IContactSelected>();
    @Input() call!: IInternalHistoricalWorkItem;
    @Input() isActive = false;

    selectedContact: IContactSelected = { name: '', identifier: '', type: '' };
    customerName = signal<string>('Unknown customer');
    incoming = signal<boolean>(false);
    //callTime = signal<string>('');

    ngOnInit() {
        if (this.call.conversations) {
            this.customerName.set(
                this.call.conversations[0].customerFirstName +
                    ' ' +
                    this.call.conversations[0].customerLastName,
            );
            this.incoming.set(!this.call.conversations[0].isOutbound);
            //this.callTime.set(this.call.startTimeUtc)
        }
    }

    selected() {
        this.isActive = true;
        this.callSelected.emit({
            ...this.selectedContact,
            name: this.customerName(),
            identifier: this.call.agentPhoneNumber,
            type: 'contact',
        });
    }

    get callSeconds(): Duration {
        return secondsToDuration(this.call.durationInSeconds);
    }
}
