<div
    class="card"
    [style.min-height]="minHeight ? minHeight : null"
    [style.max-height]="maxHeight ? maxHeight : null"
    [ngClass]="cardClassName"
>
    <div
        class="card-header"
        *ngIf="headerTemplate"
        [ngClass]="{ headerClass, 'no-body': !isExpanded }"
    >
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>

    <div
        class="card-body"
        *ngIf="bodyTemplate && isExpanded"
        [ngClass]="bodyClass"
    >
        <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>

    <div class="card-footer" *ngIf="footerTemplate" [ngClass]="footerClass">
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
</div>
