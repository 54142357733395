<div class="search-box">
    <div class="input-group mb-3 mt-3">
        <input
            #searchInput
            type="text"
            class="form-control"
            (keyup)="onSearch($event)"
            placeholder="Search..."
        />
        <span class="input-group-text">
            <i class="fa fa-search"></i>
        </span>
    </div>
</div>

@if (filteredItems().length === 0) {
    <div class="text-center py-4">
        <i class="fa-light fa-face-monocle"></i>
        <div>No data</div>
    </div>
} @else {
    <ul class="list-group scroll-on-hover">
        @for (item of filteredItems(); track item.id) {
            @if (item.isSourceQueue) {
                <p>Current Queue</p>
                <li
                    class="list-group-item d-flex justify-content-between align-items-center selectable"
                    tabindex="0"
                >
                    <span>
                        {{ item.name | truncate: truncateItemLength() }}
                    </span>

                    <telxl-button
                        [colour]="'light'"
                        [size]="'sm'"
                        (buttonClick)="onReleaseBacktoQueue(item.id)"
                    >
                        Release back to queue
                    </telxl-button>
                </li>
            } @else {
                <p>All Queues</p>

                <li
                    class="list-group-item d-flex justify-content-between align-items-center selectable"
                    tabindex="0"
                >
                    <span>
                        {{ item.name | truncate: truncateItemLength() }}
                    </span>

                    <telxl-button
                        [colour]="'secondary'"
                        [size]="'sm'"
                        (buttonClick)="onTransfer(item.id)"
                    >
                        Transfer
                    </telxl-button>
                </li>
            }
        }
    </ul>
}
