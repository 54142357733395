import { Injectable, OnDestroy, computed, signal } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IAgentClientState } from '../../models/agent/agent-state';
import { IConversation } from '../../models/conversation/conversation';
import { ChannelType } from '../../models/types/channel-type';
import { MediaType } from '../../models/types/media-type';
import { WorkItemState } from '../../models/types/workitem-type';
import { IWorkItem } from '../../models/workitem/workitem';
import { AgentHubService } from '../../signalr/agent/agent.hub.service';

@Injectable({
    providedIn: 'root',
})
export class WorkItemService implements OnDestroy {
    private _subscriptions = new Subscription();
    private _workItemsSignal = signal<IWorkItem[]>([]);
    private _workItems = new BehaviorSubject<IWorkItem[]>([]);
    private _loading = signal<boolean>(true);

    workItems$ = this._workItems.asObservable();

    workItemsSignal = computed<IWorkItem[]>(() =>
        this._workItemsSignal.asReadonly()(),
    );

    ready = computed<boolean>(() => this._loading() === false);

    constructor(private agentHubService: AgentHubService) {
        this._subscriptions.add(
            this.agentHubService.agentState$.subscribe(
                (agentState: IAgentClientState) => {
                    const workItems = this.transformWorkItems(
                        agentState.workItems,
                    );
                    this._workItems.next(workItems);
                    this._workItemsSignal.set(workItems);

                    this._loading.set(false);
                },
            ),
        );
    }

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    private transformConversations(
        conversations: Record<string, any>,
    ): IConversation[] {
        return Object.values(conversations).map(item => ({
            conversationId: item.conversationId,
            channelType: item.channelType as ChannelType,
            queueName: item.queueName,
            queuedAt: new Date(item.queuedAt),
            dequeuedAt: new Date(item.dequeuedAt),
            workflowProperties: item.workflowProperties,
            fromNumber: item.fromNumber,
            dialedNumber: item.dialedNumber,
            callerIdName: item.callerIdName,
            position: item.position,
            enquiryCallState: item.enquiryCallState,
        }));
    }

    private transformWorkItems(workItems: Record<string, any>): IWorkItem[] {
        return Object.values(workItems).map(item => ({
            workItemId: item.workItemId,
            conversations: this.transformConversations(item.conversations),
            businessUnitId: item.businessUnitId ?? null,
            customerId: item.customerId ?? null,
            workItemState: item.workItemState as WorkItemState,
            lastStateChangeDate: item.lastStateChangeDate,
            createdAt: new Date(item.createdAt),
            primaryConversationId: item.primaryConversationId,
            usableClosureCodes: item.usableClosureCodes,
            channelId: item.channelId,
            primaryMediaType: item.primaryMediaType as MediaType,
            primaryChannelType: item.primaryChannelType as ChannelType,
            remainingWrapUpResets: item.remainingWrapUpResets,
            wrapupDurationSeconds: item.wrapupDurationSeconds,
            forceCompletionCodeSelection: item.forceCompletionCodeSelection,
            queueName: item.queueName,
        }));
    }
}
