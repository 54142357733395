<div [ngClass]="{ centered: alignment === 'centered' }">
    <ul
        class="nav nav-pills nav-fill tabs"
        [ngClass]="class"
        role="tablist"
        aria-label="Tab Navigation"
    >
        @for (tab of tabs; track tab.index) {
            <li
                id="tab-{{ tab.index }}"
                role="tab"
                tabindex="{{ isSelected(tab.index) ? 0 : -1 }}"
                class="nav-item"
                (click)="setSelected(tab.index)"
                (keypress)="handleKeyPress($event, tab.index)"
                [class.active]="isSelected(tab.index)"
                [attr.aria-selected]="isSelected(tab.index)"
                [attr.aria-controls]="'tabpanel-' + tab.index"
                [ngStyle]="
                    isSelected(tab.index) ? { 'pointer-events': 'none' } : {}
                "
            >
                <span
                    class="nav-link"
                    [attr.aria-current]="isSelected(tab.index) ? 'page' : null"
                >
                    {{ tab.label }}
                </span>
            </li>
        }
    </ul>
</div>
