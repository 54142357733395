<div
    class="d-flex flex-row justify-content-between font-sm w-100 text-socials pb-3"
>
    <span>
        {{ currentPage === 1 ? 'Top' : 'Next' }} {{ itemsPerPage }} Completion
        Codes
    </span>
    <telxl-pagination
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        (pageChanged)="changePage($event)"
    />
</div>
<div class="d-flex">
    <div class="d-flex align-content-start" [ngClass]="splitchart">
        <telxl-doughnut
            [metric]="{
                percent: percentage,
                states: [{ value: 0, state: 'socials' }],
            }"
            [height]="chartSize()"
            [content]="
                DoughnutItems([
                    {
                        value: times.toString(),
                        class: 'font-xxl weight-medium',
                        showPercentage: false,
                    },
                    {
                        value: 'time' + (times !== 1 ? 's' : ''),
                        class: 'font-md',
                        showPercentage: false,
                    },
                ])
            "
            defaultState="socials"
        />
    </div>
    <div [ngClass]="'w-' + rightSplit">
        <table class="w-100" [ngClass]="selectClass">
            @for (
                dispositioncode of dispositionCodesArray;
                track dispositioncode.name;
                let idx = $index
            ) {
                @if (showDispositionCode(idx)) {
                    <tr
                        class="font-sm"
                        [ngClass]="{
                            active: selectedDispositionCodeIndex === idx,
                        }"
                        (click)="selectDispositionCode(idx)"
                    >
                        <td
                            class="w-50 ps-1 pt-1 pb-1"
                            [ngClass]="{
                                'weight-medium':
                                    selectedDispositionCodeIndex === idx &&
                                    selectClass() === 'shadowing',
                            }"
                        >
                            {{ dispositioncode.name }}
                        </td>
                        <td
                            class="w-25 text-end pt-1 pb-1"
                            [ngClass]="{
                                'font-lg weight-medium':
                                    selectClass() === 'shadowing',
                            }"
                        >
                            {{ dispositioncode.value | number: '2.0-0' }}
                        </td>
                        <td
                            class="w-25 text-end weight-medium pe-1 pt-1 pb-1"
                            [ngClass]="{
                                'font-md': selectClass() === 'shadowing',
                            }"
                        >
                            {{ dispositioncode.percentage }}%
                        </td>
                    </tr>
                }
            }
        </table>
    </div>
</div>
