import { differenceInSeconds } from 'date-fns';
import { AgentState, IPresence } from '../models/presence/presence';

export const calculateUserPresence = (
    state: AgentState,
    stateSince: Date,
    breakName?: string,
    timedBreakExpiresAt?: Date,
): IPresence => {
    return {
        state: state,
        stateSince: stateSince,
        stateDuration: differenceInSeconds(new Date(), stateSince),
        label: stateToLabel(state, breakName),
        class: stateToClass(state),
        online: stateToOnline(state),
        timedBreakExpiresAt: timedBreakExpiresAt,
        timedBreakRemainingDuration: timedBreakExpiresAt
            ? differenceInSeconds(timedBreakExpiresAt, new Date())
            : undefined,
    };
};

const stateToClass = (state: AgentState): string => {
    switch (state) {
        case 'Break':
        case 'PendingBreak':
            return 'warning';
        case 'Idle':
            return 'success';
        case 'Busy':
            return 'danger';
        case 'LoggedOut':
        case 'PendingLogout':
            return 'secondary';
    }
};
const stateToLabel = (state: AgentState, breakName?: string): string => {
    switch (state) {
        case 'LoggedOut':
            return 'Logged out';
        case 'Idle':
            return 'Available';
        case 'Break':
            return breakName || 'Break';
        case 'PendingBreak':
            return 'Pending Break';
        case 'PendingLogout':
            return 'Pending Logout';
        default:
            return state;
    }
};
const stateToOnline = (state: AgentState): boolean => {
    return state !== 'LoggedOut' && state !== 'PendingLogout';
};
