<div
    class="col-12 text-left p-1 mb-2 contact-div"
    [class.active]="isActive"
    (click)="selected()"
    (keydown.enter)="selected()"
    (keydown.space)="selected()"
    tabindex="0"
>
    <div class="d-flex w-100 align-items-center">
        <div class="rounded-circle contact-initials ms-1">
            {{ customerName() | initials }}
        </div>
        <div class="d-flex flex-column ms-3">
            <div>{{ call.agentPhoneNumber }}</div>
            <div class="font-sm text-details">
                <span class="me-2">{{ customerName() }}</span>
            </div>
        </div>
        <div class="flex-grow-1"></div>
        <div class="d-flex me-1 pb-3">
            <div>
                <i
                    class="fa-solid me-1"
                    [ngClass]="
                        incoming()
                            ? 'fa-arrow-turn-down-left text-danger'
                            : 'fa-arrow-turn-right text-success'
                    "
                ></i>
            </div>
        </div>
        <div class="text-end">
            <div class="d-flex align-items-center font-xs text-details">
                {{ incoming() ? 'Inbound' : 'Outbound' }}
            </div>
            <div class="font-xs text-details">
                {{ callSeconds | formatDuration }}s&nbsp;|&nbsp;{{
                    call.startTimeUtc | date: 'dd/MM/yy hh:mm a'
                }}
            </div>
        </div>
        <div class="flex-shrink-1 text-end">
            <button type="button" class="btn p-3">
                <i
                    class="fa-xl fa-light solid fa-phone"
                    [ngClass]="{ 'icon-sel fa-solid': isActive }"
                ></i>
            </button>
        </div>
    </div>
</div>
