@if (isAuthenticated()) {
    <div class="container-telxl">
        <div class="d-none d-lg-flex flex-row h-100">
            <app-shell-side-nav [ready]="ready().overall" />

            <div class="flex-grow-1 main-layout">
                <app-shell-header
                    class="w-100 pt-1 pb-1"
                    [user]="user()"
                    [ready]="ready().overall"
                />
                <app-shell-main-layout
                    [isAuthenticated]="isAuthenticated()"
                    [ready]="ready()"
                    [presence]="user().presence"
                />
            </div>
        </div>
        <div
            class="d-flex d-lg-none flex-column justify-content-center align-items-center vh-100 p1"
        >
            <div class="mt-3">
                <p>Current Display Resolution is Not Supported</p>
            </div>
        </div>

        <app-shell-notification-toasts />

        <div class="visibility-hidden">
            <audio #localAudio muted="muted"></audio>
            <audio #remoteAudio id="remoteAudio" autoplay></audio>
        </div>
    </div>
}
