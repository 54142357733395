import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ContactsPanelComponent } from '@dxp/contacts';
import { TelXLPanelComponent } from '@dxp/elements';
import { IContactSelected, IWorkItem } from '@dxp/shared/models';
import {
    VoiceService,
    WorkItemService,
    SettingsService,
} from '@dxp/shared/services';
import { VoiceCallNewComponent } from './components/voice-call-new/voice-call-new.component';
import { VoiceCallComponent } from './components/voice-call/voice-call.component';
import { recalculateHeight } from '@dxp/shared/helpers';

@Component({
    standalone: true,
    selector: 'voice-container',
    templateUrl: './voice-container.component.html',
    styleUrls: ['./voice-container.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
        VoiceCallComponent,
        TelXLPanelComponent,
        ContactsPanelComponent,
        VoiceCallNewComponent,
    ],
})
export class VoiceContainerComponent {
    workItemId: string | null = null;
    contacts = computed(() => this.voiceService.contacts());

    conversationId = computed(
        () => this.workItem()?.primaryConversationId ?? undefined,
    );

    _workItem = computed<IWorkItem | null>(() => {
        const workItemId = this.workItemId;
        if (!workItemId) return null;
        return (
            this.workItemService
                .workItemsSignal()
                .find(item => item.workItemId === workItemId) || null
        );
    });

    showContacts = signal(false);
    newCallContact = signal<IContactSelected | null>(null);

    constructor(
        private route: ActivatedRoute,
        private voiceService: VoiceService,
        private workItemService: WorkItemService,
        private settingsService: SettingsService,
    ) {
        this.workItemId = this.route.snapshot.paramMap.get('workItemId');
        if (this.workItemId) {
            if (this.workItemId !== 'new') {
                this.voiceService.setWorkItem(this.workItemId);
            }
        }
    }

    height = computed<string>(() =>
        recalculateHeight('chat', this.settingsService.viewType()),
    );

    get workItem() {
        return this._workItem;
    }

    contactsClose(close: boolean) {
        if (close) {
            this.voiceService.setContacts(false, '', '', '');
            this.showContacts.set(false);
        }
    }

    contactSelected(contact: IContactSelected, newCall: boolean) {
        this.voiceService.contactSelected(contact);
        if (newCall) {
            this.newCallContact.set(contact);
        }
    }
}
