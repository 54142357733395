import { Injectable, computed, signal } from '@angular/core';
import { RbacApiService } from '../../api/rbac/rbac.api.service';
import {
    BusinessUnit,
    IBusinessUnit,
} from '../../models/business-unit/business-unit';
import { Queue } from '../../models/queue/queue';
import { IRbacBusinessUnit } from '../../models/rbac/business-unit';
import { ITelXLKpiQueueThreshold } from '../../models/rbac/queue-kpi';
import { TenantService } from '../../services/tenant/tenant.service';
import { HeartbeatService } from '../heartbeat/heartbeat.service';
import { forkJoin, map } from 'rxjs';

/**
 * BusinessUnitService is responsible for managing business units and their related data.
 * It uses Angular signals and RxJS observables to manage and expose the state of business units.
 * Signals should only be used when the data is rendered in the DOM.
 */

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    private _businessUnits = signal<BusinessUnit[]>([]);
    private _loading = signal<boolean>(true);

    businessUnits = computed<IBusinessUnit[]>(() => {
        this.heartbeatService.lastHeartbeat();

        return this._businessUnits
            .asReadonly()()
            .map(
                bu =>
                    new BusinessUnit(
                        bu.id,
                        bu.name,
                        bu._queues,
                        bu.kpiTelXLThreshold,
                    ),
            );
    });

    ready = computed<boolean>(() => this._loading() === false);

    constructor(
        private rbacApiService: RbacApiService,
        private tenantService: TenantService,
        private heartbeatService: HeartbeatService,
    ) {}

    set(businessUnits: IRbacBusinessUnit[]) {
        const newBusinessUnits = businessUnits.map(
            bu => new BusinessUnit(bu.id, bu.name, []),
        );
        this._businessUnits.set(newBusinessUnits);

        forkJoin(
            this._businessUnits
                .asReadonly()()
                .map(businessUnit =>
                    this.rbacApiService
                        .getKpiThresholds(businessUnit.id, 'businessunits')
                        .pipe(
                            map(kpiThresholds => ({
                                businessUnitId: businessUnit.id,
                                kpiThresholds,
                            })),
                        ),
                ),
        ).subscribe({
            next: results => {
                results.forEach(({ businessUnitId, kpiThresholds }) => {
                    if (kpiThresholds) {
                        this.setKpi(
                            businessUnitId,
                            kpiThresholds.kpiTelXLThreshold as ITelXLKpiQueueThreshold,
                        );
                    }
                });
            },
            complete: () => this._loading.set(false),
        });
    }

    getKpi(businessUnitId: string): ITelXLKpiQueueThreshold {
        return (
            this._businessUnits
                .asReadonly()()
                .find(bu => bu.id === businessUnitId) as BusinessUnit
        ).kpiTelXLThreshold;
    }

    setKpi(businessUnitId: string, telXLKpiThreshold: ITelXLKpiQueueThreshold) {
        const businessUnitUpdate = this._businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === businessUnitId
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          bu._queues,
                          telXLKpiThreshold,
                      )
                    : bu,
            );

        this._businessUnits.set(businessUnitUpdate);

        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }

    updateQueue(queue: Queue) {
        if (!queue.businessUnit || queue.name.length === 0) return;

        const businessUnit = this._businessUnits
            .asReadonly()()
            .find(bu => bu.id === queue.businessUnit);

        const queues = [
            ...(businessUnit?._queues.filter(q => q.id !== queue.id) ?? []),
            ...[queue],
        ];

        const businessUnitUpdate = this._businessUnits
            .asReadonly()()
            .map(bu =>
                bu.id === queue.businessUnit
                    ? new BusinessUnit(
                          bu.id,
                          bu.name,
                          queues,
                          bu.kpiTelXLThreshold,
                      )
                    : bu,
            );

        this._businessUnits.set(businessUnitUpdate);
        this.tenantService.updateBusinessUnits(businessUnitUpdate);
    }
}
