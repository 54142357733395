import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { TelXlProgressbarComponent } from '@dxp/elements';
import { secondsToDuration } from '@dxp/shared/helpers';
import { AgentState } from '@dxp/shared/models';
import { FormatDurationPipe } from '@dxp/shared/pipes';

@Component({
    selector: 'telxl-shared-utilisation-panel',
    standalone: true,
    imports: [CommonModule, TelXlProgressbarComponent, FormatDurationPipe],
    templateUrl: './utilisation-panel.component.html',
    styleUrl: './utilisation-panel.component.scss',
})
export class TelXLUtilisationComponent {
    secondsToDuration = secondsToDuration;

    utilisation =
        input.required<
            Record<AgentState, { value: number; percentage: number }>
        >();

    orientation = input.required<'Stacked' | 'Inline'>();
}
