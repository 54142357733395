@if (conversation()) {
    <div class="voice-notification mt-2 ms-2 container-fluid w-auto">
        <div class="row ms-0 mt-2">
            <div class="col-2 mt-3 pe-2">
                <div class="rounded-circle call-initials-notification mt-1">
                    {{ customerName() | initials }}
                </div>
            </div>
            <div class="col-10 mt-4 ps-3 pe-0">
                <div class="d-flex flex-row">
                    <div class="font-xxl">
                        {{ conversation()?.fromNumber }}
                    </div>
                    <div class="align-self-center text-end w-100 pe-3 font-lg">
                        {{ countdown | formatDuration }}
                    </div>
                </div>
                <div class="font-lg">
                    {{ customerName() }}
                </div>
                <div class="d-flex align-items-start mt-3 mb-3">
                    <div class="flex-grow-1">
                        <div class="d-flex align-items-center">
                            <div class="col-5 font-xs me-1">
                                Number dialled:
                            </div>
                            <div class="col-7 font-sm">
                                {{ conversation()?.dialedNumber }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <div class="col-5 font-xs me-1">Queue name:</div>
                            <div class="col-7 font-sm">
                                {{ conversation()?.queueName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex pt-3 pb-2">
                    <div
                        class="d-flex flex-column align-items-center justify-content-center me-3"
                    >
                        <button
                            type="button"
                            class="btn btn-accept rounded-circle p-3"
                            (click)="onAcceptClick()"
                        >
                            <i class="fa-xl fa-solid fa-phone"></i>
                        </button>
                        <div class="mt-1 font-sm text-center">Accept</div>
                    </div>
                    <div
                        class="d-flex flex-column align-items-center justify-content-center ms-3"
                    >
                        <button
                            type="button"
                            class="btn btn-reject rounded-circle p-3"
                            (click)="onRejectClick()"
                        >
                            <i class="fa-xl fa-solid fa-phone-hangup"></i>
                        </button>
                        <div class="mt-1 font-sm text-center">Reject</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 p-0">
                <div
                    class="progress-bar progress-voice"
                    [ngClass]="{
                        'bg-success': progress > 66,
                        'bg-warning': progress > 33 && progress <= 66,
                        'bg-danger': progress <= 33,
                    }"
                    role="progressbar"
                    [style.width]="progress + '%'"
                ></div>
            </div>
        </div>
    </div>
}
