import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { activityResultTypeToText } from '@dxp/shared/helpers';
import {
    ConversationEndedReason,
    DequeuedReason,
    EnqueueAttemptRejectReason,
    WorkItemResult,
} from '@dxp/shared/models';

@Component({
    selector: 'telxl-activity-result-tag',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './activity-result-tag.component.html',
    styleUrls: ['./activity-result-tag.component.scss'],
    host: {
        '[class]': 'class || ""',
    },
})
export class TelXLActivityResultTagComponent {
    activityResultTypeToText = activityResultTypeToText;

    @Input({ required: true }) result!:
        | WorkItemResult
        | DequeuedReason
        | EnqueueAttemptRejectReason
        | ConversationEndedReason;
    @Input() outline = false;

    get resultClass(): 'red' | 'green' | 'orange' | 'blue' | 'purple' | 'grey' {
        switch (this.result) {
            case 'Abandoned':
            case 'Cancelled':
            case 'Failed':
            case 'Missed':
            case 'Rejected':
            case 'AgentJoinFailed':
            case 'BotFailed':
            case 'CallbackCanceled':
            case 'CancelledByAgent':
            case 'CustomerJoinFailed':
            case 'InternalError':
            case 'TransferToAgentFailed':
            case 'TransferToExternalFailed':
            case 'CustomerDisconnected':
                return 'red';

            case 'Handled':
            case 'AgentConnected':
                return 'green';

            case 'QueueTimedOut':
            case 'EstimatedWaitTimeLimitReached':
            case 'QueueSizeLimitReached':
            case 'IdleTimeout':
                return 'orange';

            case 'CallbackRequested':
            case 'TransferredToQueue':
            case 'TransferToChannel':
                return 'blue';

            case 'TransferToExternal':
                return 'purple';

            case 'Default':
            case 'ConversationEnded':
            case 'NoAgentLoggedIn':
            case 'NoAvailableStaffedAgent':
            case 'NoStaffedAgentLoggedIn':
            case 'Unspecified':
                return 'grey';
        }
    }
}
