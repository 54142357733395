import { differenceInSeconds } from 'date-fns';
import { toPercentage } from '../../helpers/metric';
import { IValueWithMetric } from '../../models/metrics/metric';
import {
    ITelXLKpiQueueThreshold,
    TelXLKpiQueueThresholdDefaultState,
} from '../../models/rbac/queue-kpi';
import { ChannelProviderType } from '../../services/channel-provider/channel-provider.service';
import { ChannelType } from '../types/channel-type';
import { RoutingModeType } from '../types/routing-mode-type';
import {
    IQueueActivity,
    QueueActivity,
    QueueActivityStatus,
} from './queue-activity';

export const QueueMediaTypes = [
    'Email',
    'Voice',
    'Webchat',
    'Socials',
    'Mixed',
    'Multiple',
    'Unknown',
] as const;
export type QueueMediaType = (typeof QueueMediaTypes)[number];

export const ChannelGroupTypes = [
    'email',
    'voice',
    'webchat',
    'socials',
    '',
] as const;
export type ChannelGroupType = (typeof ChannelGroupTypes)[number];

export interface IQueue {
    id: string;
    name: string;
    businessUnit?: string;
    hasActivity: boolean;
    isConfigured: boolean;
    report?: IQueueWithChannelsReport;
    information?: IQueueInformation;
    kpiTelXLThreshold: ITelXLKpiQueueThreshold;
    channelProviders: ChannelProviderType[];
    mediaType: QueueMediaType;
    channelGroups: ChannelGroupType[];
    startOfToday: Date | null;

    visibleByChannelFilter(filter: ChannelGroupType | undefined): boolean;
    activity(index?: string): IQueueActivity[];
    statistics(index?: string): IQueueStatistics;
}

export interface IQueueInformation {
    id: string;
    name: string;
    workflowId: string;
    mediaGroup: string;
    queueSegments: IQueueSegment[];
    agentSegments: IAgentSegment[];
    todaysQueueSegments: IQueueSegment[];
    todaysAgentSegments: IAgentSegment[];
    segments: IQueueSegment[];
    businessUnitId: string;
    staffedLoggedInAgentCount: number;
    staffedAvailableAgentCount: number;
}

export interface IQueueSegment {
    queueId: string;
    queuedAt: Date;
    deQueuedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    position?: number;
    customer: ICustomer;
    channelId: number;
    channelType: ChannelType;
    channelGroup: ChannelGroupType;
    routingMode: RoutingModeType;
    conversationId: string;
    businessUnitId: string;
}

export interface IAgentSegment {
    customer: ICustomer;
    createdAt: Date;
    closedAt?: Date;
    startedAt: Date;
    endedAt?: Date;
    queueId: string;
    conversationId: string;
    agents: IAgent[];
}

interface ICustomer {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
}

interface IAgent {
    id: string;
    name: string;
}

export interface IQueueReport {
    id: string;
    name: string;
    channelId: string;
    channelType: ChannelType;
    channelGroup: ChannelGroupType;
    sla: number;
    abandonThreshold: number;
    startOfToday: Date;
    answeredWithinSLARatio: number;
    handledCount: number;
    handledWithinSLACount: number;
    abandonedCount: number;
    abandonedWithinSLACount: number;
    callbackRequestedCount: number;
    callbackAcceptedCount: number;
    longestWaitTimeInSeconds: number;
    avgWaitingTimeInSeconds: number;
    queueTimedOutCount: number;
    conversationCountForAvgWaitingTime: number;
    businessUnitId: string;
}

export interface IQueueWithChannelsReport extends IQueueReport {
    queueChannelReports: IQueueReport[];
}

export interface IQueueStatistics {
    answeredWithinSLAPercentage: IValueWithMetric;
    abandoned: number;
    timedOut: number;
    waiting: IValueWithMetric;
    connected: number;
    success: number;
    complete: number;
    avgWaitingTimeInSeconds: IValueWithMetric;
    currentLongestWaitingTimeInSeconds: IValueWithMetric;
    longestWaitingTimeInSeconds: IValueWithMetric;
    outOfSla: number;
}

export class Queue implements IQueue {
    id: string;
    private _information?: IQueueInformation;
    private _report?: IQueueWithChannelsReport;
    private _activity: QueueActivity[];
    private _kpiTelXLThreshold: ITelXLKpiQueueThreshold;
    private _channelProviders?: ChannelProviderType[];

    constructor(
        id: string,
        channelProviders?: ChannelProviderType[],
        information?: IQueueInformation,
        report?: IQueueWithChannelsReport,
        kpiTelXLThreshold?: ITelXLKpiQueueThreshold,
    ) {
        this.id = id;
        this._channelProviders = channelProviders;
        this._information = information;
        this._report = report;
        this._kpiTelXLThreshold =
            kpiTelXLThreshold ?? TelXLKpiQueueThresholdDefaultState;

        const agentSegments = [
            ...(this._information?.agentSegments ?? []),
            ...(this._information?.todaysAgentSegments ?? []),
        ];
        const queueSegments = [
            ...(this._information?.queueSegments ?? []),
            ...(this._information?.todaysQueueSegments ?? []),
        ];

        this._activity = queueSegments.map(
            q =>
                new QueueActivity(
                    this.startOfToday !== null &&
                        new Date(q.queuedAt).getTime() >=
                            this.startOfToday.getTime(),
                    q,
                    agentSegments.find(
                        a => a.conversationId === q.conversationId,
                    ),
                ),
        );
    }

    get name(): string {
        return this._information?.name ?? '';
    }

    get businessUnit(): string | undefined {
        return this._information?.businessUnitId;
    }

    get isConfigured(): boolean {
        return this.mediaType !== 'Unknown';
    }

    get hasActivity(): boolean {
        return this._activity.some(a => a.includeInReport);
    }

    get report(): IQueueWithChannelsReport | undefined {
        return this._report;
    }

    get information(): IQueueInformation | undefined {
        return this._information;
    }

    get kpiTelXLThreshold(): ITelXLKpiQueueThreshold {
        return this._kpiTelXLThreshold;
    }

    get channelProviders(): ChannelProviderType[] {
        return this._channelProviders ?? [];
    }

    get mediaType(): QueueMediaType {
        switch (this._information?.mediaGroup) {
            case 'voice':
                return 'Voice';
            case 'email':
                return 'Email';
            case 'chat': {
                if (this.channelProviders.length === 0) return 'Unknown';

                if (
                    this.channelProviders.includes('Webchat') &&
                    this.channelProviders.length === 1
                )
                    return 'Webchat';
                if (
                    this.channelProviders.includes('Webchat') &&
                    this.channelProviders.length > 1
                )
                    return 'Mixed';
                return 'Socials';
            }
            default:
                return 'Unknown';
        }
    }

    get channelGroups(): ChannelGroupType[] {
        switch (this.mediaType) {
            case 'Voice':
                return ['voice'];
            case 'Email':
                return ['email'];
            case 'Webchat':
                return ['webchat'];
            case 'Socials':
                return ['socials'];
            case 'Mixed':
                return ['webchat', 'socials'];
            default:
                return ['email', 'voice', 'webchat', 'socials'];
        }
    }

    get startOfToday(): Date | null {
        if (this._report?.startOfToday)
            return new Date(this._report.startOfToday);

        if (
            this._information?.queueSegments &&
            this._information.queueSegments.length > 0
        )
            return new Date(
                this._information.queueSegments.reduce(
                    (earliest, segment) =>
                        segment.queuedAt < earliest
                            ? segment.queuedAt
                            : earliest,
                    this._information.queueSegments[0].queuedAt,
                ),
            );

        if (
            this._information?.agentSegments &&
            this._information.agentSegments.length > 0
        ) {
            const earliestAgent = this._information.agentSegments.reduce(
                (earliest, segment) =>
                    segment.createdAt < earliest.createdAt ? segment : earliest,
                this._information.agentSegments[0],
            );

            const queuedAt = this._information.todaysQueueSegments.find(
                tqs => tqs.conversationId === earliestAgent.conversationId,
            )?.queuedAt;

            return queuedAt ? new Date(queuedAt) : null;
        }

        return null;
    }

    visibleByChannelFilter(filter: ChannelGroupType | undefined): boolean {
        if (!filter) return true;

        return this.channelGroups.includes(filter);
    }

    activity(index?: string): IQueueActivity[] {
        if (index === undefined) {
            return this._activity;
        }

        return this._activity.filter(a => a.channelGroup === index);
    }

    statistics(index?: ChannelGroupType): IQueueStatistics {
        if (!this._report || !this._information) {
            // Manually set waiting and connected if report isn't available on 1st queued item.
            const minimumQueuedAt =
                this._information && this._information?.queueSegments.length > 0
                    ? this.information?.queueSegments.reduce((min, current) => {
                          return new Date(current.queuedAt).getTime() <
                              min.getTime()
                              ? new Date(current.queuedAt)
                              : min;
                      }, new Date())
                    : new Date();

            return queueStatisticDefault(
                this._activity?.filter(a =>
                    a.channelGroup === index
                        ? index
                        : a.channelGroup &&
                          a.status === QueueActivityStatus.Waiting &&
                          a.includeInReport,
                ).length,
                minimumQueuedAt,
                this._activity?.filter(a =>
                    a.channelGroup === index
                        ? index
                        : a.channelGroup &&
                          a.status === QueueActivityStatus.Connented &&
                          a.includeInReport,
                ).length,
            );
        }

        if (index === undefined) {
            return this.calculatePerformance(
                this._report,
                this._information.queueSegments,
                this._information.agentSegments,
                this.activity().filter(
                    a =>
                        a.status === QueueActivityStatus.Completed &&
                        a.includeInReport,
                ).length,
            );
        }

        const report = this._report.queueChannelReports.find(
            qcr => qcr.channelGroup === index,
        );

        if (report) {
            const conversationsMap = new Set(
                this._information.todaysQueueSegments
                    .filter(q => q.channelGroup === index)
                    .map(q => q.conversationId),
            );

            return this.calculatePerformance(
                report,
                this._information.queueSegments.filter(
                    qs => qs.channelGroup === index,
                ),
                this._information.agentSegments.filter(as =>
                    conversationsMap.has(as.conversationId),
                ),
                this.activity(index).filter(
                    a =>
                        a.status === QueueActivityStatus.Completed &&
                        a.includeInReport,
                ).length,
            );
        }

        return queueStatisticDefault();
    }

    private calculatePerformance(
        report: IQueueReport,
        queueSegments: IQueueSegment[],
        agentSegments: IAgentSegment[],
        complete: number,
    ): IQueueStatistics {
        const minimumQueuedAt =
            queueSegments.length > 0
                ? queueSegments.reduce((min, current) => {
                      return new Date(current.queuedAt).getTime() <
                          min.getTime()
                          ? new Date(current.queuedAt)
                          : min;
                  }, new Date())
                : new Date();

        return {
            answeredWithinSLAPercentage: {
                value: toPercentage(Math.max(report.answeredWithinSLARatio, 0)),
                metric: {
                    percent: toPercentage(
                        Math.max(report.answeredWithinSLARatio, 0),
                    ),
                    states: this._kpiTelXLThreshold.slaThreshold.enabled
                        ? [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold.slaThreshold.low,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold.slaThreshold.high,
                                  ),
                                  state: 'success',
                              },
                          ]
                        : [],
                },
            },
            abandoned: report.abandonedCount,
            timedOut: report.queueTimedOutCount,
            waiting: {
                value: queueSegments.length,
                metric: this._kpiTelXLThreshold.maxConversationsWaiting.enabled
                    ? {
                          percent: toPercentage(
                              queueSegments.length /
                                  this._kpiTelXLThreshold
                                      .maxConversationsWaiting.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold
                                          .maxConversationsWaiting.low /
                                          this._kpiTelXLThreshold
                                              .maxConversationsWaiting.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            connected: agentSegments.length,
            success: Math.max(report.handledWithinSLACount, 0),
            complete: complete,
            avgWaitingTimeInSeconds: {
                value: report.avgWaitingTimeInSeconds,
                metric: this._kpiTelXLThreshold.averageWaitTimeSeconds.enabled
                    ? {
                          percent: toPercentage(
                              report.avgWaitingTimeInSeconds /
                                  this._kpiTelXLThreshold.averageWaitTimeSeconds
                                      .high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold
                                          .averageWaitTimeSeconds.low /
                                          this._kpiTelXLThreshold
                                              .averageWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            currentLongestWaitingTimeInSeconds: {
                value: differenceInSeconds(new Date(), minimumQueuedAt),
                metric: this._kpiTelXLThreshold.maxLongestWaitTimeSeconds
                    .enabled
                    ? {
                          percent: this._kpiTelXLThreshold
                              .maxLongestWaitTimeSeconds.high
                              ? toPercentage(
                                    differenceInSeconds(
                                        new Date(),
                                        minimumQueuedAt,
                                    ) /
                                        this._kpiTelXLThreshold
                                            .maxLongestWaitTimeSeconds.high,
                                )
                              : 0,
                          states: [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          this._kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            longestWaitingTimeInSeconds: {
                value: report.longestWaitTimeInSeconds,
                metric: this._kpiTelXLThreshold.maxLongestWaitTimeSeconds
                    .enabled
                    ? {
                          percent: toPercentage(
                              report.longestWaitTimeInSeconds /
                                  this._kpiTelXLThreshold
                                      .maxLongestWaitTimeSeconds.high,
                          ),
                          states: [
                              {
                                  value: toPercentage(
                                      this._kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          this._kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
            },
            outOfSla:
                report.sla > 0
                    ? Math.max(complete - report.handledWithinSLACount, 0)
                    : 0,
        };
    }
}

export const queueStatisticDefault = (
    waiting = 0,
    queuedAt: Date = new Date(),
    connected = 0,
    kpiTelXLThreshold: ITelXLKpiQueueThreshold | undefined = undefined,
): IQueueStatistics => {
    return {
        answeredWithinSLAPercentage: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        waiting: { value: waiting, metric: { percent: 0, states: [] } },
        connected: connected,
        success: 0,
        complete: 0,
        timedOut: 0,
        abandoned: 0,
        outOfSla: 0,
        longestWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        avgWaitingTimeInSeconds: {
            value: 0,
            metric: { percent: 0, states: [] },
        },
        currentLongestWaitingTimeInSeconds: {
            value: differenceInSeconds(new Date(), queuedAt),
            metric:
                kpiTelXLThreshold &&
                kpiTelXLThreshold.maxLongestWaitTimeSeconds.enabled
                    ? {
                          percent: kpiTelXLThreshold.maxLongestWaitTimeSeconds
                              .high
                              ? toPercentage(
                                    differenceInSeconds(new Date(), queuedAt) /
                                        kpiTelXLThreshold
                                            .maxLongestWaitTimeSeconds.high,
                                )
                              : 0,
                          states: [
                              {
                                  value: toPercentage(
                                      kpiTelXLThreshold
                                          .maxLongestWaitTimeSeconds.low /
                                          kpiTelXLThreshold
                                              .maxLongestWaitTimeSeconds.high,
                                  ),
                                  state: 'warning',
                              },
                              {
                                  value: 100,
                                  state: 'danger',
                              },
                          ],
                      }
                    : { percent: 0, states: [] },
        },
    };
};
