import { Injectable, computed, signal } from '@angular/core';
import { RbacApiService } from '../../api/rbac/rbac.api.service';
import { BusinessUnit } from '../../models/business-unit/business-unit';
import { ITelXLKpiQueueThreshold } from '../../models/rbac/queue-kpi';
import { IRbacTenant } from '../../models/rbac/tenant';
import { ITenant, Tenant } from '../../models/tenant/tenant';
import { HeartbeatService } from '../heartbeat/heartbeat.service';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    private _tenant = signal<Tenant>({} as Tenant);
    private _loading = signal<boolean>(true);

    tenant = computed<ITenant>(() => {
        this.heartbeatService.lastHeartbeat();

        const tenant = this._tenant.asReadonly()();

        return new Tenant(
            tenant.id,
            tenant.name,
            tenant._businessUnits,
            tenant.kpiTelXLThreshold,
        );
    });

    ready = computed<boolean>(() => this._loading() === false);

    constructor(
        private rbacApiService: RbacApiService,
        private heartbeatService: HeartbeatService,
    ) {}

    set(tenant: IRbacTenant) {
        this._tenant.set(new Tenant(tenant.id, tenant.name, []));

        this.rbacApiService.getKpiThresholds(tenant.id, 'tenants').subscribe({
            next: kpiThresholds => {
                if (kpiThresholds) {
                    this.setKpi(
                        kpiThresholds.kpiTelXLThreshold as ITelXLKpiQueueThreshold,
                    );
                }
            },
            complete: () => this._loading.set(false),
        });
    }

    getKpi(): ITelXLKpiQueueThreshold {
        return this._tenant.asReadonly()().kpiTelXLThreshold;
    }

    setKpi(telXLKpiThreshold: ITelXLKpiQueueThreshold) {
        const tenant = this._tenant.asReadonly()();

        this._tenant.set(
            new Tenant(
                tenant.id,
                tenant.name,
                tenant._businessUnits,
                telXLKpiThreshold,
            ),
        );
    }

    updateBusinessUnits(businessUnits: BusinessUnit[]) {
        const tenant = this._tenant.asReadonly()();

        this._tenant.set(
            new Tenant(
                tenant.id,
                tenant.name,
                businessUnits,
                tenant.kpiTelXLThreshold,
            ),
        );
    }
}
