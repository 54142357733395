import {
    ConversationEndedReason,
    DequeuedReason,
    EnqueueAttemptRejectReason,
} from '../models/conversation/history';
import { WorkItemResult } from '../models/types/workitem-type';

export const activityResultTypeToText = (
    result:
        | WorkItemResult
        | DequeuedReason
        | EnqueueAttemptRejectReason
        | ConversationEndedReason,
): string => {
    return result.replace(/([A-Z])/g, ' $1').trim();
};
