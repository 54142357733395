import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import {
    fromEvent,
    merge,
    Observable,
    Subject,
    Subscription,
    timer,
} from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private userActivityEvents$: Observable<Event>;
    private activitySubscription: Subscription | null = null;
    private readonly timeoutDurationMs = 60 * 1000;

    private readonly activityTimeoutSubject = new Subject<void>();
    public readonly activityTimeout$ =
        this.activityTimeoutSubject.asObservable();

    constructor(
        private logger: LoggerService,

        private ngZone: NgZone,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.userActivityEvents$ = merge(
            fromEvent(this.document, 'mousemove'),
            fromEvent(this.document, 'keydown'),
            fromEvent(this.document, 'wheel'),
            fromEvent(this.document, 'touchstart'),
        );
    }

    public startMonitoring(sessionTimeOut: number): void {
        this.ngZone.runOutsideAngular(() => {
            if (this.activitySubscription) {
                this.activitySubscription.unsubscribe();
            }

            this.activitySubscription = this.userActivityEvents$
                .pipe(
                    tap(() =>
                        this.logger.debug(
                            'SessionService -> User activity detected.',
                        ),
                    ),
                    switchMap(() => {
                        this.logger.debug(
                            'SessionService -> Restarting inactivity timer.',
                        );
                        return timer(this.timeoutDurationMs * sessionTimeOut);
                    }),
                )
                .subscribe(() => {
                    this.ngZone.run(() => {
                        console.warn(
                            'SessionService -> User inactive, Logging out.',
                        );

                        this.activityTimeoutSubject.next();
                    });
                });
        });
    }

    public stopMonitoring(): void {
        this.activitySubscription?.unsubscribe();
        this.activitySubscription = null;
    }
}
