import { CommonModule } from '@angular/common';
import { Component, computed, input, output, signal } from '@angular/core';
import { ITransferAgent } from '@dxp/shared/models';

import { InitialsPipe, TruncatePipe } from '@dxp/shared/pipes';
import { TelXLUserDataTextComponent } from '../../../system-messages/user-data-text.component';

@Component({
    selector: 'telxl-agents',
    standalone: true,
    imports: [CommonModule, TruncatePipe, InitialsPipe, TelXLUserDataTextComponent],
    templateUrl: './agents.component.html',
    styleUrl: './agents.component.scss',
})
export class TransferAgentsComponent {
    agents = input.required<ITransferAgent[]>();
    filteredItems = computed(() => {
        const term = this.searchTerm().toLowerCase();
        return this.agents().filter(item =>
            item.name.toLowerCase().includes(term),
        );
    });

    // Default to 3.5 items before scrolling
    listItemHeight = input(80);
    searchTerm = signal('');
    selectedAgent = output<ITransferAgent>();
    truncateItemLength = input(20);
    visibleItems = input(3.5);

    get maxHeight(): string {
        return `${this.listItemHeight() * 3.5}px`;
    }

    agentSelected(id: string) {
        const agent = this.agents().find(agent => agent.id === id);
        if (agent) {
            this.selectedAgent.emit(agent);
        } else {
            throw new Error('Agent not found');
        }
    }

    onSearch(searchTerm: Event): void {
        this.searchTerm.set(
            (searchTerm.target as HTMLInputElement)?.value.toLowerCase(),
        );
    }
}
