export interface ICostCentres {
    sessionId: string;
    isSuccess: boolean;
    data: ICostCentre[];
}

export interface ICostCentre {
    ID: number;
    title: string;
    cli: string;
    cliWithhold: string;
    cliSetNetwork: string;
}
