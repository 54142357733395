import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IContactSelected, IVoiceCommand } from '@dxp/shared/models';
import { VoiceApiService } from '@dxp/shared/api';

@Component({
    selector: 'contacts-voice-transfer',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './voice-transfer.component.html',
    styleUrl: './voice-transfer.component.scss',
})
export class ContactVoiceTransferComponent implements OnInit {
    constructor(private voiceApiService: VoiceApiService) {}
    @Input({ required: true }) contactSelected!: IContactSelected;
    @Input({ required: true }) conversationId!: string;

    ngOnInit() {
        const params: IVoiceCommand = {
            conversationId: this.conversationId,
            destination:
                this.contactSelected.type === 'agent'
                    ? `vcc_operators+${this.contactSelected.identifier}`
                    : `${this.contactSelected.identifier}`,
            newCallCommand: 'Transfer',
        };
        this.voiceApiService.callCommand(params);
    }
}
