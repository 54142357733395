import { IAxisAttachment } from './axis-attachments';
import { IAxisSender } from './axis-sender';

export interface IAxisMessage {
    attachments: IAxisAttachment[];
    conversationId: string;
    createdAt: string;
    detectedLanguage?: string;
    id: string;
    isInternal: boolean;
    sender: IAxisSender;
    textMessage: string;
    translatedText?: string;
}
