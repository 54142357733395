import { Injectable } from '@angular/core';
import { RbacApiService } from './../../../../../api';

import { BehaviorSubject, filter, map, tap } from 'rxjs';

import { BusinessUnit, IRbacBusinessUnit } from './../../../../../models';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitDataService {
    #businessUnitsSubject = new BehaviorSubject<BusinessUnit[]>([]);

    constructor(private rbacApiService: RbacApiService) {}

    getBusinessUnits() {
        return this.rbacApiService.getBusinessUnits().pipe(
            map(businessUnits => this.mapBusinessUnits(businessUnits)),
            tap(businessUnits => {
                this.setBusinessUnits(businessUnits);
            }),
        );
    }

    listen() {
        return this.#businessUnitsSubject.asObservable().pipe(
            filter(businessUnits => businessUnits.length > 0),
        );
    }

    setBusinessUnits(businessUnits: BusinessUnit[]) {
        this.#businessUnitsSubject.next(businessUnits);
    }

    private mapBusinessUnit(businessUnit: IRbacBusinessUnit): BusinessUnit {
        return new BusinessUnit(businessUnit.id, businessUnit.name, []);
    }

    private mapBusinessUnits(
        businessUnits: IRbacBusinessUnit[],
    ): BusinessUnit[] {
        return businessUnits.map(businessUnit =>
            this.mapBusinessUnit(businessUnit),
        );
    }
}
