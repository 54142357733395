import { CommonModule } from "@angular/common";
import { Component, input } from "@angular/core";
import { DoughnutItems, TelXLDoughnutComponent, TelXLPaginationComponent } from "@dxp/elements";
import { leftSplit, recordToArray, rightSplit } from "@dxp/shared/helpers";

@Component({
    selector: 'telxl-shared-disposition-codes-panel',
    standalone: true,
    imports: [CommonModule, TelXLDoughnutComponent, TelXLPaginationComponent],
    templateUrl: './disposition-codes-panel.component.html',
    styleUrl: './disposition-codes-panel.component.scss',
})
export class TelXLDispositionCodesComponent {
    DoughnutItems = DoughnutItems;

    chartSize = input.required<string>();
    chartSplit = input.required<number>();
    currentPage = 1;

    dispositioncodes =
        input.required<Record<string, { value: number; percentage: number }>>();
    itemsPerPage = 5;
    recordToArray = recordToArray;
    selectClass = input<'highlight' | 'shadowing'>('highlight');
    selectedDispositionCodeIndex = 0;

    get dispositionCodesArray(): {
        name: string;
        value: number;
        percentage: number;
    }[] {
        return recordToArray(this.dispositioncodes()).sort(
            (a, b) => b.percentage - a.percentage,
        );
    }

    get leftSplit(): number {
        return leftSplit(this.chartSplit());
    }

    get percentage(): number {
        if (this.dispositionCodesArray.length === 0) {
            return 0;
        }

        return (
            this.dispositionCodesArray[this.selectedDispositionCodeIndex]
                ?.percentage ?? 0
        );
    }

    get rightSplit(): number {
        return rightSplit(this.leftSplit);
    }

    get splitchart(): string {
        return this.leftSplit === 0 ? 'd-none' : `w-${this.leftSplit}`;
    }

    get times(): number {
        if (this.dispositionCodesArray.length === 0) {
            return 0;
        }

        return (
            this.dispositionCodesArray[this.selectedDispositionCodeIndex]
                ?.value ?? 0
        );
    }

    get totalPages() {
        const pages = Math.ceil(
            this.dispositionCodesArray.length / this.itemsPerPage,
        );
        return pages > 0 ? pages : 1;
    }

    changePage(newPage: number) {
        this.currentPage = newPage;
        this.selectedDispositionCodeIndex =
            (this.currentPage - 1) * this.itemsPerPage;
    }

    selectDispositionCode(dispositionCodeIndex: number) {
        this.selectedDispositionCodeIndex = dispositionCodeIndex;
    }

    showDispositionCode(index: number): boolean {
        const low = (this.currentPage - 1) * this.itemsPerPage;
        const high = low + this.itemsPerPage - 1;

        return index >= low && index <= high;
    }
}
