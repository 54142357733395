<div class="search-box">
    <div class="input-group mb-3">
        <input
            #searchInput
            type="text"
            class="form-control"
            (keyup)="onSearch($event)"
            placeholder="Search..."
        />
        <span class="input-group-text">
            <i class="fa fa-search"></i>
        </span>
    </div>
</div>

@if (filteredItems().length === 0) {
    <div class="text-center py-4">
        <telxl-user-data-text />
    </div>
} @else {
    <ul class="list-group scroll-on-hover">
        @for (item of filteredItems(); track item.id) {
            <li
                class="list-group-item d-flex align-items-center selectable"
                (click)="agentSelected(item.id)"
                (keydown)="agentSelected(item.id)"
                tabindex="0"
            >
                <div class="position-relative me-3">
                    <div
                        class="rounded-circle user-initials position-relative"
                        id="header-user-initials"
                    >
                        {{ item.name | initials }}
                        <span
                            class="position-absolute top-0 start-100 p-2 border border-white border-2 rounded-circle status-badge bg-success"
                            style="transform: translate(-75%, -25%)"
                        ></span>
                    </div>
                </div>

                <div>
                    <span class="text-truncate fw-bold d-block">
                        {{ item.name | truncate: truncateItemLength() }}
                    </span>
                    <span class="small text-muted d-block">
                        Status: Logged In 12:23
                    </span>
                </div>
            </li>
        }
    </ul>
}
