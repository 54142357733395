import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { LoggerService } from './../../services/logger/logger.service';
import { RetryService } from './../../services/retry/retry.service';

export interface IWorkflowResponse {
    id: string;
    name: string;
    routingMode: 0;
}

export interface IAxisWorkflow {
    id: string;
    name: string;
    routingMode: 0;
}
@Injectable({
    providedIn: 'root',
})
export class WorkflowApiService {
    constructor(
        private logger: LoggerService,
        private http: HttpClient,
        private retryService: RetryService,
    ) {}

    getWorkflowQueues(conversationId: string): Observable<IAxisWorkflow[]> {
        const url = `${environment.workflowUrl}/conversations/${conversationId}/queues`;
        return this.http.get<IWorkflowResponse[]>(url).pipe(
            map(queues =>
                queues.map(queue => ({
                    id: queue.id,
                    name: queue.name,
                    routingMode: queue.routingMode,
                })),
            ),
            this.retryService.logErrorAndDelayRetry(),
            catchError(error => {
                this.logger.error('Error fetching templates', error);
                throw error;
            }),
        );
    }
}
