import { computed, Injectable, Signal } from '@angular/core';
import { ContactsApiService } from '@dxp/shared/api';
import { firstValueFrom, forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IContact } from '@dxp/shared/models';
import { IContactAgent, IOperatorIdResponse } from '@dxp/shared/models';
import { toSignal } from '@angular/core/rxjs-interop';

interface ContactResponse {
    total: number;
    results: IContact[];
}

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    private contacts: Signal<IContact[]>;

    constructor(private contactsApiService: ContactsApiService) {
        const contacts$ = this.contactsApiService.getContactDirectories().pipe(
            switchMap(directories => {
                const contactObservables: Observable<IContact[]>[] =
                    directories.map(dir =>
                        this.contactsApiService
                            .getContactsByDirectory(dir.id)
                            .pipe(
                                map(
                                    response =>
                                        (response as unknown as ContactResponse)
                                            .results || [],
                                ),
                            ),
                    );

                return forkJoin(contactObservables);
            }),
            map(contactsArray => {
                return contactsArray.flat();
            }),
        );

        this.contacts = toSignal(contacts$, { initialValue: [] });
    }

    getContactAgentDetails(agentId: string): Promise<IContactAgent | null> {
        return firstValueFrom(
            this.contactsApiService.getAgentContactDetails(agentId),
        );
    }

    getContactAgentOperatorId(
        agentId: string,
    ): Promise<IOperatorIdResponse | null> {
        return firstValueFrom(this.contactsApiService.getOperatorId(agentId));
    }

    getAllContacts(): Signal<IContact[]> {
        return this.contacts;
    }

    getVoiceContacts(): Signal<IContact[]> {
        return computed(() =>
            this.contacts().filter(contact => contact.phoneNumber?.trim()),
        );
    }
}
