export interface IAppReady {
    queue: boolean;
    agent: boolean;
    user: boolean;
    team: boolean;
    business: boolean;
    tenant: boolean;
    workitem: boolean;
    overall: boolean;
}
