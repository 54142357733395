export type ChannelType =
    | ''
    | 'webchat'
    | 'voice'
    | 'twitter'
    | 'instagram'
    | 'virtual'
    | 'whatsapp'
    | 'facebook'
    | 'sms'
    | 'email';
