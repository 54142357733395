<div class="attachment-wrapper">
    <telxl-attachment-button (attachClicked)="handleAttachmentClick()" />

    <input
        type="file"
        #fileInput
        class="d-none"
        (change)="onFileSelected($event)"
        multiple
    />
</div>
