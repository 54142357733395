import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';

import { IAxisUser } from '../user/user-data.service';
import { RbacApiService } from './../../../../../api';
import { IRbacTeam } from './../../../../../models';

export interface ITeamMG {
    id: string;
    memberIds: string[];
    name: string;
    queueIds: string[];
}

@Injectable({
    providedIn: 'root',
})
export class TeamDataService {
    #memberIds = new BehaviorSubject<string[]>([]);
    #teams = new BehaviorSubject<ITeamMG[]>([]);

    constructor(private rbacApiService: RbacApiService) {}

    getTeams(axisUser: IAxisUser): Observable<ITeamMG[]> {
        return this.fetchTeams(axisUser.id).pipe(
            tap(teams => {
                this.setTeams(teams);

                const uniqueMemberIds = [
                    ...new Set(teams.map(team => team.memberIds).flat()),
                ];
                this.setMember(uniqueMemberIds);
            }),
            filter(teams => !!teams.length),
        );
    }

    listen() {
        return this.#teams.asObservable();
    }

    listenForMemberIds() {
        return this.#memberIds.asObservable();
    }

    setMember(ids: string[]) {
        this.#memberIds.next(ids);
    }

    setTeams(teams: ITeamMG[]) {
        this.#teams.next(teams);
    }

    private fetchTeams(userId: string): Observable<ITeamMG[]> {
        return this.rbacApiService
            .getTeams(userId)
            .pipe(map(teams => this.mapTeams(teams)));
    }

    private mapTeam(team: IRbacTeam): ITeamMG {
        return {
            id: team.id,
            name: team.name,
            memberIds: team.memberIds,
            queueIds: team.queueIds,
        };
    }

    private mapTeams(teams: IRbacTeam[]): ITeamMG[] {
        return teams.map(team => this.mapTeam(team));
    }
}
