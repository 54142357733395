import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { IEmail, IEmailBody } from '../../models';
import { environment } from '../../environments/environment';
import { LoggerService } from '../../services';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    #baseUrl = environment.contentUrl;

    constructor(
        private logger: LoggerService,
        private http: HttpClient,
    ) {}

    getEmailDetails(messageId: string): Observable<IEmail> {
        return this.http
            .get<IEmail>(`${this.#baseUrl}/messages/email/${messageId}`)
            .pipe(
                tap({
                    next: email =>
                        this.logger.debug(
                            'ContentService -> getEmailDetails success',
                            email,
                        ),
                    error: err =>
                        this.logger.error(
                            'ContentService -> getEmailDetails failed',
                            err,
                        ),
                }),
            );
    }

    getEmails(conversationId: string): Observable<IEmail[]> {
        return this.http
            .get<
                IEmail[]
            >(`${this.#baseUrl}/messages/email/byconversationid?conversationId=${conversationId}`)
            .pipe(
                tap({
                    next: emails =>
                        this.logger.debug(
                            'ContentService -> getEmails success',
                            emails,
                        ),
                    error: err =>
                        this.logger.error(
                            'ContentService -> getEmails failed',
                            err,
                        ),
                }),
            );
    }

    getMessagesInThread(threadId: string): Observable<IEmail[]> {
        return this.http
            .get<IEmail[]>(`${this.#baseUrl}/messages/email/thread/${threadId}`)
            .pipe(
                tap({
                    next: messages =>
                        this.logger.debug(
                            'ContentService -> getMessagesInThread success',
                            messages,
                        ),
                    error: err =>
                        this.logger.error(
                            'ContentService -> getMessagesInThread failed',
                            err,
                        ),
                }),
            );
    }

    getEmailBody(bodyId: string): Observable<IEmailBody> {
        return this.http
            .get<IEmailBody>(`${this.#baseUrl}/messages/email/${bodyId}`)
            .pipe(
                tap({
                    next: body =>
                        this.logger.debug(
                            'ContentService -> getEmailBody success',
                            body,
                        ),
                    error: err =>
                        this.logger.error(
                            'ContentService -> getEmailBody failed',
                            err,
                        ),
                }),
            );
    }

    uploadEmailBody(body: IEmailBody): Observable<IEmailBody> {
        return this.http
            .post<IEmailBody>(`${this.#baseUrl}/messages/email`, body)
            .pipe(
                tap({
                    next: uploadedBody =>
                        this.logger.debug(
                            'ContentService -> uploadEmailBody success',
                            uploadedBody,
                        ),
                    error: err =>
                        this.logger.error(
                            'ContentService -> uploadEmailBody failed',
                            err,
                        ),
                }),
            );
    }

    deleteFile(url: string): Observable<void> {
        return this.http.delete<void>(url).pipe(
            tap({
                next: () =>
                    this.logger.debug('ContentService -> deleteFile success', {
                        url,
                    }),
                error: err =>
                    this.logger.error(
                        'ContentService -> deleteFile failed',
                        err,
                    ),
            }),
        );
    }
}
