import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, map, withLatestFrom } from 'rxjs';

import { environment } from './../../environments';
import { BusinessUnitDataService, ErrorHandlerService } from './../../services';

interface ITemplate {
    id: string;
    item: string;
    title: string;
}

interface ITemplateResponse {
    businessUnitId: string;
    description: string;
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class TemplatesApiService {
    constructor(
        private http: HttpClient,
        private errorHandlerService: ErrorHandlerService,
        private businessUnitDataService: BusinessUnitDataService,
    ) {}

    getLongContent(templateId: string, tenantId: string): Observable<string> {
        const url = `${environment.templatesTenantsUrl}/${tenantId}/templates/${templateId}/personalized`;
        return this.http.get<{ htmlContent: string }>(url).pipe(
            map(response => response.htmlContent),
            this.errorHandlerService.logErrorAndDelayRetry(
                'Error fetching tenant template',
            ),
            catchError(error =>
                this.errorHandlerService.handleError(
                    'Error fetching tenant template',
                    error,
                ),
            ),
        );
    }

    getTemplates(mediaGroup = 'Chat'): Observable<ITemplate[]> {
        const url = `${environment.templatesUrl}?mediaGroup=${mediaGroup}`;
        return this.http.get<ITemplateResponse[]>(url).pipe(
            withLatestFrom(this.businessUnitDataService.listen().pipe()),

            map(([templates, businessUnits]) => {
                // Filter templates based on the business unit id
                return templates.filter(template =>
                    businessUnits.some(bu => bu.id === template.businessUnitId),
                );
            }),
            map(templates =>
                templates.map(template => {
                    return {
                        id: template.id,
                        item: template.description,
                        title: template.name,
                    } as ITemplate;
                }),
            ),
            this.errorHandlerService.logErrorAndDelayRetry(
                'Error fetching templates',
            ),
            catchError(error =>
                this.errorHandlerService.handleError(
                    'Error fetching templates',
                    error,
                ),
            ),
        );
    }
}
