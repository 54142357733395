import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
    selector: 'telxl-loading',
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss',
    standalone: true,
    imports: [CommonModule],
})
export class TelXLLoadingComponent {
    size = input.required<string>();
}
