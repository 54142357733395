import { computed, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AgentApiService, VoiceApiService } from '@dxp/shared/api';
import { ICallState, IWorkItem } from '@dxp/shared/models';
import { SipService, WorkItemService } from '@dxp/shared/services';
import { ChatHubService, EmailHubService } from '@dxp/shared/signalr';
import { navigate } from './../../helpers/navigation';

@Injectable({
    providedIn: 'root',
})
export class TaskBeltService {
    workItems = computed<IWorkItem[]>(() =>
        this.workItemService.workItemsSignal(),
    );

    constructor(
        private workItemService: WorkItemService,
        private sipService: SipService,
        private agentApiService: AgentApiService,
        private voiceApiService: VoiceApiService,
        private chatHubService: ChatHubService,
        private emailHubService: EmailHubService,
        private router: Router,
    ) {}

    accept(workItem: IWorkItem) {
        if (workItem.primaryMediaType === 'Voice') {
            const params: ICallState = {
                conversationId: workItem.primaryConversationId,
                callState: 'Answer',
            };

            this.sipService.answerCall();
            this.voiceApiService.changeCallState(params);
        } else {
            if (workItem.primaryMediaType === 'Email') {
                this.emailHubService.acceptEmail(
                    workItem.primaryConversationId,
                );
            } else {
                this.chatHubService.accept({
                    conversationId: workItem.primaryConversationId,
                });
            }
        }

        this.navigate(workItem);
    }

    reject(workItem: IWorkItem) {
        if (workItem.primaryMediaType === 'Voice') {
            const params: ICallState = {
                conversationId: workItem.primaryConversationId,
                callState: 'Reject',
            };
            this.voiceApiService.changeCallState(params);
            this.sipService.reject();
        } else {
            if (workItem.primaryMediaType === 'Email') {
                this.emailHubService.rejectEmail(
                    workItem.primaryConversationId,
                );
            } else {
                this.chatHubService.reject({
                    conversationId: workItem.primaryConversationId,
                });
            }
        }
    }

    reset(workItem: IWorkItem) {
        this.agentApiService.resetWrapup(workItem.workItemId);
    }

    navigate(workItem: IWorkItem) {
        navigate(this.router, workItem);
    }
}
