import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { Queue } from '../../../../../models';
import { LiveHubWrapperService } from '../../wrappers/live-hub-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class QueueDataService {
    #queues = new BehaviorSubject<Queue[]>([]);

    constructor(private liveHubWrapperService: LiveHubWrapperService) {}
    setQueues(queues: Queue[]) {
        const currentQueues = this.#queues.getValue();

        this.#queues.next([...currentQueues, ...queues]);
    }

    listen() {
        return this.#queues.asObservable().pipe(
            tap(queues => {
                console.log('🚀 ~ QueueDataService ~ listen ~ queues', queues);
            }),
        );
    }

    hubData() {
        return this.liveHubWrapperService.queueInformation$;
    }
}
