import { Injectable } from '@angular/core';

import {
    BehaviorSubject,
    Observable,
    map,
    tap,
    filter,
    distinctUntilChanged,
} from 'rxjs';

import {
    ChannelType,
    IAgentClientState,
    IConversation,
    IWorkItem,
    MediaType,
    WorkItemState,
} from './../../../../../models';
import { AgentHubService } from './../../../../../signalr';
import { DataChangeOperatorsService } from '../../../../data-changed/data-changed.service';

@Injectable({
    providedIn: 'root',
})
export class UserWorkitemService {
    #userWorkItems = new BehaviorSubject<IWorkItem[]>([] as IWorkItem[]);

    constructor(
        private agentHubService: AgentHubService,
        private dataChangeOperatorsService: DataChangeOperatorsService,
    ) {}

    get userWorkItems() {
        return this.agentHubService.agentState$.pipe(
            this.dataChangeOperatorsService.distinctChange(),
            tap((agentState: IAgentClientState) => {
                const workItems = this.transformWorkItems(agentState.workItems);
                this.#userWorkItems.next(workItems);
            }),
        );
    }

    count(): Observable<number> {
        return this.#userWorkItems
            .asObservable()
            .pipe(map((items: IWorkItem[]) => items.length));
    }

    listen() {
        return this.#userWorkItems.asObservable();
    }

    listenFilteredByLength() {
        return this.#userWorkItems
            .asObservable()
            .pipe(filter((items: IWorkItem[]) => items.length > 0));
    }

    listenDistinct() {
        return this.#userWorkItems
            .asObservable()
            .pipe(
                distinctUntilChanged(
                    (prev, curr) =>
                        JSON.stringify(prev) === JSON.stringify(curr),
                ),
            );
    }

    private transformConversations(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        conversations: Record<string, any>,
    ): IConversation[] {
        return Object.values(conversations).map(item => ({
            conversationId: item.conversationId,
            channelType: item.channelType as ChannelType,
            queueName: item.queueName,
            queuedAt: item.queuedAt,
            dequeuedAt: item.dequeuedAt,
            workflowProperties: item.workflowProperties,
            fromNumber: item.fromNumber,
            dialedNumber: item.dialedNumber,
            callerIdName: item.callerIdName,
            position: item.position,
            enquiryCallState: item.enquiryCallState,
        }));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private transformWorkItems(workItems: Record<string, any>): IWorkItem[] {
        return Object.values(workItems).map(item => ({
            workItemId: item.workItemId,
            conversations: this.transformConversations(item.conversations),
            businessUnitId: item.businessUnitId ?? null,
            customerId: item.customerId ?? null,
            workItemState: item.workItemState as WorkItemState,
            lastStateChangeDate: item.lastStateChangeDate,
            createdAt: item.createdAt,
            primaryConversationId: item.primaryConversationId,
            usableClosureCodes: item.usableClosureCodes,
            channelId: item.channelId,
            primaryMediaType: item.primaryMediaType as MediaType,
            primaryChannelType: item.primaryChannelType as ChannelType,
            remainingWrapUpResets: item.remainingWrapUpResets,
            wrapupDurationSeconds: item.wrapupDurationSeconds,
            forceCompletionCodeSelection: item.forceCompletionCodeSelection,
            queueName: item.queueName,
        }));
    }
}
