<div class="col-12 p-0 text-left">
    <button
        [disabled]="disabled()"
        type="button"
        class="btn btn-voice-call w-100 p-3 mt-2 text-start"
        [class.clicked]="isClickedInput()"
        (click)="onButtonClick()"
        [class.disabled]="disabled()"
    >
        <i
            class="fa-xl fa-light ms-2"
            [ngClass]="isClicked() ? iconSelected() : icon()"
        ></i>
        <span class="ms-2 font-lg" [ngClass]="{ 'text-secondary': disabled() }">
            {{ isClicked() ? titleSelected() : title() }}
        </span>
    </button>
</div>
