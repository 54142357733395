import {
    Component,
    computed,
    Input,
    OnDestroy,
    OnInit,
    Signal,
    signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoiceApiService } from '@dxp/shared/api';
import {
    IDropdown,
    TelXLButtonComponent,
    TelXLDropdownComponent,
} from '@dxp/elements';
import { VoiceService } from '@dxp/shared/services';
import { VoiceDialpadComponent } from '../voice-dialpad/voice-dialpad.component';
import {
    IContactSelected,
    ICostCentre,
    ICostCentres,
    IInternalHistoricalWorkItems,
} from '@dxp/shared/models';
import { VoiceRecentCallComponent } from '../voice-recent-call/voice-recent-call.component';
import { TruncatePipe } from '@dxp/shared/pipes';

@Component({
    selector: 'voice-call-new',
    standalone: true,
    imports: [
        CommonModule,
        TelXLButtonComponent,
        VoiceDialpadComponent,
        TelXLDropdownComponent,
        VoiceRecentCallComponent,
    ],
    templateUrl: './voice-call-new.component.html',
    styleUrl: './voice-call-new.component.scss',
    providers: [TruncatePipe],
})
export class VoiceCallNewComponent implements OnInit, OnDestroy {
    @Input()
    set contactSelected(value: IContactSelected | null) {
        if (value && value.identifier) {
            this.callNumber.set(value.identifier);
        }
    }

    constructor(
        private voiceApiService: VoiceApiService,
        private voiceService: VoiceService,
        private truncatePipe: TruncatePipe,
        //private sipService: SipService,
    ) {
        this.costCentres = this.voiceService.getCostCentres();
        this.recentCalls = this.voiceService.getRecentCalls();
    }

    canCall = computed(() => this.numberDisplay !== '');
    callNumber = signal('');
    dialpad = signal(false);
    costCentreId = signal('');
    costCentres!: Signal<ICostCentres | null>;
    buttonState = signal<'dropdown' | 'danger'>('dropdown');
    itemSelected = 99;

    contactDetails = computed(() => {
        const contact = this.voiceService.selectedContact();
        return contact ? contact.identifier : null;
    });

    baseCLI = computed(() => {
        const cli = this.voiceApiService.operatorCLI();
        return cli ?? null;
    });
    overrideCLI = signal<string | null>(null);

    opCLI = computed(() => this.overrideCLI() ?? this.baseCLI());

    recentCalls!: Signal<IInternalHistoricalWorkItems | null>;
    contacts = computed(() => this.voiceService?.contacts?.() ?? []);

    ngOnInit() {
        this.voiceApiService.getOperatorCLI();
        this.voiceService.setContacts(true, '', 'voice', '');
    }

    ngOnDestroy(): void {
        this.contacts().display = false;
    }

    get costCentresData(): IDropdown<string>[] {
        const costCentresData = this.costCentres()?.data ?? [];
        return costCentresData.map((centre: ICostCentre) => ({
            label: this.truncatePipe.transform(centre.title, 25),
            value: centre.ID.toString(),
            disabled: false,
        }));
    }

    get numberDisplay() {
        return this.contactDetails() ?? this.callNumber();
    }

    withholdClick() {
        this.buttonState.set(
            this.buttonState() === 'dropdown' ? 'danger' : 'dropdown',
        );
    }

    onNumberChange(event: Event) {
        const inputValue = (event.target as HTMLInputElement).value;
        this.voiceService.selectedContact.set(null);
        this.callNumber.set(inputValue);
    }

    makeCall() {
        //this.sipService.makeCall(this.callNumber());
        console.log('make call');
    }

    showDialpad() {
        this.dialpad.set(!this.dialpad());
    }

    dialpadClick(number: string) {
        this.voiceService.selectedContact.set(null);
        this.callNumber.set(`${this.callNumber()}${number}`);
    }

    onCostCentreChange(costCentreId: string) {
        this.costCentreId.set(costCentreId);

        const costCentres = this.costCentres();
        if (costCentres && costCentres.isSuccess) {
            const selected = costCentres.data.find(
                cc => cc.ID.toString() === costCentreId,
            );
            if (selected?.cli) {
                this.buttonState.set('danger');
                this.overrideCLI.set(selected.cli);
            } else {
                this.overrideCLI.set(null);
                this.buttonState.set('dropdown');
            }
        }
    }

    onContactSelected(contact: IContactSelected) {
        this.callNumber.set(contact.identifier ?? '');
    }

    callSelected(contact: IContactSelected, id: number) {
        this.itemSelected = id;
        this.callNumber.set(contact.identifier ?? '');
    }

    isSelected(id: number): boolean {
        return this.itemSelected === id;
    }
}
