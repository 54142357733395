import { CommonModule } from '@angular/common';
import { Component, computed, input, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IDropdown, TelXLDropdownComponent } from '@dxp/elements';
import { PresenceApiService } from '@dxp/shared/api';
import { secondsToDuration } from '@dxp/shared/helpers';
import { IUser } from '@dxp/shared/models';
import { FormatDurationPipe, InitialsPipe } from '@dxp/shared/pipes';
import { HeartbeatService, StateService } from '@dxp/shared/services';
import { NotificationHubService } from '@dxp/shared/signalr';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Duration } from 'date-fns';
import { NotificationPanelComponent } from './../notifications/notification-panel/notification-panel.component';

@Component({
    selector: 'app-shell-header',
    standalone: true,
    imports: [
        CommonModule,
        NgbDropdownModule,
        FormsModule,
        FormatDurationPipe,
        InitialsPipe,
        TelXLDropdownComponent,
    ],
    providers: [NotificationPanelComponent],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    user = input.required<IUser>();
    ready = input.required<boolean>();

    isUserDropdownOpen = false;

    breakNames: Signal<string[]>;
    now: Signal<Date>;
    unread: Signal<number>;
    isBreakDropdownOpen = false;
    selectedBreak = '';

    isOnline = computed<boolean>(() => this.user().presence.online);

    constructor(
        private notificationHubService: NotificationHubService,
        private presenceApiService: PresenceApiService,
        private stateService: StateService,
        private heartbeatService: HeartbeatService,
    ) {
        this.breakNames = this.presenceApiService.breakNames;
        this.unread = this.notificationHubService.unread;
        this.now = this.heartbeatService.lastHeartbeat;
    }

    toggleDropdownOpenState(isOpen: boolean) {
        this.isUserDropdownOpen = isOpen;
    }

    toggleNotificationPanel() {
        this.notificationHubService.open(NotificationPanelComponent, {
            position: 'end',
        });
    }

    stopDropdownClose(event: MouseEvent): void {
        event.stopPropagation();
    }

    changePresence(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.user().presence.online) {
            this.stateService.onLogoff();
        } else if (this.user().presence.state === 'PendingLogout') {
            this.stateService.onActivate();
        }
    }

    onBreakNameChange(breakName: string) {
        this.presenceApiService.goToBreak(breakName);
    }

    get stateSince(): Duration {
        if (this.user().presence.timedBreakExpiresAt) {
            return secondsToDuration(
                this.user().presence.timedBreakRemainingDuration ?? 0,
            );
        }

        return secondsToDuration(this.user().presence.stateDuration);
    }

    get menuItems(): IDropdown<string>[] {
        return this.breakNames()
            .map(b => ({
                label: b,
                value: b,
                disabled: false,
            }))
            .sort((a: { label: string }, b: { label: string }) =>
                a.label.localeCompare(b.label),
            );
    }

    get borderClass(): string {
        return 'border-' + this.user().presence.class;
    }

    get backgroundClass(): string {
        return 'bg-' + this.user().presence.class;
    }
}
