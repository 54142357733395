import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { secondsToDuration } from '@dxp/shared/helpers';
import { FormatDurationPipe } from '@dxp/shared/pipes';
import { UserService } from '@dxp/shared/services';
import { Duration } from 'date-fns';

@Component({
    selector: 'app-shell-task-belt-status-panel',
    templateUrl: './task-belt-status-panel.component.html',
    styleUrls: ['./task-belt-status-panel.component.scss'],
    standalone: true,
    imports: [CommonModule, FormatDurationPipe],
})
export class TaskBeltStatusPanelComponent {
    constructor(private userService: UserService) {}

    get stateSince(): Duration {
        return secondsToDuration(
            this.userService.user().presence.stateDuration,
        );
    }
}
